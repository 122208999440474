import React from "react";
import "../components.css";
import { Icon } from "@iconify/react";


/* text: title
    text2: description
    numberOfPoints: nu,ber of points for task
    btnType: success (purple), danger(gold), or warning(white)
    width: selects from list in components (currently: 100%, 95%, 80%, 50%, 20%)
    fontSize: for title selects from list in components (currently: fs, fm, fl fxl fxxl)
    fontSize2: for description selects from list in components (currently: fs, fm, fl fxl fxxl)
    onClick: provides onClick
    id: id
    other: space for any other classes
    disabled: disabled

    updated 13/1/25
 */
function ButtonTask({ title, description, numberOfPoints, taskType, width, titleSize, descriptionSize, onClick, id, disabled, other}) {
    return (
        <button
            className={`generalButtonTask btn ${taskType} ${width} ${titleSize} ${other}`}
            onClick={onClick}
            id={id}
            disabled={disabled}

        >
            <p className={`ButtonTaskParagraph mb-1 d-flex justify-content-between ${titleSize}`}>
                <b>{title}</b> <Icon icon={taskType == "question" ? "wpf:ask-question" : "tdesign:task-filled"}  />
            </p>
            <hr className="m-2" />
            <p className={`ButtonTaskParagraph mb-1 ${descriptionSize}`}>
                <i>{numberOfPoints} points</i>
            </p>
            <p className={`ButtonTaskParagraph mb-1 ${descriptionSize}`}>
               {description}
            </p>
        </button>
    );
}

export default ButtonTask;
