
const nutrition = {to:"/account/tracking/food", icon:"mdi:food-fork-drink", name:"Nutrition"}
const sleep = {to:"/account/tracking/sleep", icon:"solar:moon-sleep-linear", name:"Sleep"}
const mood = {to:"/account/tracking/mood", icon:"tabler:mood-puzzled", name:"Mood"}
const period = {to:"/account/tracking/period", icon:"mdi:flower-lotus-outline", name:"Period"}

const homepage ={to:"/account/", icon:"bi:house-fill", name:"Home"}
const settings = {to: "/account/settings", icon:"bi:gear", name: "Settings"}
const redeem = {to:"account/redeem", icon:"material-symbols:redeem-rounded", name: "Redeem"}


const desktopDropdownLinks = [nutrition,  sleep,mood, period, redeem, settings]

const mobileNavbarLinks = [nutrition, sleep,homepage, mood, period ]


export {desktopDropdownLinks, mobileNavbarLinks, homepage, redeem, };