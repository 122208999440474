import {Button, InputGroup, Form, FloatingLabel, Collapse} from "react-bootstrap";
import api from "services/api";
import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import validator from 'validator'

// used for /reset/<token>. Sends an API request to the reset password API with the token in the URL and the new password. Only accessable to signed out users
// this is basically the same as the registration page. scope to make them more similar?


export default function ResetPass() {
  const location = useLocation();

  async function resetButton(e) {
    e.preventDefault();
    
    // input validation :3
    const errorMessages = {}
    if (passOne === ""){
      errorMessages.password = "Password cannot be blank"
    } else if (!validator.isStrongPassword(passOne, {minLength: 8, minNumbers: 1, minSymbols: 1 })){
      errorMessages.password = "Password does not meet compelxity requirements"
    } else if (passOne !== passTwo){
      errorMessages.password = "Passwords are not identical"
    }

    if (Object.keys(errorMessages).length === 0){
      await api.post("auth/password-reset/change", {password: passOne, token: location.pathname.split("/").pop()}).then(success => {
        // navigate user to login page if successful
        window.location.replace('/login');
    }, error => {
        errorMessages.backend = error.data
        setErrors(errorMessages)
    });
    } else {
      setErrors(errorMessages)
    }
  }
   
  const [privacyDetails, setPrivacydetails] = useState(false);
  const [passwComplex, setpasswComplex] = useState(false);
  const [errorMessages, setErrors] = useState({});
  const [passTwo,setPassTwo] = useState("");
  const [passOne, setPassOne] = useState("");
     return (
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-2"></div>
                <div className="col-sm-12 col-lg-8">
                  <div>  
                      <div className="container-fluid accountpage-div">
                          <div className="row">
                              <div className="col-lg-6 col-sm-12 p-5 pb-3 pt-xl-5 pt-2">
                              <h2>Reset Your Password</h2>
                              <button className={"btn-light rounded-top p-2"} onClick={() => setpasswComplex(!passwComplex)} aria-controls="password-complexity" aria-expanded={passwComplex} style={{width: "100%", border: "none"}}><i className="bi bi-caret-down-square-fill"></i> Password complexity requirements</button>
                              <Collapse in={passwComplex}>
                                <div id="password-complexity" className="rounded-bottom bg-light p-2 pb-1">
                                  <ul>
                                    <li>Minimum length: 8</li>
                                    <li>At least 1 lowercase letter</li>
                                    <li>At least 1 uppercase letter</li>
                                    <li>At least 1 number</li>
                                    <li>At least 1 symbol</li>
                                  </ul>
                                </div>
                              </Collapse>
                              <button className={"btn-light rounded-top mt-2 p-2"} onClick={() => setPrivacydetails(!privacyDetails)} aria-controls="privacy" aria-expanded={privacyDetails} style={{width: "100%", border: "none"}}><i className="bi bi-caret-down-square-fill"></i> What data do we store?</button>
                              <Collapse in={privacyDetails}>
                                <div id="privacy" className="rounded-bottom bg-light p-2 pb-1">
                                <p>At Healthify, we only request the minimum amount of data required from our users.
                                   We store:
                                </p>
                                  <ul>
                                    <li>Registration Information (Name, E-Mail, DoB, Gender etc)</li>
                                    <li>Tracking Information (Food, Sleep, Mood etc)</li>
                                    <li>Rewards Information (Points & Redemptions)</li>
                                    <li>Community forum posts</li>
                                  </ul>
                                  <p>These are accessable by Dr Gostic to allow her to provide her services.</p>
                                  <p>Your IP address and last login date are kept to prevent unauthroised access to your account.</p>
                                  <p>If you have any concerns or requests regarding your data, please <Link to="../contact" style={{textDecoration: "none", color:"purple"}}>contact us</Link>.</p>
                                </div>
                              </Collapse>
                              </div>
                              <div className="col-lg-6 col-sm-12 p-1 p-lg-5 pt-0 pt-xl-5 pb-3">
                                <div className="p-2">
                                <div className="m-1">
                                    <FloatingLabel controlId="passwordone" label="Password">
                                    <Form.Control type="password" name="password" onChange={(e) => setPassOne(e.target.value)}/>
                                    </FloatingLabel>
                                  </div>

                                  <div className="m-1">
                                    <FloatingLabel controlId="passwordtwo" label="Confirm Password">
                                    <Form.Control type="password" name="password" onChange={(e) => setPassTwo(e.target.value)}/>
                                    </FloatingLabel>
                                    
                                  </div>
                                  
                                  
      
                                  <InputGroup style={{width: "100%"}}>
                                    <Button style={{width: "100%"}} variant="primary" type="submit" value="LOGIN" onClick={resetButton}>Update</Button> 
                                  </InputGroup>

                                  {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}

                              </div>
                      </div>
                    </div>
                  </div>
                <div className="col-lg-2"></div>
              </div>
            <div>
          </div>
        </div>
      </div>
    </div>
  );  
}  