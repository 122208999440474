import React, {useState} from "react";
import api from "services/api";
import authenticationService from "services/authentication.service";

// generic button for deleting a user account

function DeletePassword({isOpen, onClose}) {
    const [screen, setScreen] = useState(0);
    const [pass, setPass] = useState("");

    async function iterate(e){
        if (parseInt(screen) === 1 ){
            const newEntry = {
                password: pass
            };
            await api.post("/auth/delete", newEntry).then(success => {
                // logout user and refresh window
                authenticationService.logout();
                //window.location.reload(false);
            }, error =>
            {
                console.log("error" + error);
            });
        }
        if (parseInt(screen) !== 1 ){
            setScreen(1);
        }
    }

    if (!isOpen) return null; // Don't render if modal is not open

    return (
    <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
            <h2>Are you sure you want to delete your account?</h2>
            {screen === 1 && (
                <div>
                    <p>Confirm your password to delete your account</p>
                    <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="password" aria-label="form-input" onChange={(e) => setPass(e.target.value)}/>
                </div>
            )}
            <div className="input-group">
                <button className="btn-danger btn" style={{width:"50%"}} onClick={onClose}>
                    No
                </button>
                <button className="btn-success btn" style={{width:"50%"}} onClick={iterate}>
                    Yes
                </button>
                </div>   
            
            <button className="modal-close" onClick={onClose}>
            <i className="bi bi-x"></i>
            </button>
        </div>
      </div>
    );
  }


export default DeletePassword;