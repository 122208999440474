import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import "index.css";
import api from "services/api";
import DeleteForum from "components/forum/DeleteForum";
import StickyForum from "components/forum/StickyForum";

export default function AdminCommunity() {
    const [topics, setTopics] = useState([]);
    const [bannedUsers, setBannedUsers] = useState([]);
    const [comName, setComName] = useState("");
    const [comDesc, setComDesc] = useState("");
    const [errorMessages, setErrors] = useState({});
    const [comSticky, setComSticky] = useState(false);

    
    useEffect(() => {
        // Gets topics in forum. 
        const fetchData = async () => {
            await api.get("forum/topics",)
            .then(success => {
                setTopics(success.data);
            },
            error => {
                console.log("Unable to load topics");
            });
            // gets banned users
            await api.get("forum/users/ban",)
            .then(success => {
                setBannedUsers(success.data);
                console.log(success.data)
            },
            error => {
                console.log("Unable to load banned users");
            });
        }
        fetchData();
    },[]);

      // handles unban user. refreshes bage on unban success
    async function handleUnban(e, selUser) {
        await api.delete("forum/users/ban/"+selUser.id).then(success => {
            window.location.reload(false)
        }, error =>
        {
            console.log("error" + error);
        });
    }
      // handles addition of category. validates input and then refreshes page
    async function handleAddCategory(e) {
        e.preventDefault();      
        const errorMessages = {}
        if (comName === ""){
            errorMessages.title = "Name cannot be blank"
        } else if (comName.toString().length  > 49){
            errorMessages.tooLongTitle = "Category name too long (max 50 chars)"
        }

        if (comDesc === ""){
            errorMessages.text = "Description cannot be blank"
        } else if (comDesc.toString().length  > 250){
            errorMessages.tooLongText = "Description too long (max 250 chars)"
        }
        if (Object.keys(errorMessages).length === 0){
            const newCategory = {
                name: comName,
                description: comDesc,
                sticky: comSticky,
            };
            await api.post("forum/topics", newCategory).then(success => {
                window.location.reload(false)
            }, error =>
            {
                console.log("error" + error);
            });
        } else {
            setErrors(errorMessages)
        };
    }
    
    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
                <div className="col-lg-10">
                    <h1>Community</h1>
                    <h3>Categories</h3>
                        {topics.map((singleTopic, index) => (
                            <div>
                                <Link to={"../community/topic/" + singleTopic.id}><p><strong>{singleTopic.title}</strong></p></Link>
                                <p>{singleTopic.description}</p>
                                {singleTopic.lasttimestamp && (<p>Last Activity: {singleTopic.lasttimestamp} ({singleTopic.lasttitle})</p>)}
                                {/* hey, these are the same buttons from the forum. Same criteria applies except they'll always appear here */}
                                <span>{singleTopic.sticky && ("pinned")} <StickyForum type="topic" id={singleTopic.id} current={singleTopic.sticky}/> <DeleteForum type="topic" id={singleTopic.id}/></span>
                            </div>
                            ))
                            }
                        {(topics.length === 0) && (<p>No topics found. Start by adding one.</p>)}
                    <hr />
                    <h3>Banned Users</h3>
                    <div>
                        {bannedUsers.map((singleUser, index) => (
                        <div>
                            <p><strong>{singleUser.name}</strong> ({singleUser.email}) <button onClick={(e) => {handleUnban(e, singleUser)}}>Unban</button></p>
                            <p>Registered {singleUser.emailConfirmed} & Last Activity {singleUser.lastActivity}</p>
                        </div>
                        ))
                        }
                        {(bannedUsers.length === 0) && (<p>No banned users found. Hurray!</p>)}
                    </div>
                </div>
                <div className="col-lg-2">
                    <h1>Add Category</h1>
                    <div className="p-2">
                        <p>Category Title</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="new-category-name" onChange={(e) => setComName(e.target.value)}/>
                    </div>
                    <div className="p-2">
                        <p>Category Description</p>
                        <textarea className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="new-category-description" onChange={(e) => setComDesc(e.target.value)}/>
                    </div>
                    <div className="p-2 pt-0">
                        <input type="checkbox" aria-label="new-category-description" id="cat" onChange={(e) => setComSticky(e.target.value)}/><label htmlFor="cat">Sticky</label>
                    </div>
                    <button className="btn-success btn" style={{width:"100%"}} onClick={handleAddCategory}>
                    Add
                    </button>
                    {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}
