import "index.css"; 


export default function LoadingPlaceholder() {
 return (
    <div className="container-fluid d-flex justify-content-center align-items-center">
      <div className="col-lg-8 p-5 text-center">
        <h1><span className="spinner-border " style={{width: "4rem",height: "4rem", color: "#65295f"}}></span></h1>
        <h5>Loading, please wait</h5>
      </div>
    </div>
    
  );
}