import React, {useTransition, useState, useEffect } from 'react';
import "index.css";
import "./admin.css"
import api from "services/api";

export default function AdminTasks() {
    const [searchTerm, setSearchTerm] = useState('');
    const [tasks, setTasks] = useState([])
    const [taskDetails, setTaskDetails] = useState("");
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageURL, setImageURL] = useState();
    const [deadline, setDeadline] = useState();
    const [frequency, setFrequency] = useState(0);
    const [points, setPoints] = useState(0);
    const [assignedTo, setAssignedTo] = useState({});
    const [enabled, setEnabled] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isGlobalTask, setIsGlobalTask] = useState(false);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState([]);
    const [isDefaultTask, setIsDefaultTask] = useState(false);

    const [edit, setEdit] = useState(false);
    const [errors, setErrors] = useState([]);
    

    
    const [, startTransition] = useTransition();

    const filteredTasks = tasks.filter(task => 
        task.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        //Gets a list of tasks
        const fetchData = async () => {
            await api.get("/account/tasks/admin")
            .then(success => {
                setTasks(success.data);
                // console.log(success.data)
            },
            error => {
                console.log("Unable to load tasks");
            });
            await api.get("/users")
            .then(success => {
                setUserList(success.data.groups);
            },
            error => {
                console.log("Unable to load users");
            });
        }
        fetchData();
      },[]);

    
    // todo: date validation
    async function handleInputValidation(){
        const errorMessages = {}
        if (title === ""){
            errorMessages.title= "Title cannot be blank"
        } else if (title.toString().length  > 50){
            errorMessages.title = "Title too long (max 50 characters)"
        }

        if (description === ""){
            errorMessages.description = "Description cannot be blank"
        } else if (description.toString().length  > 500){
            errorMessages.description = "Description too long (max 500 characters)"
        }

        if (description.toString().length  > 250){
            errorMessages.url = "ImageURL too long (max 250 characters)"
        }

        if (points < 0){
            errorMessages.points = "Points can't be less than zero"
        }

        if (frequency < 0){
            errorMessages.points = "Frequency can't be less than zero"
        }

        setErrors(errorMessages)
    }

    // handles user selection. sends an API request to get user information
    async function handleSelectTask(e, selTask) {
        setErrors([])
        setLoading(true);
        handleClear()
        await api.get(`account/tasks/admin/${selTask.id}`).then(success => {
            setTaskDetails(success.data);
            setTitle(success.data.title);
            setDescription(success.data.description);
            setImageURL(success.data.image_url);
            setDeadline(success.data.deadline);
            setFrequency(success.data.frequency);
            setPoints(success.data.points);
            setAssignedTo(success.data.assigned_to);
            setEnabled(success.data.enabled);
            setSelectedUsers(success.data.assigned_to);
            setLoading(false);
            setIsDefaultTask(success.data.default)
            console.log(success.data)
        }, error =>
        {
        return // generally we need better error handlign
        });
    }

    // to do : do something here?
    async function handleDeleteTask(e, selTask) {
        setLoading(true)
        setTaskDetails();
        await api.delete(`account/tasks/admin/${selTask.id}`).then(success => {
            // console.log(success)
            handleClear()
            setLoading(false)
            setTasks(tasks.filter(task => task.id !== selTask.id));
                }, error =>
        {
            setErrors(["An error has occured. Please try again."])
            setLoading(false)
        });
    }


    async function handleAdd(e) {
        setErrors([])
        handleInputValidation()
        if (Object.keys(errors).length === 0){
            setLoading(true);
            // collapse groups and users into a single array of userIds
            const filteredSelectionId = []
            selectedUsers.forEach(function (item, index){
                if (item.users){
                    let IdFilter = item.users.map(item => item.id);
                    filteredSelectionId.push.apply(filteredSelectionId, IdFilter)
                } else {
                    filteredSelectionId.push(item.id)
                }
            })

            const addData = {
                title: title,
                description: description,
                image_url: imageURL ? imageURL: "",
                points: Number(points),
                deadline: deadline,
                frequency: Number(frequency),
                enabled: enabled,
                assigned_to: isGlobalTask ? ["Global"] : filteredSelectionId,
                default : isDefaultTask
            }
            console.log(addData)
            await api.post("account/tasks/admin", addData).then(success => {
                handleSelectTask(e, success.data)
                addData.id = success.data.id
                setTasks([...tasks, addData])
            }, error =>
            {
                setErrors(["An error has occured. Please try again."])
            });
        }
    }

    async function handleEdit(e, selTask) {
        setErrors({})
        handleInputValidation()
        if (Object.keys(errors).length === 0){
            const filteredSelectionId = []
            selectedUsers.forEach(function (item, index){
                if (item.users){
                    let IdFilter = item.users.map(item => item.id);
                    filteredSelectionId.push.apply(filteredSelectionId, IdFilter)
                } else {
                    // different because editing disregards user data structure used when creating :3
                    filteredSelectionId.push(item)
                }
            })
            const compressedSelection = [...new Set(filteredSelectionId)];
            const addData = {
                title: title,
                description: description,
                image_url: imageURL ? imageURL: "",
                points: Number(points),
                deadline: deadline,
                frequency: Number(frequency),
                assigned_to: isGlobalTask ? ["Global"] : compressedSelection,
                default : isDefaultTask
            }
            console.log(addData)
            await api.patch(`account/tasks/admin/${selTask.id}`, addData).then(success => {
                setErrors(["Task Successfully Updated!"])
                setEdit(false)
            }, error =>
            {
                setErrors(["An error has occured. Please try again."])
            });
        }
    }

    async function handleDisabled(e, selId) {
        setErrors([])
        await api.post("account/tasks/admin/" + selId.id + "/disable").then(success => {
            setEdit(false)
            taskDetails.enabled = !taskDetails.enabled
            setErrors(["Updated Successfully"])

        }, error =>
        {
            setErrors([error.data.message])
        });
    }

    async function handleClear() {
        setErrors([])
        setTaskDetails();
        setTitle("");
        setDescription("");
        setImageURL("");
        setDeadline();
        setFrequency(0);
        setPoints(0);
        setAssignedTo([]);
        setEnabled(true);
        setSelectedUsers([]);
        setIsGlobalTask(false);
        setOpen([]);
        setEdit(false);
        setIsDefaultTask(false)

    }

    const toggleUserSelection = (user) => {

        if (selectedUsers.includes(user)) {
            setSelectedUsers(selectedUsers.filter(u => u !== user));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const toggleOpen = (group) => {
        if (open.includes(group.group_id)) {
            setOpen(open.filter(users => users !== group.group_id))
        } else {
            let newOpen = [...open]
            newOpen.push(group.group_id)
            setOpen(newOpen)
        }

    }


    // const filteredUsers = userList.filter(user => 
    //     user.title.toLowerCase().includes(assigneeSearchTerm.toLowerCase())
    // );

    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
            <div className="col-lg-2 ps-0 ms-0">
                <h1>Select a task</h1>
                <button className="btn adminNav" style={{width: "100%"}} onClick={handleClear}>Create a task</button>
                    <div className="adminpage-search-box bg-white pb-0 mb-0">
                        <input 
                            type="text" 
                            placeholder="Search tasks..." 
                            value={searchTerm} 
                            onChange={e => setSearchTerm(e.target.value)} 
                        />
                    </div>
                    <div className="adminpage-sidebar bg-white pt-0 mt-0 " >
                        <div >
                            <div >
                                {filteredTasks.map((task, index) => (
                                    <p>
                                                                            <button 
                                        value={task.id}
                                        className="btn text-black pb-0 "
                                        onClick={(e) => {handleSelectTask(e, task)}}
                                        disabled={taskDetails ? taskDetails.id === task.id : false}
                                    >
                                        {task.title}
                                    </button>
                                    </p>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10">
                <div>
                    {!taskDetails && !loading && (
                            <div>
                            <h1>..or create a new one</h1>
                            <h3>Create a Task</h3>
                            <hr />
                            <div>
                                <div className="p-2">
                                    <h5>Task Name</h5>
                                    <p className='mb-1'>A short name for the task shown to users. (Max 50 characters)</p>
                                    <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Description</h5>
                                    <p className='mb-1'>A longer description of the task. (Max 256 characters)</p>
                                    <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Image URL</h5>
                                    <p className='mb-1'>A link to an image to be displayed along side the task.</p>
                                    <div className="input-task">
                                        <input type="link" className="form-control" value={imageURL} onChange={(e) => setImageURL(e.target.value)} aria-label="Reward Image URL"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Task Deadline</h5>
                                    <p className='mb-1'>The date at which users will no longer be able to complete the task.</p>
                                    <div className="input-task">
                                        <input type="date" className="form-control" value={deadline} onChange={(e) => setDeadline(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Task Frequency</h5>
                                    <p className='mb-1'>After how many days should users be able to repeat a task? Leave blank for zero.</p>
                                    <div className="input-task">
                                        <input type="number" className="form-control" value={frequency} onChange={(e) => setFrequency(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Points</h5>
                                    <p className='mb-1'>How many points is the task worth?</p>
                                    <div className="input-task">
                                        <input type="number" className="form-control" value={points} onChange={(e) => setPoints(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Assignees</h5>
                                    <p className='mb-1'>Who should the task be assigned to?</p>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="adminpage-info-box">
                                            <div >
                                                <div>
                                                    <div className="assign-global-checkbox pb-0 mb-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="global-task-checkbox" 
                                                            checked={isGlobalTask} 
                                                            onChange={() => setIsGlobalTask(!isGlobalTask)}
                                                        />
                                                        <label htmlFor="global-task-checkbox">Assign to everyone</label>
                                                    </div>
                                                    <div className="assign-global-checkbox pt-0 mt-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="default-task-checkbox" 
                                                            checked={isDefaultTask} 
                                                            onChange={() => setIsDefaultTask(!isDefaultTask)}
                                                            disabled={!isGlobalTask}
                                                        />
                                                        <label htmlFor="default-task-checkbox">Assign to new users as they register? {!isGlobalTask && ("Only available when task is set to \"everyone\"")}</label>
                                                    </div>
                                                </div>
                                                {!isGlobalTask && (
                                                <div className="p-3 ">
                                                    <div>
                                                    {userList.map((group, index) => (
                                                    <div>
                                                        <div className="input-group m-2" style={{width:"100%"}}>
                                                            <button 
                                                                value={group.group_id}
                                                                className={`adminpage-user-box ${selectedUsers.includes(group) ? "selected" : ""} ${open.includes(group.group_id) ? "rounded-top" : "rounded"}`}
                                                                onClick={() => toggleOpen(group)}
                                                                disabled={selectedUsers.includes(group)}
                                                            >
                                                            <p className="text-white">{open.includes(group.group_id) ? <i className="bi bi-arrow-down-square"></i> : <i className="bi bi-arrow-right-square"></i>} {group.title ? group.title : "Unassigned"}</p>
                                                            </button>
                                                            <div className="input-group-text adminpage-checkbox">
                                                                <input 
                                                                            type="checkbox" 
                                                                            checked={selectedUsers.includes(group)}
                                                                            onChange={() => toggleUserSelection(group)}
                                                                        />
                                                            </div>
                                                            </div>
                                                            {open.includes(group.group_id) ? (<div>{group.users.map((single_user) => 
                                                                <p className="m-0 ms-4"><button value={single_user.id}  onClick={() => toggleUserSelection(single_user)} className={`p-1 mt-0 mb-0 btn `}>{single_user.name}</button> {selectedUsers.includes(single_user) ? <i className="bi bi-check-all"></i> : null}</p>)}<hr/></div>) : null}
                                                        </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-6">
                                        </div>
                                    </div>

                                </div>

                                <div className="p-2">
                                    <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="disabledCheck" checked={enabled} onChange={() => {setEnabled(!enabled)}}/>
                                            <p className='mb-1'>By default, this task will be <b>immediately</b> available to anyone assigned to the task. Uncheck this box to create this task but prevent users from joining. tasks can be hidden and unhidden at any time by selecting the record from the panel on your left.</p>
                                    </div>
                                    <button className="btn adminNav" style={{width: "100%"}} onClick={handleAdd}>Create Task</button>
                                    {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}

                                </div>
                                </div>
                            </div>)}
                        {loading && (<p>Loading, please wait</p>)}
                        {taskDetails && !loading && (
                            <div>
                            <h1>{taskDetails.title}</h1>
                            <nav className="nav nav-pills flex-column flex-lg-row pt-2">
                                <h3>Change Task</h3>
                                <button className="flex-lg-fill text-lg-center adminNav rounded-start ms-2" value="0" onClick={() => setEdit(!edit)}>{edit ? "Editing.." : "Edit"}</button>
                                <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={(e) => handleDisabled(e, taskDetails)} >{taskDetails.enabled ? "Disable" : "Enable"}</button>
                                <button className="flex-lg-fill text-lg-center adminNav rounded-end" value="2"  onClick={(e) => handleDeleteTask(e, taskDetails)} disabled={!taskDetails.delete_allowed}>Delete</button>
                            </nav>
                            <span className="text-danger">{!taskDetails.delete_allowed && ("Deletion of this task is not possible as it has been completed by a user. To stop people completing this task, disable it. ")}</span>
                            {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}
                            {edit && <div>
                            <div>
                                <div className="p-2">
                                    <h5>Task Name</h5>
                                    <p className='mb-1'>A short name for the task shown to users. (Max 50 characters)</p>
                                    <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="task-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Description</h5>
                                    <p className='mb-1'>A longer description of the task. (Max 256 characters)</p>
                                    <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="task-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Image URL</h5>
                                    <p className='mb-1'>A link to an image to be displayed along side the task.</p>
                                    <div className="input-task">
                                        <input type="link" className="form-control" value={imageURL} onChange={(e) => setImageURL(e.target.value)} aria-label="Task Image URL"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Task Deadline</h5>
                                    <p className='mb-1'>The date at which users will no longer be able to complete the task.</p>
                                    <div className="input-task">
                                        <input type="date" className="form-control" value={deadline} onChange={(e) => setDeadline(e.target.value)} aria-label="Task deadline"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Task Frequency</h5>
                                    <p className='mb-1'>After how many days should users be able to repeat a task? Leave blank for zero.</p>
                                    <div className="input-task">
                                        <input type="number" className="form-control" value={frequency} onChange={(e) => setFrequency(e.target.value)} aria-label="Task frequency"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Points</h5>
                                    <p className='mb-1'>How many points is the task worth?</p>
                                    <span className="text-danger">{!taskDetails.delete_allowed && ("You can't edit the point value of this task as it's already been completed by someone.")}</span>
                                    <div className="input-task">
                                        <input type="number" className="form-control" value={points} onChange={(e) => setPoints(e.target.value)} disabled={!taskDetails.delete_allowed} aria-label="Task points"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Assignees</h5>
                                    <p className='mb-1'>Who should the task be assigned to?</p>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="adminpage-info-box">
                                            <div >
                                                <div>
                                                    <div className="assign-global-checkbox pb-0 mb-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="global-task-checkbox" 
                                                            checked={isGlobalTask} 
                                                            onChange={() => setIsGlobalTask(!isGlobalTask)}
                                                        />
                                                        <label htmlFor="global-task-checkbox">Assign to everyone</label>
                                                    </div>
                                                    <div className="assign-global-checkbox pt-0 mt-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="default-task-checkbox" 
                                                            checked={isDefaultTask} 
                                                            onChange={() => setIsDefaultTask(!isDefaultTask)}
                                                            disabled={!isGlobalTask}
                                                        />
                                                        <label htmlFor="default-task-checkbox">Assign to new users as they register? {!isGlobalTask && ("Only available when task is set to \"everyone\"")}</label>
                                                    </div>
                                                </div>
                                                {!isGlobalTask && (
                                                <div className="p-3">
                                                    <div>
                                                    {userList.map((group, index) => (
                                                    <div>
                                                        <div className="input-group m-2" style={{width:"100%"}}>
                                                            <button 
                                                                value={group.group_id}
                                                                className={`adminpage-user-box ${selectedUsers.includes(group) ? "selected" : ""} ${open.includes(group.group_id) ? "rounded-top" : "rounded"}`}
                                                                onClick={() => toggleOpen(group)}
                                                                disabled={selectedUsers.includes(group)}
                                                            >
                                                            <p className="text-white">{open.includes(group.group_id) ? <i className="bi bi-arrow-down-square"></i> : <i className="bi bi-arrow-right-square"></i>} {group.title ? group.title : "Unassigned"}</p>
                                                            </button>
                                                            <div className="input-group-text adminpage-checkbox">
                                                                <input 
                                                                            type="checkbox" 
                                                                            checked={selectedUsers.includes(group)}
                                                                            onChange={() => toggleUserSelection(group)}
                                                                        />
                                                            </div>
                                                            </div>
                                                            {open.includes(group.group_id) ? (<div>{group.users.map((single_user) => 
                                                                <p className="m-0 ms-4"><button value={single_user.id}  onClick={() => toggleUserSelection(single_user.id)} className={`p-1 mt-0 mb-0 btn `}>{single_user.name}</button> {selectedUsers.includes(single_user.id) ? <i className="bi bi-check-all"></i> : null}</p>)}<hr/></div>) : null}
                                                        </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-6">
                                        </div>
                                    </div>

                                </div>

                                <div className="p-2">
                
                                    <button className="btn adminNav" style={{width: "100%"}} onClick={(e) => handleEdit(e, taskDetails)}>Save Changes</button>
                                    {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}

                                </div>
                                </div>
                            </div>}
                                <hr />
                                <h3>Total Assignees: {taskDetails.assigned_to.length}</h3>
                                {(taskDetails.assigned_to.length === 0) && (<p>No further details, as there are no users in the assigned to this task.</p>)}
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
