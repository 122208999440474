import React, {useState} from "react";
import api from "services/api";

// generic button for deleting a user account given a userID

// ADMIN USER REQUIRED AND WILL DELETE A USER ACCOUNT WITHOUT A PASSWORD!!!!!!!!!!!! USE WITH CAUTION :3
function DeleteUser({userID}) {
    const [screen, setScreen] = useState(0)
    async function handleDelete() {
        if (screen === 0){
            setScreen(1)
        } else if (screen === 1){
            setScreen(2)
        } else if (screen === 2){
            await api.delete("users/" + userID).then(success => {
                window.location.reload(false)
            }, error =>
            {
                console.log("error" + error);
            });
        };
    }
    
    return (
            <button onClick={handleDelete} className="settings-button"><i className="bi bi-trash "></i> {(screen === 0 && "delete")}{(screen === 1 && "are you sure")}{(screen === 2 && "this action cannot be reversed and will delete all data on this user. continue?")}</button> 
        );
}

export default DeleteUser;