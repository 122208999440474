import "index.css";
import React, { useState } from 'react';
import api from "services/api";

export default function AdminUserHistory({userID}) {
    const [userData, setUserData] = useState ([]);
    const [logSearchTerm, setLogSearchTerm] = useState ([]);
    const [userHistory, setUserHistory] = useState();
    const [dateFrom, setDateFrom] = useState ();
    const [dateTo, setDateTo] = useState ();
    const [loading, setLoading] = useState(false);
    const [foodSearch, setFoodSearch] = useState(true);
    const [waterSearch, setWaterSearch] = useState(true);
    const [moodSearch, setMoodSearch] = useState(true);
    const [sleepSearch, setSleepSearch] = useState(true);
    const [hideDates, setHideDates] = useState(false);
    let updatedFrom, updatedTo;

    const getDate = (timeshift) => {
        const now = new Date();
        now.setDate(now.getDate() - timeshift)
        return now.toISOString().slice(0, 10); // formats to "yyyy-MM-ddTHH:mm"
    };


    async function handleUserHistory(value) {

        if (value === 0){
            updatedFrom = getDate(0)
        } else if (value === 1){
            updatedFrom = getDate(7)
        } else if (value === 2){
            updatedFrom = getDate(28)
        }
        if (value !== 3){
            setDateFrom(updatedFrom);
            updatedTo = getDate(0)
            setDateTo(updatedTo); 

            getTrackingData(); 
        }
        setUserHistory(value);
    }

    async function handleCustomDate(e, from) {
        if (from){
            updatedFrom = e.target.value;
            setDateFrom(e.target.value);
            updatedTo = dateTo;
        } else {
            updatedTo = e.target.value;
            setDateTo(e.target.value);
            updatedFrom = dateFrom;
        }
        console.log(updatedFrom);
        console.log(updatedTo); 
        if (updatedFrom || updatedTo){
            getTrackingData();
        }
    }

    async function getTrackingData(){
        setUserData([]);
        setLoading(true);
        await api.get(`users/${userID}/tracker`, { params: { fromDate: updatedFrom, toDate:updatedTo}}).then(success => {
            setLoading(false);
            setUserData(success.data);
        }, error =>
        {
            console.log("error" + error);
        });
    }

    // if (!isDisplay) return null; // Don't render if modal is not open

    return (
        <div>
            <nav className="nav nav-pills flex-column flex-lg-row">
                    <button className="flex-lg-fill text-lg-center adminNav" value="0" onClick={(e) => {handleUserHistory(0)}} disabled={userHistory === 0}>Today</button>
                    <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={(e) => {handleUserHistory(1)}} disabled={userHistory === 1}>Past Week</button>
                    <button className="flex-lg-fill text-lg-center adminNav" value="2" onClick={(e) => {handleUserHistory(2)}} disabled={userHistory === 2}>Past 4 Weeks</button>
                    <button className="flex-lg-fill text-lg-center adminNav" value="3" onClick={(e) => {handleUserHistory(3)}} disabled={userHistory === 3}>Custom Range</button>
            </nav>
            {userHistory === 3 && 
                <div>
                    <div className="input-group">
                    <form className="form-floating">
                        <input
                            type="date"
                            id="data_from"
                            name="From"
                            className={"form-control p-3"}
                            value={updatedFrom}
                            onChange={(e) => {handleCustomDate(e, true)}}
                            /> 
                        </form>
                        <input
                            type="date"
                            id="date_to"
                            name="To"
                            className={"form-control p-3"}
                            value={updatedTo}
                            onChange={(e) => {handleCustomDate(e, false)}}
                            /> 
                    </div>
                </div>}
                <div className="container-fluid">
                <div className="row text-center">
                    <p>Showing results from <strong>{dateFrom}</strong> to <strong>{dateTo}</strong></p>
                </div>
                <div className="row">
                    <div className="col-10"> 
                    {/* display each day from user log */}
                    {loading && (<p><strong>Loading results, please wait</strong></p>)}
                    {userData.map((entry, index) => (
                        <div className="container-fluid">
                        {(!hideDates || ((entry.food && foodSearch) || (entry.water && waterSearch) || (entry.mood && moodSearch) || (entry.sleep && sleepSearch))) && (
                                <div className='row'>
                                    <div className="col-2">
                                        <span><strong>{entry.date}</strong></span>
                                    </div>
                                    <div className="col-10">
                                        <span>{(!entry.food || !foodSearch) && (!entry.water || !waterSearch) && (!entry.mood || !moodSearch) && (!entry.sleep || !sleepSearch)&& <i> No logging found on this date</i>}</span>
                                        {foodSearch && entry.food && 
                                            <div className="adminpage-userlist-infocard rounded-top">
                                                <p className="adminpage-userlist-infocard-header">Food</p>
                                            {entry.food.map((foodentry, index) => (
                                                <p className="adminpage-userlist-infocard-item">{foodentry.name} {foodentry.description && "(" + foodentry.description + ")"} at {foodentry.timestamp}</p>
                                            ))}
                                            </div>}
                                        {waterSearch && entry.water && 
                                            <div className="adminpage-userlist-infocard">
                                                <p className="adminpage-userlist-infocard-header">Water</p>
                                            {entry.water.map((waterentry, index) => (
                                                <p className="adminpage-userlist-infocard-item">{waterentry.quantity}ml at {waterentry.timestamp}</p>
                                            ))}
                                            </div>}
                                        {moodSearch && entry.mood && 
                                            <div className="adminpage-userlist-infocard rounded-bottom">
                                                <p className="adminpage-userlist-infocard-header">Mood</p>
                                            {entry.mood.map((moodentry, index) => (
                                                <p className="adminpage-userlist-infocard-item">{moodentry.mood} at {moodentry.timestamp}</p>
                                            ))}
                                            </div>}
                                        {waterSearch && entry.sleep && 
                                            <div className="adminpage-userlist-infocard rounded-bottom">
                                                <p className="adminpage-userlist-infocard-header">Sleep</p>
                                            {entry.sleep.map((sleepentry, index) => (
                                                <p className="adminpage-userlist-infocard-item">{sleepentry.start} - {sleepentry.end}</p>
                                            ))}
                                        </div>}
                                    </div>
                                <hr />
                                </div>
                        )}
                        </div>
                    ))}
                    </div>
                    <div className="col-2">
                        <p><strong>Search Filters</strong></p>
                        <div className="accountRedeem-search-box">
                            <input 
                                type="text" 
                                placeholder="Search logs..." 
                                value={logSearchTerm} 
                                onChange={e => setLogSearchTerm(e.target.value)} 
                            />
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="foodCheck" checked={foodSearch} onChange={(e) => {setFoodSearch(!foodSearch)}}/>
                            <label className="form-check-label" htmlFor="foodCheck">
                                Food
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="waterCheck" checked={waterSearch} onChange={(e) => {setWaterSearch(!waterSearch)}}/>
                            <label className="form-check-label" htmlFor="foodCheck">
                                Water
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="sleepCheck" checked={sleepSearch} onChange={(e) => {setSleepSearch(!sleepSearch)}}/>
                            <label className="form-check-label" htmlFor="foodCheck">
                                Sleep
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="moodCheck" checked={moodSearch} onChange={(e) => {setMoodSearch(!moodSearch)}}/>
                            <label className="form-check-label" htmlFor="foodCheck">
                                Mood
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="moodCheck" checked={hideDates} onChange={(e) => {setHideDates(!hideDates)}}/>
                            <label className="form-check-label" htmlFor="foodCheck">
                                Hide dates with no activity
                            </label>
                        </div>
                    </div>
                    </div> 

                    
                    </div>
        </div>    
    );
}