import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/buttons/Button.js";
import TextInput from "components/text/textInput";
import "./mood.css";
import Rating from "components/sliders/Rating"
import DatePicker from "components/date/DatePicker";
import dayjs from "dayjs";
import ImageUpload from "components/imageUpload/imageUpload";

const initialMoodState = {
    Happiness: undefined,
    Anger: undefined,
    Stress: undefined,
    Anxiety: undefined,
    Energy: undefined,
    Motivation: undefined,
    Pain: undefined,
    Swelling: undefined,
    Fatigue: undefined,
    Stiffness: undefined,
};


const moodLabels = [
    { mood: "Happiness", left: "Sad", right: "Happy", description: "How happy were you today?" },
    { mood: "Anger",  left: "Angry", right: "Calm", description: "How happy were you today?"  },
    { mood: "Stress",  left: "Stressed", right: "Relaxed", description: "How happy were you today?"  },
    { mood: "Anxiety", left: "Calm", right: "Anxious", description: "How happy were you today?"  },
    { mood: "Energy", left: "Lethargic", right: "Energetic", description: "How happy were you today?"  },
    { mood: "Motivation", left: "Unmotivated", right: "Motivated", description: "How happy were you today?"  },
    { mood: "Pain", left: "No pain", right: "Severe pain", description: "How happy were you today?"  },
    { mood: "Swelling", left: "Severe", right: "None", description: "How happy were you today?"  },
    { mood: "Fatigue", left: "Exhausted", right: "Rested", description: "How fatigued did you feel?"  },
    { mood: "Stiffness", left: "Stiff", right: "Flexible", description: "Did you feel stiff today?"  }
];

export default function Mood() {
    const [moodValues, setMoodValues] = useState(initialMoodState);
    const [overallDay, setOverallDay] = useState(undefined);
    const [notes, setNotes] = useState("");
    const [date, setDate] = useState(dayjs());
    const [digestiveIssues, setDigestiveIssues] = useState(null);
    const [digestiveSymptoms, setDigestiveSymptoms] = useState([]);

    const sumMoodValues = Object.values(moodValues)
            .filter(value => typeof value === "number") // Only keep numbers
            .reduce((sum, value) => sum + value, 0); // Sum them up



    

    const handleReset = () => {
        setMoodValues(initialMoodState);
        setOverallDay(undefined);
        setNotes("");
        setDate(new Date().toISOString().split("T")[0]);
        setDigestiveIssues(null);
        setDigestiveSymptoms([]);
    };

    const handleSubmit = () => {
        const entry = { date, moodValues, overallDay, notes, digestiveIssues, digestiveSymptoms };
        console.log("Mood Entry:", entry);
    };

    return (
        <div className="account-container">
            <h2 >Mood Tracker </h2>
            <p className="info-text">
                Log your mood, pain, and feelings for today using the scales below.
            </p>
            <DatePicker value={date} onChange={setDate} className="mb-3"/>
            {moodLabels.map((item ) => 
                <Rating 
                key={item.mood} 
                description={item.description}
                leftHint={item.left}
                rightHint={item.right}
                title={item.mood} 
                value={moodValues[item.mood]} 
                onChange={severity=>setMoodValues(prev => ({...prev, [item.mood]: severity}))}/>
            )}
            

            <div className="digestive-container">
                <label htmlFor="digestive-issues">Did you experience digestive issues?</label>
                <div className="d-flex gap-2">
                    <Button
                        width="w100"
                        text="Yes"
                        btnType={digestiveIssues === "yes" ? "primary" : "secondary"}
                        onClick={() => setDigestiveIssues("yes")}
                    />
                    <Button
                        text="No"
                        width="w100"
                        btnType={digestiveIssues === "no" ? "primary" : "secondary"}
                        onClick={() => setDigestiveIssues("no")}
                    />
                </div>
                {digestiveIssues === "yes" && (
                    <div className="digestive-symptoms">
                        <p>Please select the digestive issues you experienced:</p>
                        {[
                            "Bloating",
                            "Gas",
                            "Abdominal pain and cramping",
                            "Diarrhea",
                            "Constipation",
                            "Nausea and/or Vomiting",
                            "Loss of appetite",
                        ].map((symptom) => (
                            <div key={symptom} className="digestive-symptom-option">
                                <input
                                    type="checkbox"
                                    id={symptom}
                                    value={symptom}
                                    checked={digestiveSymptoms.includes(symptom)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setDigestiveSymptoms((prev) => [...prev, symptom]);
                                        } else {
                                            setDigestiveSymptoms((prev) =>
                                                prev.filter((item) => item !== symptom)
                                            );
                                        }
                                    }}
                                />
                                <label htmlFor={symptom}>{symptom}</label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <TextInput
                placeholder="Add notes about your mood..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows="3"
                width="w100"
                className="mb-3"
            />
            <Rating 
            value={overallDay} 
            onChange={setOverallDay} 
            title="Overall day" 
            description={`You rated your day as ${sumMoodValues}. How would you rate today?`}
            leftHint="Could have been better"
            rightHint="Excellent"/>

            <div className="d-flex w-100 gap-2 mt-3">
                <Button text="Reset" btnType="danger" width="w50" fontSize="fm" onClick={handleReset} disabled />
                <Button text="Submit" btnType="success" width="w50" fontSize="fm" onClick={handleSubmit} />
            </div>
            
        </div>
    );
}
