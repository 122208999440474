import {Button, InputGroup} from "react-bootstrap";
import api from "services/api";
import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// used for /verify/<token>. Sends an API request to the conformation API with the token in the URL. Only accessable to signed out users

export default function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [displayText, setDisplayText] = useState("Loading");


  useEffect(() => {
    // sends API request automatically to verify account
    const fetchData = async () => {
      await api.post("auth/confirm-email", {token: location.pathname.split("/").pop()}).then(success => {
        setDisplayText(success.data.message)
    }, error => {
        setDisplayText("An Error has Occured")
        });
    }
    fetchData();
  // eslint-disable-next-line    
  },[]);
     return (
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-2"></div>
                <div className="col-sm-12 col-lg-8">
                  <div>  
                      <div className="container-fluid accountpage-div">
                          <div className="row">
                              <div className="col-lg-6 col-sm-12 p-5 pb-3 pt-xl-5 pt-2">
                              <h2>Account Verification</h2>
                              </div>
                              <div className="col-lg-6 col-sm-12 p-1 p-lg-5 pt-0 pt-xl-5 pb-3">
                                <div className="p-2">
                                  <h3>{displayText}</h3>                                 
      
                                  <InputGroup style={{width: "100%"}}>
                                    <Button style={{width: "100%"}} variant="primary" type="submit" value="LOGIN" onClick={(e) => {navigate("../login");}}>Login</Button> 
                                  </InputGroup>
                              </div>
                      </div>
                    </div>
                  </div>
                <div className="col-lg-2"></div>
              </div>
            <div>
          </div>
        </div>
      </div>
    </div>
  );  
}  
 