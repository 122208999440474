import React, { useState, useEffect } from "react";
import api from "services/api";

export default function CommunityAdd() {
  const [forumTopics, setForumTopics] = useState([]);
  const [usrtitle, setUsrtitle] = useState("");
  const [usrtext, setUsrtext] = useState("");
  const [usrcat, setUsrcat] = useState("0")
  const [errorMessages, setErrors] = useState([]);

  var urlOne = "forum/topics"

  useEffect(() => {
    // gets list of topics for user to post to. 
    const fetchData = async () => {
        await api.get(urlOne)
        .then(success => {
            setForumTopics(success.data);
        },
        error => {
           // if error force navigate
           //window.location.replace("../community/")
        });
    }
    fetchData();
    
// eslint-disable-next-line    
},[]);

  async function handleAddPost(e) {
      e.preventDefault();      
      
      // input validation
      const errorMessages = {}
      if (usrtitle === ""){
        errorMessages.title = "Title cannot be blank"
      } else if (usrtitle.toString().length  > 50){
        errorMessages.tooLongTitle = "Title too long (max 50 chars)"
      }

      if (usrtext === ""){
        errorMessages.text = "Text cannot be blank"
      } else if (usrtext.toString().length  > 2000){
        errorMessages.tooLongText = "Post too long (max 2000 chars)"
      }

      if (parseInt(usrcat) === 0){
        errorMessages.category = "Please select a category"
      }

      if (Object.keys(errorMessages).length === 0){
          const newForumEntry = {
              text: usrtext,
              title: usrtitle,
              sticky: false, // update this so that a sticky toggle appears for admins and admins only
          };
          console.log(newForumEntry);
              await api.post(`/forum/topics/${parseInt(usrcat)}/threads`, newForumEntry).then(success => {
                // naviagate user to new thread after it has been created
                window.location.replace("./post/" + success.data.id)
              }, error =>
              {
                  setErrors(["A server error has occured"]);
              });
    } else {
      setErrors(errorMessages)
    };
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <div className="clearfix mb-3">
              <h2>Create a thread</h2>
            </div>
            <div className="p-2">
              <h5>Category</h5>
              <select className="form-control" onChange={(e) => setUsrcat(e.target.value)}>
                <option value="0">Select a topic for the thread</option> )
              {
                forumTopics.map( (entry,index) => 
                  <option key={entry.id} value={entry.id}>{entry.title}</option> ) 
                // list of topics based on API request from above
              }</select>
            </div>

            <div className="p-2">
              <h5>Title</h5>
              <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="form-input" onChange={(e) => setUsrtitle(e.target.value)}/>
            </div>

            <div className="p-2">
              <h5>Content</h5>
              <textarea className="form-control" style={{ minHeight: "150px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="form-input" onChange={(e) => setUsrtext(e.target.value)}/>
            </div>

            <div className="p-2">
              <h5>Attachments</h5>
              <input className="form-control" style={{ background: "#f1f1f1", color: "#65295f" }} type="file" aria-label="form-input"/>
            </div>
            <div>
              <button type="button" className="btn FoodPage-hover FoodPage-add" style={{width:"100%"}} onClick={handleAddPost}>Create Thread</button>
              {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
