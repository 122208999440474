import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "index.css";
import api from "services/api.js";
import Button from "components/buttons/Button.js"
import TextInput from "components/text/textInput"
import DatePicker from "components/date/DatePicker";
import dayjs from "dayjs"
import TimePicker from "components/time/TimePicker";


const default_state = {
    "category":"None",
    "food_name":"",
    "quantity":1,
    "description":"",
    "hour_min": dayjs().format("HH:mm"),
}


export default function AddFood() {
    const location = useLocation(); // Get the current location (URL)
    const params = new URLSearchParams(location.search);
    const dateString = params.get('date');
    const date = dateString ? dayjs(dateString) : dayjs();


    const navigate = useNavigate();
    const [foodObject, setFoodObject] = useState(default_state);
    const [dateDisplay, setdateDisplay] = useState(date);
    const [loading, setLoading] = useState(false);

    const [errorMessages, setErrors] = useState({});

    async function postFoodUpdate(){
        setLoading(true);
        const errorMessages = {}

        if (foodObject.food_name === ""){
            errorMessages.emptyFood = "Food cannot be empty"
        }

        if (foodObject.food_name.toString().length  > 20){
            errorMessages.tooLongFood = "Food name too long"
        }
        if (foodObject.description && foodObject.description.toString().length  > 175){
                errorMessages.tooLongDesc = "Food description too long"
        }
    
        if (Object.keys(errorMessages).length === 0){
            foodObject.time_period = dateDisplay.format("YYYY-MM-DD") + "T" +foodObject.hour_min + ":00"
            const { hour_min, day, ...foodData } = foodObject;  // Exclude min_hour
            await api.post("account/food/add",foodData).then(success => {
                setFoodObject(default_state);
                navigate(`../food/confirm?date=${dateDisplay.toISOString()}`, { state: {food: 1} })

            }, error =>{
                errorMessages.backend = "A server error has occured."
                setErrors(errorMessages)
            });
        }
        else {
            setErrors(errorMessages)
        }
        setLoading(false);
    }

    return (
        <div className="account-container">
            <h1 className="text-center">Add Food</h1>
            
            <div className="w-100">
                <h4 className="pt-2">When did you eat</h4>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                    <TimePicker  value={foodObject.hour_min} onChange={(e) => setFoodObject({...foodObject, hour_min: e.target.value})}/>
                    
                    </div>
                    <div>
                    <DatePicker value={dateDisplay} onChange={setdateDisplay} />
                    </div>
                </div>
                
                
            
                <h4>Food Name</h4>
                <TextInput className="mb-3" rows="1" value={foodObject.food_name} onChange={e => setFoodObject((prevState) => ({...prevState, food_name: e.target.value}))} width="w100"/>

                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="pt-2">Description</h4>
                    <span className="text-muted">optional</span>
                </div>
                <TextInput className="mb-3" rows="2" value={foodObject.description} onChange={e => setFoodObject((prevState) => ({...prevState, description: e.target.value}))} width="w100"/>

                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="pt-2">Notes</h4>
                    <span className="text-muted">optional</span>
                </div>
                <TextInput className="mb-3" rows="3" width="w100"/>

                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                <Button other="pt-2" text="Add" btnType="success" width="w100" fontSize="fm" onClick={postFoodUpdate} disabled={loading}/>

            </div>
        </div>
    );
}
