import React from 'react';
import "index.css";
import AccountSettings from "pages/account/AccountSettings"

// lol this just loads the user settings. it could be easily fixed to just have the buttons

export default function AdminSettings() {

    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
                <AccountSettings/>
            </div>
        </div>
    </div>
    </div>
    );
}
