import React, {useTransition, useState, useEffect } from 'react';
import "index.css";
import "./admin.css"
import api from "services/api";
import LoadingPlaceholder from "components/LoadingPlaceholder"

export default function AdminQuestions() {
    const [searchTerm, setSearchTerm] = useState('');
    const [questionaires, setQuestionaires] = useState([])
    const [questionDetails, setQuestionDetails] = useState("");
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageURL, setImageURL] = useState();
    const [deadline, setDeadline] = useState();
    const [frequency, setFrequency] = useState(0);
    const [points, setPoints] = useState(0);
    const [assignedTo, setAssignedTo] = useState({});
    const [enabled, setEnabled] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isGlobalQuestion, setIsGlobalQuestion] = useState(false);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState([]);
    const [isDefaultQuestion, setIsDefaultQuestion] = useState(false);
    const [numQuestions, setNumQuestions] = useState(1);
    const [questions, setQuestions] = useState([{required: true}]);
    

    const [edit, setEdit] = useState(false);
    const [errors, setErrors] = useState([]);
    

    
    const [, startTransition] = useTransition();

    const filteredQuestions = questionaires.filter(question => 
        question.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        //Gets a list of questionaires
        const fetchData = async () => {
            await api.get("/account/questions/admin")
            .then(success => {
                setQuestionaires(success.data);
                // console.log(success.data)
            },
            error => {
                console.log("Unable to load questionaires");
            });
            await api.get("/users")
            .then(success => {
                setUserList(success.data.groups);
            },
            error => {
                console.log("Unable to load users");
            });
        }
        fetchData();
      },[]);

    
    // todo: date validation
    function handleInputValidation(doQuestions){
        const errorMessages = {}
        if (title === ""){
            errorMessages.title= "Title cannot be blank"
        } else if (title.toString().length  > 50){
            errorMessages.title = "Questionaire Title too long (max 50 characters)"
        }

        if (description === ""){
            errorMessages.description = "Questionaire Description cannot be blank"
        } else if (description.toString().length  > 500){
            errorMessages.description = "Questionaire Description too long (max 500 characters)"
        }

        if (points < 0){
            errorMessages.points = "Points can't be less than zero"
        }

        if (frequency < 0){
            errorMessages.points = "Frequency can't be less than zero"
        }

        if (doQuestions){
            for (let i = 0; i < questions.length; ++i) {
                //console.log(userResponse[i])
                if (!questions[i].type){
                    questions[i].type = "textarea"
                }
                if (questions[i].title){
                    if (questions[i].title.toString().length > 255){
                        errorMessages[i] = "Question  " + (i+1) + ": question too long"
                    } 
                    if (questions[i].description){
                        if (questions[i].description.toString().length > 512){
                            errorMessages[i] = "Question  " + (i+1) + ": description too long"
                        } 
                    }
                    if (Number(questions[i].points) < 0){
                        errorMessages[i] = "Question  " + (i+1) + ": negative points not allowed"
                    } 
                    if (questions[i].type === "mcq"){
                        if (questions[i].mcq_1 && !questions[i].mcq_2){
                            errorMessages[i] = "Question  " + (i+1) + ": two or more MCQ respones required"
                        }
                    }  
                } else {
                    errorMessages[i] = "Question  " + (i+1) + ": missing question"
                }
    
            } 
        }

        setErrors(errorMessages)
        return Object.keys(errorMessages).length
    }

    // handles user selection. sends an API request to get user information
    async function handleSelectQuestion(e, selQuestion) {
        setErrors([])
        setLoading(true);
        handleClear()
        await api.get(`account/questions/admin/${selQuestion.id}`).then(success => {
            setQuestionDetails(success.data);
            setTitle(success.data.title);
            setDescription(success.data.description);
            setImageURL(success.data.image_url);
            setDeadline(success.data.deadline);
            setFrequency(success.data.frequency);
            setPoints(success.data.points);
            setAssignedTo(success.data.assigned_to);
            setEnabled(success.data.enabled);
            setSelectedUsers(success.data.assigned_to);
            setLoading(false);
            setIsDefaultQuestion(success.data.default)
            console.log(success.data)
        }, error =>
        {
        return // generally we need better error handlign
        });
    }

    // to do : do something here?
    async function handleDeleteQuestion(e, selQuestion) {
        setLoading(true)
        setQuestionDetails();
        await api.delete(`account/questions/admin/${selQuestion.id}`).then(success => {
            // console.log(success)
            handleClear()
            setLoading(false)
            setQuestionaires(questionaires.filter(question => question.id !== selQuestion.id));
                }, error =>
        {
            setErrors(["An error has occured. Please try again."])
            setLoading(false)
        });
    }


    async function handleAdd(e) {
        setErrors([])
        const errorNo = handleInputValidation(true);    
        if (errorNo === 0){
            // setLoading(true);
            // collapse groups and users into a single array of userIds
            const filteredSelectionId = []
            selectedUsers.forEach(function (item, index){
                if (item.users){
                    let IdFilter = item.users.map(item => item.id);
                    filteredSelectionId.push.apply(filteredSelectionId, IdFilter)
                } else {
                    filteredSelectionId.push(item.id)
                }
            })
            const addData = {
                title: title,
                description: description,
                image_url: imageURL ? imageURL: "",
                points: Number(points),
                deadline: deadline,
                frequency: Number(frequency),
                enabled: enabled,
                assigned_to: isGlobalQuestion ? ["Global"] : filteredSelectionId,
                default : isDefaultQuestion,
                questions: questions
            }
            console.log(addData)

            await api.post("account/questions/admin", addData).then(success => {
                handleSelectQuestion(e, success.data)
                addData.id = success.data.id
                setQuestionaires([...questionaires, addData])
            }, error =>
            {
                setErrors({backend: "An error has occured. Please try again."})
            });
        }
    }

    async function handleEdit(e, selQuestion) {
        setErrors({})
        const errorNo = handleInputValidation(false);    
        if (errorNo === 0){
            const filteredSelectionId = []
            selectedUsers.forEach(function (item, index){
                if (item.users){
                    let IdFilter = item.users.map(item => item.id);
                    filteredSelectionId.push.apply(filteredSelectionId, IdFilter)
                } else {
                    // different because editing disregards user data structure used when creating :3
                    filteredSelectionId.push(item)
                }
            })
            const compressedSelection = [...new Set(filteredSelectionId)];
            const addData = {
                title: title,
                description: description,
                image_url: imageURL ? imageURL: "",
                points: Number(points),
                deadline: deadline,
                frequency: Number(frequency),
                assigned_to: isGlobalQuestion ? ["Global"] : compressedSelection,
                default : isDefaultQuestion
            }
            console.log(addData)
            await api.patch(`account/questions/admin/${selQuestion.id}`, addData).then(success => {
                setErrors(["Questionaire Successfully Updated!"])
                setEdit(false)
            }, error =>
            {
                setErrors(["An error has occured. Please try again."])
            });
        }
    }

    async function handleDisabled(e, selId) {
        setErrors([])
        await api.post("account/questions/admin/" + selId.id + "/disable").then(success => {
            setEdit(false)
            questionDetails.enabled = !questionDetails.enabled
            setErrors([questionDetails.enabled ? "Questionaire Enabled" : "Questionaire Disabled"])

        }, error =>
        {
            setErrors([error.data.message])
        });
    }

    async function handleClear() {
        setErrors([])
        setQuestionDetails();
        setTitle("");
        setDescription("");
        setImageURL("");
        setDeadline();
        setFrequency(0);
        setPoints(0);
        setAssignedTo([]);
        setEnabled(true);
        setSelectedUsers([]);
        setIsGlobalQuestion(false);
        setOpen([]);
        setEdit(false);
        setIsDefaultQuestion(false)

    }

    const toggleUserSelection = (user) => {

        if (selectedUsers.includes(user)) {
            setSelectedUsers(selectedUsers.filter(u => u !== user));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const toggleOpen = (group) => {
        if (open.includes(group.group_id)) {
            setOpen(open.filter(users => users !== group.group_id))
        } else {
            let newOpen = [...open]
            newOpen.push(group.group_id)
            setOpen(newOpen)
        }

    }

    const incrementQuestions = () => {
        if (numQuestions < 26){
            console.log(questions)
            setNumQuestions(numQuestions + 1);
            setQuestions([...questions, {required: true}])
        }
    }

    const decrementQuestions = () => {
        if (numQuestions > 1){
            setNumQuestions(numQuestions - 1);
            setQuestions(questions.slice(0,numQuestions-1))
        }
    }

    const changeForm = event => {
        const { id, value, title } = event.target;

        if (title === "required"){
            setQuestions(prevState => {
                const updatedState = [...prevState];
                updatedState[id] = {
                    ...updatedState[id],
                    [title]: !(questions[id].required),
                };
                return updatedState;
            });
        } else {
            setQuestions(prevState => {
                const updatedState = [...prevState];
                updatedState[id] = {
                    ...updatedState[id],
                    [title]: value,
                };
                return updatedState;
            });
        }
       
      };


    // const filteredUsers = userList.filter(user => 
    //     user.title.toLowerCase().includes(assigneeSearchTerm.toLowerCase())
    // );

    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
            <div className="col-lg-2 ps-0 ms-0">
                <h1>Select a question</h1>
                <button className="btn adminNav" style={{width: "100%"}} onClick={handleClear}>Create a question</button>
                    <div className="adminpage-search-box bg-white pb-0 mb-0">
                        <input 
                            type="text" 
                            placeholder="Search questionaires..." 
                            value={searchTerm} 
                            onChange={e => setSearchTerm(e.target.value)} 
                        />
                    </div>
                    <div className="adminpage-sidebar bg-white pt-0 mt-0 " >
                        <div >
                            <div >
                                {filteredQuestions.map((question, index) => (
                                    <p>
                                                                            <button 
                                        value={question.id}
                                        className="btn text-black pb-0 "
                                        onClick={(e) => {handleSelectQuestion(e, question)}}
                                        disabled={questionDetails ? questionDetails.id === question.id : false}
                                    >
                                        {question.title}
                                    </button>
                                    </p>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10">
                <div>
                    {!questionDetails && !loading && (
                            <div>
                            <h1>..or create a new one</h1>
                            <h3>Create a Question</h3>
                            <hr />
                            <div>
                                <h4>Questionaire Metadata</h4>
                                <div className="p-2">
                                    <h5>Questionaire Name</h5>
                                    <p className='mb-1'>A short name for the questionaire shown to users. (Max 50 characters)</p>
                                    <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Description</h5>
                                    <p className='mb-1'>A longer description of the questionaire. (Max 256 characters)</p>
                                    <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Image URL</h5>
                                    <p className='mb-1'>A link to an image to be displayed along side the questionaire.</p>
                                    <div className="input-question">
                                        <input type="link" className="form-control" value={imageURL} onChange={(e) => setImageURL(e.target.value)} aria-label="Reward Image URL"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Question Deadline</h5>
                                    <p className='mb-1'>The date at which users will no longer be able to complete the questionaire.</p>
                                    <div className="input-question">
                                        <input type="date" className="form-control" value={deadline} onChange={(e) => setDeadline(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Question Frequency</h5>
                                    <p className='mb-1'>After how many days should users be able to repeat this questionaire? Leave blank for zero.</p>
                                    <div className="input-question">
                                        <input type="number" className="form-control" value={frequency} onChange={(e) => setFrequency(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Points</h5>
                                    <p className='mb-1'>How many points is the questionaire worth?</p>
                                    <div className="input-question">
                                        <input type="number" className="form-control" value={points} onChange={(e) => setPoints(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                <h4>Questions</h4>
                                <div className="p-2">
                                    <p>A quick word about questionaires: each questionaire can hold up to 25 questions. Supported question types: Text, Number, Range (1-100), Binary (yes/no, if no, they will be prompted with a text-box), and Multiple-Choice (maximum 5 choices). Users are limited to 256 characters for text responses, and number responses cannot be negative.</p>
                                    <div class="input-group w-auto align-items-center">
                                        <p className="pt-2">Number of Questions:</p>
                                        <input type="button" value="-" onClick={decrementQuestions} disabled={numQuestions === 0}/>
                                        <input type="number" step="1" min="1" max="25" value={numQuestions} class="quantity-field border-0 text-center w-25" />
                                        <input type="button" value="+" onClick={incrementQuestions} disabled={numQuestions === 25}/>
                                    </div>
                                    <div>
                                    {questions.map((singleQuestion, index) => (
                                        <div className="p-2">
                                        <h5>Question {index + 1}</h5>
                                        <p className='mb-1'>What is the type of question?</p>
                                        <div className="input-question">
                                        <select className="form-control" onChange={changeForm} title="type" id={index} value={questions[index].type}>
                                            <option value="textarea">Text</option> 
                                            <option value="number">Number</option> 
                                            <option value="range">Range</option> 
                                            <option value="binary">Binary</option> 
                                            <option value="mcq">Multiple Choice</option> 
                                            <option value="msq">Multiple Select</option>
                                        </select>
                                        </div>
                                        <p className='mb-1'>Question</p>
                                        <div className="input-question">
                                            <input type="text" className="form-control" aria-label="Question"  onChange={changeForm} title="title" id={index} value={questions[index].title}/>
                                        </div>
                                        <p className='mb-1' >Question Description (optional)</p>
                                        <div className="input-question">
                                            <input type="text" className="form-control" aria-label="Question Description" onChange={changeForm} title="description" id={index} value={questions[index].description} />
                                        </div>
                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" onChange={changeForm} title="required" id={index} checked={questions[index].required}/>
                                            <p className='mb-1'>Mandatory response?</p>
                                        </div>
                                        {questions[index].type === "mcq" || questions[index].type === "msq"   && (
                                            <div className="input-question">
                                                <p className='mb-1' >Multiple Responses (leave options blank if fewer than five required)</p>
                                                <input type="text" className="form-control"  aria-label="Multiple Choice Responses" onChange={changeForm} title="mcq_1" id={index} value={questions[index].mcq_1}/>
                                                <input type="text" className="form-control"  aria-label="Multiple Choice Responses" onChange={changeForm} title="mcq_2" id={index} value={questions[index].mcq_2}/>
                                                <input type="text" className="form-control"  aria-label="Multiple Choice Responses" onChange={changeForm} title="mcq_3" id={index} value={questions[index].mcq_3}/>
                                                <input type="text" className="form-control"  aria-label="Multiple Choice Responses" onChange={changeForm} title="mcq_4" id={index} value={questions[index].mcq_4}/>
                                                <input type="text" className="form-control"  aria-label="Multiple Choice Responses" onChange={changeForm} title="mcq_5" id={index} value={questions[index].mcq_5}/>
                                            </div>
                                        )}
                                    </div>
                                        ))}
                                    </div>
                                </div>
                                
                                </div>
                                <hr />

                                <div className="p-2">
                                    <h5>Assignees</h5>
                                    <p className='mb-1'>Who should the question be assigned to?</p>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="adminpage-info-box">
                                            <div >
                                                <div>
                                                    <div className="assign-global-checkbox pb-0 mb-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="global-question-checkbox" 
                                                            checked={isGlobalQuestion} 
                                                            onChange={() => setIsGlobalQuestion(!isGlobalQuestion)}
                                                        />
                                                        <label htmlFor="global-question-checkbox">Assign to everyone</label>
                                                    </div>
                                                    <div className="assign-global-checkbox pt-0 mt-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="default-question-checkbox" 
                                                            checked={isDefaultQuestion} 
                                                            onChange={() => setIsDefaultQuestion(!isDefaultQuestion)}
                                                            disabled={!isGlobalQuestion}
                                                        />
                                                        <label htmlFor="default-question-checkbox">Assign to new users as they register? {!isGlobalQuestion && ("Only available when question is set to \"everyone\"")}</label>
                                                    </div>
                                                </div>
                                                {!isGlobalQuestion && (
                                                <div className="p-3 ">
                                                    <div>
                                                    {userList.map((group, index) => (
                                                    <div>
                                                        <div className="input-group m-2" style={{width:"100%"}}>
                                                            <button 
                                                                value={group.group_id}
                                                                className={`adminpage-user-box ${selectedUsers.includes(group) ? "selected" : ""} ${open.includes(group.group_id) ? "rounded-top" : "rounded"}`}
                                                                onClick={() => toggleOpen(group)}
                                                                disabled={selectedUsers.includes(group)}
                                                            >
                                                            <p className="text-white">{open.includes(group.group_id) ? <i class="bi bi-arrow-down-square"></i> : <i class="bi bi-arrow-right-square"></i>} {group.title ? group.title : "Unassigned"}</p>
                                                            </button>
                                                            <div className="input-group-text adminpage-checkbox">
                                                                <input 
                                                                            type="checkbox" 
                                                                            checked={selectedUsers.includes(group)}
                                                                            onChange={() => toggleUserSelection(group)}
                                                                        />
                                                            </div>
                                                            </div>
                                                            {open.includes(group.group_id) ? (<div>{group.users.map((single_user) => 
                                                                <p className="m-0 ms-4"><button value={single_user.id}  onClick={() => toggleUserSelection(single_user)} className={`p-1 mt-0 mb-0 btn `}>{single_user.name}</button> {selectedUsers.includes(single_user) ? <i class="bi bi-check-all"></i> : null}</p>)}<hr/></div>) : null}
                                                        </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-6">
                                        </div>
                                    </div>

                                </div>

                                <div className="p-2">
                                    <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="disabledCheck" checked={enabled} onChange={() => {setEnabled(!enabled)}}/>
                                            <p className='mb-1'>By default, this question will be <b>immediately</b> available to anyone assigned to the question. Uncheck this box to create this question but prevent users from joining. questionaires can be hidden and unhidden at any time by selecting the record from the panel on your left.</p>
                                    </div>
                                    <button className="btn adminNav" style={{width: "100%"}} onClick={handleAdd}>Create Question</button>
                                    {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}

                                </div>
                                </div>
                            </div>)}
                        {loading && (<LoadingPlaceholder></LoadingPlaceholder>)}
                        {questionDetails && !loading && (
                            <div>
                            <h1>{questionDetails.title}</h1>
                            <nav className="nav nav-pills flex-column flex-lg-row pt-2">
                                <h5>Change Questionaire Metadata</h5>
                                <button className="flex-lg-fill text-lg-center adminNav rounded-start ms-2" value="0" onClick={() => setEdit(!edit)}>{edit ? "Editing.." : "Edit"}</button>
                                <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={(e) => handleDisabled(e, questionDetails)} >{questionDetails.enabled ? "Disable" : "Enable"}</button>
                                <button className="flex-lg-fill text-lg-center adminNav rounded-end" value="2"  onClick={(e) => handleDeleteQuestion(e, questionDetails)} disabled={!questionDetails.delete_allowed}>Delete</button>
                            </nav>
                            <span className="text-danger">{!questionDetails.delete_allowed && ("Deletion of this question is not possible as it has been completed by a user. To stop people completing this question, disable it. ")}</span>
                            {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}
                            {edit && <div>
                            <div>
                                <div className="p-2">
                                    <h5>Question Name</h5>
                                    <p className='mb-1'>A short name for the question shown to users. (Max 50 characters)</p>
                                    <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="question-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Description</h5>
                                    <p className='mb-1'>A longer description of the question. (Max 256 characters)</p>
                                    <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="question-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Image URL</h5>
                                    <p className='mb-1'>A link to an image to be displayed along side the question.</p>
                                    <div className="input-question">
                                        <input type="link" className="form-control" value={imageURL} onChange={(e) => setImageURL(e.target.value)} aria-label="Question Image URL"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Question Deadline</h5>
                                    <p className='mb-1'>The date at which users will no longer be able to complete the question.</p>
                                    <div className="input-question">
                                        <input type="date" className="form-control" value={deadline} onChange={(e) => setDeadline(e.target.value)} aria-label="Question deadline"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Question Frequency</h5>
                                    <p className='mb-1'>After how many days should users be able to repeat a question? Leave blank for zero.</p>
                                    <div className="input-question">
                                        <input type="number" className="form-control" value={frequency} onChange={(e) => setFrequency(e.target.value)} aria-label="Question frequency"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Points</h5>
                                    <p className='mb-1'>How many points is the question worth?</p>
                                    <span className="text-danger">{!questionDetails.delete_allowed && ("You can't edit the point value of this question as it's already been completed by someone.")}</span>
                                    <div className="input-question">
                                        <input type="number" className="form-control" value={points} onChange={(e) => setPoints(e.target.value)} disabled={!questionDetails.delete_allowed} aria-label="Question points"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Assignees</h5>
                                    <p className='mb-1'>Who should the question be assigned to?</p>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="adminpage-info-box">
                                            <div >
                                                <div>
                                                    <div className="assign-global-checkbox pb-0 mb-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="global-question-checkbox" 
                                                            checked={isGlobalQuestion} 
                                                            onChange={() => setIsGlobalQuestion(!isGlobalQuestion)}
                                                        />
                                                        <label htmlFor="global-question-checkbox">Assign to everyone</label>
                                                    </div>
                                                    <div className="assign-global-checkbox pt-0 mt-2">
                                                        <input 
                                                            type="checkbox" 
                                                            id="default-question-checkbox" 
                                                            checked={isDefaultQuestion} 
                                                            onChange={() => setIsDefaultQuestion(!isDefaultQuestion)}
                                                            disabled={!isGlobalQuestion}
                                                        />
                                                        <label htmlFor="default-question-checkbox">Assign to new users as they register? {!isGlobalQuestion && ("Only available when question is set to \"everyone\"")}</label>
                                                    </div>
                                                </div>
                                                {!isGlobalQuestion && (
                                                <div className="p-3">
                                                    <div>
                                                    {userList.map((group, index) => (
                                                    <div>
                                                        <div className="input-group m-2" style={{width:"100%"}}>
                                                            <button 
                                                                value={group.group_id}
                                                                className={`adminpage-user-box ${selectedUsers.includes(group) ? "selected" : ""} ${open.includes(group.group_id) ? "rounded-top" : "rounded"}`}
                                                                onClick={() => toggleOpen(group)}
                                                                disabled={selectedUsers.includes(group)}
                                                            >
                                                            <p className="text-white">{open.includes(group.group_id) ? <i class="bi bi-arrow-down-square"></i> : <i class="bi bi-arrow-right-square"></i>} {group.title ? group.title : "Unassigned"}</p>
                                                            </button>
                                                            <div className="input-group-text adminpage-checkbox">
                                                                <input 
                                                                            type="checkbox" 
                                                                            checked={selectedUsers.includes(group)}
                                                                            onChange={() => toggleUserSelection(group)}
                                                                        />
                                                            </div>
                                                            </div>
                                                            {open.includes(group.group_id) ? (<div>{group.users.map((single_user) => 
                                                                <p className="m-0 ms-4"><button value={single_user.id}  onClick={() => toggleUserSelection(single_user.id)} className={`p-1 mt-0 mb-0 btn `}>{single_user.name}</button> {selectedUsers.includes(single_user.id) ? <i class="bi bi-check-all"></i> : null}</p>)}<hr/></div>) : null}
                                                        </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-6">
                                        </div>
                                    </div>

                                </div>

                                <div className="p-2">
                
                                    <button className="btn adminNav" style={{width: "100%"}} onClick={(e) => handleEdit(e, questionDetails)}>Save Changes</button>
                                    {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}

                                </div>
                                </div>
                            </div>}
                                <hr />
                                <div>
                                    <h5>Questions</h5>
                                    <p>Questions cannot be changed after creation to maintain integrity of user responses.</p>
                                    {questionDetails.questions.map((singleQuestion, index) => (
                                        <div className="p-2">
                                        <h5>Question {index + 1}</h5>
                                        <p className='mb-1'><b>{singleQuestion.title}</b></p>
                                        <p className='mb-1'>Type: {singleQuestion.type}</p>
                                        <p className='mb-1'>Required: {singleQuestion.required === "T" ? "Yes" : "No"}</p>
                                        {singleQuestion.description && (<p className="mb-1">Description: {singleQuestion.description}</p>)}
                                        {singleQuestion.type === "mcq" && (<p className="mb-1">Options: {singleQuestion.options.replaceAll("|",", ")}</p>)}
                                    </div>
                                        ))}
                                </div>
                                <hr />
                                <h5>Total Assignees: {questionDetails.assigned_to.length}</h5>
                                {(questionDetails.assigned_to.length === 0) && (<p>No further details, as there are no users in the assigned to this question.</p>)}
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
