import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import DeletePassword from "components/deletePassword"
import SettingsPopup from "components/settingsPopup"
import authenticationService from 'services/authentication.service';
import { Icon } from '@iconify/react';
import SettingsButton from 'components/buttons/SettingsButton';


export default function AccountSettings() {
  const [deleteAcc, setDeleteAcc] = useState(false);
  const [details, setDetails] = useState(false);
  const [password, setPassword] = useState(false);
  const [email, setEmail] = useState(false);
  const [name, setName] = useState(false);
  const [shareCode, setShareCode] = useState(false);
  
  async function logoutButton(e) {
      e.preventDefault();
      await authenticationService.logout();
      window.location.replace('/');
  }

  return (
    <div className="account-container">
        <div className="row align-items-start">
          <div className="col-lg-6 pb-3 pt-2">
              <div>
                <h3>Accessibility Options</h3>
              </div>
                <div style={{ marginTop: '20px'}}>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Increased Contrast</span>
                <label className="switch float-end">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                </div>
                <div style={{ marginTop: '20px'}}>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Bold Text</span>
                <label className="switch float-end">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                </div>
                <hr />
                <h3>Group Settings</h3>
                <SettingsButton onClick={() => setShareCode(true)} icon="bi:people-fill">Enter a Share Code </SettingsButton>
                <SettingsPopup isOpen={shareCode} onClose={() => setShareCode(false)} type="sharecode"/>
                <hr />
                <h3>Update Personal Details</h3>
                <button className="settings-button" onClick={() => setName(true)}>Change Name</button>
                <SettingsPopup isOpen={name} onClose={() => setName(false)} type="name"/>

                <button className="settings-button" onClick={() => setDetails(true)}>Change Height/Weight/Gender</button>
                <SettingsPopup isOpen={details} onClose={() => setDetails(false)} type="details"/>
            </div>
          
          <div className="col-lg-6 pb-3 pt-2">
            <h3>Account Settings</h3>
            <SettingsButton onClick={logoutButton} icon="bi:box-arrow-left">Logout</SettingsButton>
            <SettingsButton onClick={() => setEmail(true)} icon="bi:at">Change Email</SettingsButton>
            <SettingsPopup isOpen={email} onClose={() => setEmail(false)} type="email"/>
            <SettingsButton onClick={() => setPassword(true)} icon="bi:key-fill">Change Password</SettingsButton>
            <SettingsPopup isOpen={password} onClose={() => setPassword(false)} type="password"/>
            <SettingsButton onClick={() => setDeleteAcc(true)} icon="bi:trash-fill">Delete Account</SettingsButton>
            <DeletePassword isOpen={deleteAcc} onClose={() => setDeleteAcc(false)}/>
            <hr />
            <h3>About Healthify</h3>  
            <div className="input-group" style={{width:"100%"}}>
              <Link to="../../terms" style={{width:"50%"}} onClick={() => {document.documentElement.scrollTo({top: 0,left: 0,behavior: 'instant'});}}><button className="settings-button-round-start" >Terms</button></Link>
              <Link to="../../privacy" style={{width:"50%"}} onClick={() => {document.documentElement.scrollTo({top: 0,left: 0,behavior: 'instant'});}}><button className="settings-button-round-end ">Privacy</button></Link>
            </div>
            <div className="input-group" style={{width:"100%"}}>
              <Link to="../../contact" style={{width:"50%"}} onClick={() => window.scrollTo(0, 0)}><button className="settings-button-round-start" >Contact</button></Link>
              <Link to="https://monikagostic.com/" style={{width:"50%"}}><button className="settings-button-round-end ">Dr Monika Gostic Ltd <i className="bi bi-box-arrow-up-right"></i></button></Link>
            </div>
            <hr />

            <small>Healthify is developed by <b>Team Delta <i className="bi bi-triangle-half"></i></b> for Dr Monika Gostic.<br /><br /><i className="bi bi-c-circle"></i> 2024-2025</small>
          </div>
        </div>
      </div>
    
  );
}
