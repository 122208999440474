// Unauthenticated and Navigational
import Contact from "./pages/root/Contact";
import Terms from "./pages/root/Terms";
import Privacy from "./pages/root/Privacy";
import NoMatch from "./pages/root/NoMatch";
import Layout from "./pages/root/Layout";
import Footer from "./pages/root/Footer";
import BlankFooter from "./pages/root/BlankFooter";
import Home from "./pages/root/Home";

// Admin
import Admin from "./pages/admin/Admin";
import AdminHome from "./pages/admin/AdminHome";
import AdminCreateTask from "./pages/admin/AdminCreateTask";
import AdminTaskSuccess from "./pages/admin/AdminTaskSuccess";
import AdminUsersPage from "pages/admin/AdminUsersPage";

// Account Business
import AccountHome from "./pages/account/AccountHome";
import AccountSettings from "./pages/account/AccountSettings";
import AccountPointHistory from "./pages/account/AccountPoint";
import RegistrationQues from "./pages/account/RegistrationQuestions";
import TaskTemplate from "./pages/account/TaskTemplate";
import QuestionTemplate from "./pages/account/QuestionTemplate";
import ActivitySuccessful from "./pages/account/ActivitySuccessful";

// Account Tracking
import PeriodPage from "./pages/account/tracking/period/PeriodPage";
import FoodPage from "pages/account/tracking/food/FoodPage";
import AddFood from "pages/account/tracking/food/AddFood";
import AddWater from "pages/account/tracking/food/AddWater";
import Confirm from "pages/account/tracking/food/FoodPageConfirm.js";
import SleepPage from "pages/account/tracking/sleep/SleepPage";
import MoodPage from "pages/account/tracking/mood/MoodPage";
import WaterTarget from "pages/account/tracking/food/WaterTarget";

// Account Rewards
import Rewards from "pages/account/Rewards";
import RedeemReward from "pages/account/RedeemReward";
import RedeemRewardSuccess from "pages/account/RedeemRewardSuccess";

// Authentication Business
import Login from "./pages/root/Login";
import Registration from "./pages/root/Registration";
import ResetPassword from "./pages/root/ResetPassword";
import Verify from "./pages/root/VerifyEmail";

// Forums
import CommunityHome from "./pages/community/CommunityHome";
import CommunityTopic from "./pages/community/CommunityTopic";
import CommunityPost from "./pages/community/CommunityPost";
import CommunityAdd from "./pages/community/CommunityAdd";

// System-Wide Helpers
import { Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "react-bootstrap";
import RequireAuth from "./components/auth/RequireAuth"
import RequireLoggedOut from "./components/auth/RequireLoggedOut"
import Role from "./services/role";
import Unauthorised from "pages/root/Unauthorised";

// Temporary Demo, delete this later
import DemoFeedback from "components/feedback/FeedbackDemo";
import AddSleep from "pages/account/tracking/sleep/AddSleep";


export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* here lies the pages with feet. */}
        <Route element={<Footer />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="contact" element={<Contact />} />
          <Route path="unauthorized" element={<Unauthorised/>} />
          <Route path="*" element={<NoMatch />} />


          <Route element={<RequireLoggedOut/>}>
            <Route path="login" element={ <Login />} />
            <Route path="register" element={<Registration />} />
            <Route path="reset/*" element={<ResetPassword />} />
            <Route path="verify/*" element={<Verify />} />
          </Route>
        </Route>
        
        {/* here lies the pages without feet */}
        <Route element={<BlankFooter />}>
          <Route path="community">
            <Route index element={<CommunityHome />}/>
            <Route path="topic/*" element={<CommunityTopic />} />
            <Route path="post/*" element={<CommunityPost />} />
            <Route element={<RequireAuth allowedRoles={[Role.Admin, Role.User]}/>}>
            <Route path="add/" element={<CommunityAdd />}/>
            </Route>
            
          </Route>

          <Route path="account/" element={<RequireAuth allowedRoles={[Role.User]}/>}>
            <Route index element={<AccountHome />}/>
            <Route path="survey" element={<RegistrationQues />} />
            <Route path="history" element={<AccountPointHistory />} />
            <Route path="redeem" element={<Rewards />} />
            <Route path="redeem/*" element={<RedeemReward />} />
            <Route path="redeem/success" element={<RedeemRewardSuccess />} />
            <Route path="task" element={<TaskTemplate />} />
            <Route path="question" element={<QuestionTemplate />} />
            <Route path="success" element={<ActivitySuccessful />} />
            <Route path="settings" element={<AccountSettings />} />
            <Route path="demo" element={<DemoFeedback />} /> {/* Demo, delete later */}

              <Route path="tracking/">
                <Route index element={<Navigate to="../../account" />} />
                <Route path="food" element={<FoodPage />} />
                <Route path="food/addfood" element={<AddFood />} />
                <Route path="food/target" element={<WaterTarget />} />
                <Route path="food/confirm" element={<Confirm />} />
                <Route path="sleep" element={<SleepPage />} />
                <Route path="sleep/add" element={<AddSleep />} />
                <Route path="period" element={<PeriodPage />} />
                <Route path="mood" element={<MoodPage />} />
              </Route>
          </Route>
          <Route path="admin/" element={<RequireAuth allowedRoles={[Role.Admin]}/>}>
            <Route index element={<AdminHome />} />
              <Route path="old" element={<Admin />} />
              <Route path="users" element={<AdminUsersPage/>}/>
            </Route>
          </Route>
        </Route>
    </Routes>
  );
};
