import { useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import api from 'services/api';
import { homepage } from "components/nav/links";
import Button from "components/buttons/Button";

export default function TaskTemplate() {
  const location = useLocation();
  const sendTo = location.state?.from?.pathname || homepage.to;
  const taskData = location.state;

  const [updatedTaskData, setUpdatedTaskData] = useState([])
  const [points, setPoints] = useState(0)
  const navigate = useNavigate(); // For navigation
  const [errors, setErrors] = useState("");

  useEffect(() => {
    //Run async function in use effect. 
    const fetchData = async () => {
        await api.get("account/tasks/" + taskData.id)
        .then(success => {
            console.log(success.data)
            setUpdatedTaskData(success.data);            
        },
        error => {
          console.log("error")
            // If error, force user back to index
            // window.location.replace("../redeem/")
        });

        await api.get("users/points",)
        .then(success => {
            setPoints(success.data.points);
        });
    }
    fetchData();
  // eslint-disable-next-line    
  },[]);

  if (!taskData) {
    navigate(homepage.to);
  }

  // handle the redemption
  async function handleConfirm(e) {
    await api.post("/account/tasks/" + updatedTaskData.id).then(success => {
      navigate('../success', {state: {title: updatedTaskData.title, points: updatedTaskData.points, totalPoints: points + updatedTaskData.points}})
      }, error => {
        setErrors(error.status)
    });
  }

  return (
      <div  className="account-container p-0 ">
        <div  className="row w-100">
          <div className="col w-100 p-0">
              <div style={{backgroundImage: `url(${updatedTaskData.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="redeem-cover p-5 pb-3 pt-5">
                  <h2>{taskData.title}</h2>
                  <p><b>{taskData.points}</b> points</p>

                </div>
              </div>
          </div>
      </div>
      <div  className="row w-100 pad-sides-16">
        <h2>About this task</h2>
        
        <p>{updatedTaskData.description}</p>
        
          {/* //Use navigate state={{ from: location }} */}
          <p><b>{errors}</b></p>
          <div className="d-flex gap-2 w-100">
            <Link to={sendTo} className="w-100"><Button btnType="secondary" width="w100">{sendTo === homepage.to? "Cancel":"Go back to tasks"}</Button></Link>
            <Button text="Redeem" btnType="success" width="w100" onClick={handleConfirm}/>
          </div>
      </div>
    </div>
  );
}
