import React, { act } from 'react'
import "./Rating.css"
import { Icon } from '@iconify/react'



const colour = {
    "-5": "#d9534f",
    "-1": "#f0ad4e",
    "0": "#ffd700",
    "1": "#5cb85c",
    "5": "#28a745"
}
const Rating = ({title, description = "", leftHint, rightHint, value, onChange}) => {
    return (
        <div className="rating-group">
            <div className='rating-group-container m-auto'>
            <h3 className="rating-group-title text-center mb-1">{title}</h3>
            <div className="rating-row">
                <p className="text-muted text-left mb-1">{description}</p>
                <div className="rating-bar-labels">
                    <span className="rating-bar-left">{leftHint}</span>
                    <span className="raring-bar-right">{rightHint}</span>
                </div>
                <div className="rating-bar-boxes">
                    {[-5, -1, 0, 1, 5].map((severity) => {
                        const active= value !== undefined &&
                        (severity === 0 || (Math.sign(value) === Math.sign(severity) && Math.abs(severity) <= Math.abs(value)));
                        return(
                        <button
                            key={severity}
                            className={active
                                    ? "rating-box active"
                                    : "rating-box"
                            }
                            style={{
                                backgroundColor:
                                        active
                                        ? colour[severity]
                                        : "#e0e0e0",
                                color: 
                                active && severity!== 0
                                        ?'white'
                                        : 'black'
                            }}
                            onClick={() => onChange(severity)}
                        >{severity>0? "+":""}{severity}</button>
                    )})}
                </div>
                <div className='min-height-reset-container d-flex justify-content-end mt-3'>
                    {value !== undefined &&
                    <button className='btn mt-0 mb-0 p-0' onClick={()=> onChange(undefined)}>
                        <p className="text-muted text-end mt-0 mb-0 ">Reset <Icon icon="bi:arrow-counterclockwise"></Icon></p> 
                    </button>
                    }
                </div>
                
            </div>
            </div>
        </div>
    )
}

export default Rating