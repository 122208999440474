import { useState, useEffect } from "react";
import dayjs from "dayjs";
import DatePicker from "components/date/DatePicker.jsx";
import useLocalStorage from "hooks/useLocalStorage.js";
import Button from "components/buttons/Button.js";
import api from "services/api.js";
import { useNavigate } from "react-router-dom";
import duration from "dayjs/plugin/duration";

const AddSleep = () => {
    dayjs.extend(duration);
    const navigate = useNavigate();
    const [defaultSleepTime, setDefaultSleepTime] = useLocalStorage("default-sleep-time",[22,0]);
    const [defaultWakeTime, setDefaultWakeTime] = useLocalStorage("default-wake-time",[8,0]);

    const [startDateTime, setStartDateTime] = useState(dayjs().add(-1,"day").set('hour', defaultSleepTime[0]).set('minute',  defaultSleepTime[1]).set('second', 0));

    const [isEndTimeSet, setIsEndTimeSet] = useState(false);
    const [endDateTime, setEndDateTime] = useState(dayjs().set('hour', defaultWakeTime[0]).set('minute', defaultWakeTime[1]).set('second', 0));
    const [error, setError] = useState("");

    const [showOffset, setShowOffset] = useLocalStorage("default-sleep-page-show",false);

    const [showSleepSaveButton, setShowSleepSaveButton] = useState(true);
    const [showWakeSaveButton, setShowWakeSaveButton] = useState(true);



    const diffInMinutes = endDateTime.diff(startDateTime, "minutes");
    const formattedDuration = dayjs.duration(diffInMinutes, "minutes").format("HH:mm");

    const setEndTimeFromDuration= (timeString)=> {
        const [hour,minute] = timeString.split(":");
        setEndDateTime( startDateTime.add(hour,"h").add(minute,"m"));
    }
    

    const setTime =(timeString,stateSetter)=>{
        const [hour,minute] = timeString.split(":")
        stateSetter( prev => prev.set("h",hour).set("m",minute))
        
    }
    
    useEffect(()=>{
        if(!isEndTimeSet){
            const nextDay = endDateTime.add(1,"day");
            if(nextDay.isBefore(dayjs(),"day")){
                setEndDateTime(nextDay);
            }
            else{
                setEndDateTime(endDateTime.set("year", dayjs().year()).set("month",dayjs().month()).set("day",dayjs().day()))
            }
            
        }
    },[startDateTime])

    async function handleSleepSubmit(event) {
        event.preventDefault();
        //Ensure there is more than 0 sleep!
        let validationErrors = []
        if(endDateTime.isBefore(startDateTime)){
            validationErrors.push("End date time must be before start");
        }
        const duration = startDateTime.diff(endDateTime,"hour");
        if(duration>24){
            validationErrors.push("Sleep period must be less than 24 hours");
        }
        if(duration==0){
            validationErrors.push("Sleep must be longer than 0!")
        }
        if(validationErrors.length==0){

            await api.post("account/sleep", {
                start_time: startDateTime.toISOString(),
                end_time: endDateTime.toISOString()
            }).then(
                success => {
                    navigate("/account/tracking/sleep");
                },
                error => {
                    setError("A server error has occurred.");
                }
            );
        }
        else{
            setError(validationErrors.join(" "))
        }
        
    }

    return (
        <div className="account-container">
            <div className="w-100">
                <h2 className="flex-grow-1 text-center m-0">Sleep Tracker</h2>
                <form onSubmit={handleSleepSubmit} className="">
                    <h4>When did you fall asleep?</h4>
                    <div className="row mb-3">
                        <div className="col-12 col-sm-6 ">
                            <input
                                type="time"
                                value={startDateTime.format("HH:mm")}
                                onChange={(e)=>setTime(e.target.value, setStartDateTime)}
                                className="form-control"
                                required
                            />
                            <span  style={{cursor: showSleepSaveButton? "pointer" :"not-allowed" }} className="text-muted" onClick={()=>{setDefaultSleepTime([startDateTime.hour(), startDateTime.minute()]); setShowSleepSaveButton(false) }} disabled={!showSleepSaveButton}> 
                                        Sleep often at {startDateTime.format("HH:mm")}? {showSleepSaveButton? <>Save  <i className="bi bi-floppy"/></> : <>saved!</>}
                                    </span>
                        </div>
                        <div className="col-12 col-sm-6">
                            <DatePicker
                            value={startDateTime}
                            onChange={setStartDateTime}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">

                        <div className="col-12 col-sm-6">
                            <h4>{showOffset? "How many hours?":""}</h4>
                        </div>
                        <div className="col-12 col-sm-6 d-flex justify-content-center">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="btn btn-secondary active">
                                    <input type="radio" name="options" id="option1" autoComplete="off" checked={!showOffset}  onClick={()=>setShowOffset(false)}/> Time
                                </label>
                                <label className="btn btn-secondary">
                                    <input type="radio" name="options" id="option2" autoComplete="off" checked={showOffset} onClick={()=>setShowOffset(true)}/> Offset
                                </label>
                            </div>
                        </div>
                    </div>
                    {showOffset&&
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <input
                                type="time"
                                value={formattedDuration}
                                className="form-control mb-2"
                                onChange={(e) =>setEndTimeFromDuration(e.target.value)}
                                /> 
                            </div>
                        </div>
                    }
                    {!showOffset &&
                        <div>
                            <h4>What time did you wake up?</h4>
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-1">
                                    <input
                                        type="time"
                                        value={endDateTime.format("HH:mm")}
                                        onChange={(e)=>setTime(e.target.value, setEndDateTime)}
                                        className="form-control mb-2"
                                        required
                                    />
                                    <span  style={{cursor: showWakeSaveButton? "pointer" :"not-allowed" }} className="text-muted" onClick={()=>{setDefaultWakeTime([endDateTime.hour(), endDateTime.minute()]); setShowWakeSaveButton(false) }} disabled={!showWakeSaveButton}> 
                                        Wake often at {endDateTime.format("HH:mm")}? {showWakeSaveButton? <>Save  <i className="bi bi-floppy"/></> : <>saved!</>}
                                    </span>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <DatePicker
                                    value={endDateTime}
                                    onChange={(e) => { setEndDateTime(e); setIsEndTimeSet(true);}}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    }
                    

                    <Button btnType="success" width="w100" fontSize="fm" type="submit" other="mt-3">Add</Button>
                    {error && <div className="text-danger fw-bold">{error}</div>}

                </form>
            </div>
    </div>
    )
}

export default AddSleep