import React from 'react';
import { Outlet, Link } from "react-router-dom";
import authenticationService from "../../services/authentication.service";
import ErrorBoundary from "../../components/ErrorBoundary";
import "index.css"; 
import "./Layout.css"
import MobileNav from 'components/nav/MobileNav';
import MobileHeader from 'components/nav/MobileHeader';
import Header from 'components/nav/Header';
export default function Layout() {

  async function logoutButton(e) {
    e.preventDefault();
    await authenticationService.logout();
    window.location.replace('/');
  }

  return (
    <>
      <ErrorBoundary fallback={
        <div className="container-fluid justify-content">
          <div className="col-sm-12 col-lg-8 m-auto pt-3">
            <div className="alert alert-danger" role="alert">
              An error occurred while trying to process this page. <Link reloadDocument to={"/"} className="alert-link">Let's take you home</Link>
            </div>
          </div>
        </div>
      }>
        <div className=' with-padding-top'>
          <Outlet />
        </div>
        <div className='d-sm-none'>
        <MobileHeader/>
        </div>
      <div className="d-none d-sm-block">
        <Header/>
      </div>
      </ErrorBoundary>
      <div className='d-sm-none'>
          <MobileNav/>
        </div>
    </>
  );
}
