import React, { useState } from "react";
import { Icon } from '@iconify/react';
import Button from "components/buttons/Button.js";
import "./feedback.css";

export default function FeedbackWidget({ feedbacks = [] }) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="feedback-widget-container" style={{ position: "relative" }}>
            <div
                className="feedback-icon"
                onClick={togglePopup}
                style={{
                    cursor: "pointer",
                    display: "inline-block",
                    color: "#813579",
                    fontSize: "1.5rem",
                    position: "relative"
                }}
            >
                <Icon icon={"system-uicons:speech-typing"}  />
                {feedbacks.length > 0 && (
                    <div className="notification-badge">{feedbacks.length}</div>
                )}
            </div>
            {isOpen && (
                <div
                    className="feedback-popup-overlay"
                    onClick={togglePopup}
                >
                    <div
                        className="feedback-popup"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="feedback-popup-header">
                            <h4 style={{ margin: 0 }}>Monika's Feedback</h4>
                        </div>
                        <div className="feedback-popup-body">
                            {feedbacks.length > 0 ? (
                                feedbacks.map((feedback, index) => (
                                    <div key={index} className="feedback-item">
                                        {feedback}
                                    </div>
                                ))
                            ) : (
                                <div className="feedback-item">No feedback available.</div>
                            )}
                        </div>
                        <div className="feedback-popup-footer" style={{ textAlign: "right" }}>
                            <Button
                                text="Close"
                                btnType="danger"
                                width="w15"
                                fontSize="fm"
                                onClick={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
