import React, { useState } from "react";
import api from 'services/api';


export default function Contact() {
  const [usrmail, setusrmail] = useState("");
  const [usrtitle, setUsrtitle] = useState("");
  const [usrtext, setUsrtext] = useState("");
  const [secret, setsecret] = useState("");
  const [errorMessages, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);


  async function handleSendEmail(e) {
    e.preventDefault();      
    
    // input validation
    const errorMessages = {}
    if (usrtitle === ""){
      errorMessages.title = "Title cannot be blank"
    } else if (usrtitle.toString().length  > 50){
      errorMessages.tooLongTitle = "Title too long (max 50 chars)"
    }

    if (usrtext === ""){
      errorMessages.text = "Text cannot be blank"
    } else if (usrtext.toString().length  > 2000){
      errorMessages.tooLongText = "Post too long (max 2000 chars)"
    }

    if (usrmail === ""){
      errorMessages.email = "Email cannot be blank"
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usrmail))){
      errorMessages.email = "Email not in correct format"
    }
    if (secret === ""){
      errorMessages.secret = "Secret cannot be blank"
    } else if (secret !== "Principles"){
      errorMessages.secret = "Secret Incorrect"
    }

    if (Object.keys(errorMessages).length === 0){
        const newContact = {
            fromAddress: usrmail,
            title: usrtitle,
            text: usrtext,
            secret: secret,
        };
        console.log(newContact);
            await api.post("users/contact", newContact).then(success => {
              // naviagate user to new thread after it has been created
              setDisabled(true);
              setErrors(["Your message has been sent!"]);
            }, error =>
            {
                setErrors(["A server error has occured"]);
            });
  } else {
    setErrors(errorMessages)
  };
}

  return (
    <div>
      <div  className="container-fluid">
          <div  className="row">
              <div  className="col-lg-2"></div>
                  <div  className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
                    <h1>Contact</h1>
                    <p>If you would like to contact Healthify, please use the form below.</p>
                    <div className="p-2">
                      <h5>Your Email</h5>
                      <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="email" aria-label="your email" onChange={(e) => setusrmail(e.target.value)}/>
                    </div>
                    <div className="p-2">
                      <h5>Title</h5>
                      <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="enquiry title" onChange={(e) => setUsrtitle(e.target.value)}/>
                    </div>

                    <div className="p-2">
                      <h5>Content</h5>
                      <textarea className="form-control" style={{ minHeight: "150px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="enqiury text" onChange={(e) => setUsrtext(e.target.value)}/>
                    </div>
                    <div className="p-2">
                      <h5>Secret: _ of Software Engineering</h5>
                      <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="secret" onChange={(e) => setsecret(e.target.value)}/>
                    </div>
                    <div>
                      <button type="button" className="btn FoodPage-hover FoodPage-add" style={{width:"100%"}} onClick={handleSendEmail} disabled={disabled}>Send Message</button>
                      {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                    </div>

                  </div>
             </div>
      </div>
    </div>
  );
}
