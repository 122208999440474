import { useEffect, useState } from "react";
import api from "services/api";
import dayjs from "dayjs";
import LoadingPlaceholder from "components/LoadingPlaceholder";
import "./UserRecord.css"
import UpdateGroup from "components/admin/UpdateGroup";
import MutedIconButton from "components/buttons/MutedIconButton";

const UserRecord = ({ id, groups, onRefreshGroups }) => {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editGroups, setEditGroups] = useState(false);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      await api.get(`/users/${id}`).then(
        (success) => {
          setUser(success.data);
          setUserName(success.data);
        },
        (error) => {
          //Set some errors and the like.
        }
      );
      setLoading(false)
    }
    
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if(loading){
    return <LoadingPlaceholder/>
  }
  if (user == null) {
    return <div>No user selected</div>;
  }
  return (
    <div className="bg-white user-container ">
      <div className="d-flex align-items-center gap-3 mb-3">
        <img
          className="rounded-circle"
          src={"https://placehold.co/60?text=User+Image"}
          alt="user image"
        ></img>
        <h3 className="mb-1">{user.first_name} {user.last_name}</h3>
      </div>
      <table className="table">
        <colgroup>
          <col style={{width: "15%"}}/>
          <col style={{width: "35%"}}/>
          <col style={{width: "15%"}}/>
          <col style={{width: "35%"}}/>
        </colgroup>
          <tr>
            <th scope="row">Height</th>
            <td>{user.height.toFixed(2)}cm</td>
            <th>Weight:</th>
            <td scope="row"> {user.weight.toFixed(2)}kg</td>
          </tr>
          <tr>
            <th scope="row">Gender: </th>
            <td>{user.gender}</td>
            <th scope="row">DOB:</th>
            <td>{dayjs(user.dob).format("DD/MM/YYYY")}</td>
          </tr>
          <tr>
            <th scope="row">Last Activity:</th>
            <td>{dayjs(user.last_activity).format("HH:mm DD/MM/YY")}</td>
            <th scope="row">Email:</th>
            <td>{user.email}</td>
          </tr>
          <tr>
            <th scope="row">Group:</th>
            <td ><div className="d-flex p-0 mr-5">{groups.find(group => group.id === user.group_id)?.title || "Unassigned"}  <MutedIconButton biIcon="bi-pencil" onClick={()=> setEditGroups(true)}>Edit</MutedIconButton></div></td>
          </tr>
          <tr>
            <td colSpan="4">
              {editGroups &&
                    <UpdateGroup groups={groups} currentGroup={user.group_id} userId={id} onClose={(group_id)=> { onRefreshGroups(group_id); setEditGroups(false); setUser(prev => ({...prev, group_id}))}}/>
              }
            </td>
          </tr>
      </table>
    </div>
  );
};

export default UserRecord;


// <div className="row">
{/* <div className="col">
<b>Gender:</b> {user.gender}
</div>
<div className="col">
<b>DOB:</b> {dayjs(user.dob).format("DD/MM/YYYY")}
</div>
</div>
<div className="row">
<div className="col">
  <b>Last Activity:</b> {dayjs(user.last_activity).format("HH:mm DD/MM/YY")}
</div>
<div className="col">
  <b>Email:</b> {user.email}
</div>
</div>
<div className="row">
<div className="col d-flex gap-1">
  <b>Group:</b>  
</div>
<div className="col">
  {/* <b>Email:</b> {user.email} */}
// </div>
