import React, {useTransition, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "index.css";
import "../admin/admin.css"
import AdminUsers from "./AdminUsers"
import AdminTasks from "./AdminTasks"
import AdminQuestions from "./AdminQuestion"
import AdminRewards from "./AdminRewards"
import AdminCommunity from "./AdminCommunity"
import AdminSettings from "./AdminSettings"
import AdminGroups from "./AdminGroups"

import tokenService from 'services/token.service';

// this takes more of a navigational role

export default function Admin() {
    const navigate = useNavigate(); // For navigation
    const [screen, setScreen] = useState(0);
    const [, startTransition] = useTransition();

    async function handleScreen(e) {
        startTransition(() => {
            setScreen(parseInt(e.target.value));
        });
    }

    return (
        <div className="adminpage-container">
            <nav className="adminpage-navbar">
                <div className="adminpage-navbar-content">
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Profile" className="adminpage-profile-picture"/>
                    <h1>Welcome back, {tokenService.getFirstName()}</h1>
                </div>
                <button 
                    className="adminpage-create-task-button"
                    onClick={() => navigate('/admin/create-task')} // Navigate on button click
                >
                    Create Task
                </button>
                
            </nav>
            <nav className="nav nav-pills flex-column flex-lg-row">
                <button className="flex-lg-fill text-lg-center adminNav" value="0" onClick={handleScreen} disabled={screen === 0}>Users</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={handleScreen} disabled={screen === 1}>Tasks</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="2" onClick={handleScreen} disabled={screen === 2}>Questions</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="3" onClick={handleScreen} disabled={screen === 3}>Rewards</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="4" onClick={handleScreen} disabled={screen === 4}>Community</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="5" onClick={handleScreen} disabled={screen === 5}>Groups</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="6" onClick={handleScreen} disabled={screen === 6}>Settings</button>
            </nav>
            <div>
                {screen === 0 && <AdminUsers />}
                {screen === 1 && <AdminTasks />}
                {screen === 2 && <AdminQuestions />}
                {screen === 3 && <AdminRewards />}
                {screen === 4 && <AdminCommunity />}
                {screen === 5 && <AdminGroups />}
                {screen === 6 && <AdminSettings />}
            </div>
        </div>
    );
}
