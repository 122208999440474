
import { Outlet, Link } from "react-router-dom";
import "index.css"; 

export default function BlankFooter() {

    return (
        <div >
            <div className={"mainContent"}>
            <Outlet />
            </div>
        <footer style={{minHeight: "100px"}}>
        </footer>
    </div>

    )
}
