import React, {useState} from "react";
import api from "services/api";

// generic button for refunding a user reward given a transaction ID

function RefundReward({id}) {
    const [screen, setScreen] = useState(0)
    async function handleRefund() {
        if (screen === 0){
            setScreen(1)
        } else if (screen === 1){
            await api.post("account/rewards/refund", {transaction_id: id}).then(success => {
                window.location.reload(false)
            }, error =>
            {
                console.log("error" + error);
            });
        };
    }
    
    return (
            <button onClick={handleRefund}><i className="bi bi-recycle"></i> {(screen === 0 && "refund")}{(screen === 1 && "are you sure. this action cannot be undone")}</button> 
        );
}

export default RefundReward;