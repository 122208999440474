import "index.css";
import { useEffect, useState, useTransition } from 'react';
import api from "services/api";
import "./admin.css";
import dayjs from 'dayjs';

export default function AdminGroups() {
    const [searchTerm, setSearchTerm] = useState('');
    const [groups, setGroups] = useState([])
    const [groupDetails, setGroupDetails] = useState("");
    const [loading, setLoading] = useState(false)
    const [newGroup, setNewGroup] = useState(false)
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [maxUsers, setMaxUsers] = useState();
    const [endSignupDate, setEndSignupDate] = useState();
    const [endGroupDate, setEndGroupDate] = useState();
    const [edit, setEdit] = useState(false);
    const [errors, setErrors] = useState([]);
    const [shareCode, setShareCode] = useState("");
    const [enabled, setEnabled] = useState(true);
    const [deleteScreen, setDeleteScreen] = useState(0);

    
    const [, startTransition] = useTransition();

    const filteredGroups = groups.filter(group => 
        group.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        //Gets a list of users
        const fetchData = async () => {
            await api.get("/groups")
            .then(success => {
                setGroups(success.data);
                // console.log(success.data)
            },
            error => {
                console.log("Unable to load groups");
            });
        }
        fetchData();
      },[]);
    
    // todo: date validation
    async function handleInputValidation(){
        const errorMessages = {}
        if (title === ""){
            errorMessages.title= "Title cannot be blank"
        } else if (title.toString().length  > 50){
            errorMessages.title = "Title too long (max 50 characters)"
        }

        if (description === ""){
            errorMessages.description = "Description cannot be blank"
        } else if (description.toString().length  > 250){
            errorMessages.description = "Description too long (max 250 characters)"
        }

        if (maxUsers <= 0){
            errorMessages.maxUsers = "Max users cannot be less than zero!"
        } 

        setErrors(errorMessages)
    }
    
    // handles group selection. sends an API request to get user information
    async function handleSelectGroup(e, selGroup) {
        setDeleteScreen(0);
        setErrors([])
        setGroupDetails([]);
        setLoading(true);
        setNewGroup(false);
        await api.get(`/groups/${selGroup.id}`).then(success => {
            // console.log(success.data)
            setGroupDetails(success.data);
            setTitle(success.data.title);
            setDescription(success.data.description);
            setMaxUsers(success.data.max_users);
            setEndSignupDate(success.data.signups_close);
            setEndGroupDate(success.data.access_expires);
            setShareCode(success.data.share_code)
            setLoading(false);
        }, error =>
        {
        return // generally we need better error handlign
        });
    }

    // to do : do something here?
    async function handleDeleteGroup(e, selGroup) {
        if (deleteScreen === 0){
            setDeleteScreen(1)
        } else if (deleteScreen === 1){
            setLoading(true)
            setGroupDetails([]);
            await api.delete(`/groups/${selGroup.id}`).then(success => {
                // console.log(success)
                handleClear()
                setLoading(false)
                setGroups(groups.filter(group => group.id !== selGroup.id));
                setDeleteScreen(0);
            }, error =>
                {
                    setErrors(["An error has occured. Please try again."])
                    setLoading(false)
                    setDeleteScreen(0);
                });
        }
    }


    async function handleAdd(e) {
        setErrors([])
        handleInputValidation()
        if (Object.keys(errors).length === 0){
            const addData = {
                title: title,
                description: description,
                max_users: maxUsers ? maxUsers : 9999,
                signups_close: endSignupDate,
                access_expires: endGroupDate,
                enabled: enabled,
            }
            // console.log(addData)
            await api.post("groups", addData).then(success => {
                handleSelectGroup(e, success.data)
                addData.id = success.data.id
                setGroups([...groups, addData])
            }, error =>
            {
                setErrors(["An error has occured. Please try again."])
            });
        }
    }

    async function handleEdit(e, selGroup) {
        setErrors({})
        handleInputValidation()
        if (Object.keys(errors).length === 0){
            const addData = {
                title: title,
                description: description,
                max_users: maxUsers,
                signups_close: endSignupDate,
                access_expires: endGroupDate,
            }
            // console.log(addData)
            await api.patch(`/groups/${selGroup.id}`, addData).then(success => {
                setErrors(["Group Successfully Updated! Reload page to see updates."])
                setEdit(false)
            }, error =>
            {
                setErrors(["An error has occured. Please try again."])
            });
        }
    }

    async function handleRemove(e, selId) {
        setErrors([])
        await api.delete(`/groups/assign/` + selId).then(success => {
            setErrors(["User Removed!"])
        }, error =>
        {
            setErrors(["An error has occured. Please try again."])
        });
        
    }

    async function handleDisabled(e, selId) {
        setErrors([])
        await api.post("groups/" + selId.id + "/disable").then(success => {
            setEdit(false)
            groupDetails.enabled = !groupDetails.enabled
            setErrors(["Updated Successfully"])

        }, error =>
        {
            setErrors([error.data.message])
        });
    }

    async function handleRegenerate(e, selId) {
        setErrors([])
        await api.post("groups/" + selId.id + "/regenerate").then(success => {
            groupDetails.share_code = success.share_code
            setShareCode(success.data.share_code)
            setErrors(["Code Regenerated Successfully"])

        }, error =>
        {
            setErrors([error.data.message])
        });
    }


    async function handleClear() {
        setErrors([])
        setGroupDetails()
        setLoading(false)
        setTitle("")
        setDescription("")
        setMaxUsers()
        setEndSignupDate()
        setEndGroupDate()
        setEdit(false)    
    }

    async function handleNewGroup() {
        setNewGroup(true)
        handleClear()
    }


    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
            <div className="col-lg-3">
                <h1>Groups</h1>
                <button 
                    className="btn adminGroupCreate" 
                    style={{width: "100%"}} 
                    
                    onClick={handleNewGroup}>
                        Create a group
                </button>
                <div className="adminpage-search-box bg-white pb-0 mb-0">
                    <input 
                        type="text" 
                        placeholder="Search groups..." 
                        value={searchTerm} 
                        onChange={e => setSearchTerm(e.target.value)} 
                    />
                </div>
                    <div>
                        {filteredGroups.map((group, index) => (
                            <p>
                                <button
                                value={group.id}
                                className="adminpage-user-box btn w-100 btn-outline text-start btn-success"
                                onClick={(e) => {handleSelectGroup(e, group)}}
                                >
                                {group.title}
                                </button>
                            
                            </p>
                        ))}  
                    </div>
                </div>
                <div className="col-lg-9">
                    <div>
                        {/**Default Group Page for admin */}
                        {!groupDetails && !loading && !newGroup && (
                            <div>
                            <h1>Select a group to get started</h1>
                            <h3>Or create a new group</h3>
                            </div>
                        )}
                    </div>
                    <div>
                        {/** Creating a new group */}
                        {!groupDetails && !loading && newGroup && (
                                <div>
                                <h1>Create a New Group</h1>
                                <p>Users can be assigned in to groups for easier organisation and assignment of tasks. They can join groups via a 'share code' through their account settings, or administrators can assign users into groups manually.</p>
                                
                                <hr />
                                <div>
                                    <div className="p-2">
                                        <h5>Group Name</h5>
                                        <p className='mb-1'>A short name for the group shown to users. (Max 50 characters)</p>
                                        <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                    </div>

                                    <div className="p-2">
                                        <h5>Description</h5>
                                        <p className='mb-1'>A longer description of the group. (Max 250 characters)</p>
                                        <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                                    </div>

                                    <div className="p-2">
                                        <h5>Maximum Users</h5>
                                        <p className='mb-1'>The maximum number of users permitted to be in the group at a time. Leave blank for unlimited.</p>
                                        <div className="input-group">
                                            <input type="link" className="form-control" value={maxUsers} onChange={(e) => setMaxUsers(e.target.value)} aria-label="Reward Image URL"/>
                                        </div>
                                    </div>

                                    <div className="p-2">
                                        <h5>Signup End</h5>
                                        <p className='mb-1'>The date at which users will no longer be able to use the share code to join the group.</p>
                                        <div className="input-group">
                                            <input type="date" className="form-control" value={endSignupDate} onChange={(e) => setEndSignupDate(e.target.value)} aria-label="Reward delivery method"/>
                                        </div>
                                    </div>

                                    <div className="p-2">
                                        <h5>Access End</h5>
                                        <p className='mb-1'>The date at which the 'group' will finish, where users will lose access to the premium features of Healthify.</p>
                                        <div className="input-group">
                                            <input type="date" className="form-control" value={endGroupDate} onChange={(e) => setEndGroupDate(e.target.value)} aria-label="Reward delivery method"/>
                                        </div>
                                    </div>

                                    <div className="p-2">
                                        <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="disabledCheck" checked={enabled} onChange={() => {setEnabled(!enabled)}}/>
                                                <p className='mb-1'>By default, this group will be <b>immediately</b> joinable by anyone with the share code. Uncheck this box to create this group but prevent users from joining. Groups can be hidden and unhidden at any time by selecting the record from the panel on your left.</p>
                                        </div>
                                        <button className="btn adminNav" style={{width: "100%"}} onClick={handleAdd}>Create Group</button>
                                        {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}

                                    </div>
                                    </div>
                                </div>)}
                        {/**Loading Page */}
                        {loading && (<p>Loading, please wait</p>)}
                        {/**View Group */}
                        {groupDetails && !loading && (
                            <div>
                            <h1>{groupDetails.title}</h1>
                            <p>A group with {groupDetails.users.length}/{groupDetails.max_users ? groupDetails.max_users : "unlimited"} members, running until {groupDetails.access_expires}.</p> 
                            <p> {groupDetails.title} {groupDetails.enabled ? " is open to new signups" : " is closed to new signups."}</p>
                            <p>To add a user: Share the code to them or manually change their group in the users panel.</p>
                            <p> You've set the deadline for signups to {groupDetails.signups_close}. This code is random and cannot be edited.</p>
                            <div className="input-group">
                            <input className="form-control" type="text" aria-label="reward-title" value={shareCode} disabled={true}/>
                                    <div className="input-group-append">
                                        <button className="ps-2 pe-2 adminNav" onClick={() => navigator.clipboard.writeText(shareCode)} type="button">copy <i className="bi bi-clipboard"></i></button>
                                        <button className="ps-2 pe-2 adminNav rounded-end" onClick={(e) => handleRegenerate(e, groupDetails)} type="button">regenerate <i className="bi bi-arrow-clockwise"></i></button>
                                    </div>
                                </div>
                            <nav className="flex-column flex-lg-row pt-2">
                                <h3>Group Options</h3>
                                <button 
                                    className="btn adminGroup" 
                                    //btnType="success" 
                                    onClick={() => setEdit(!edit)}>
                                        {edit ? "Cancel Edit" : "Edit"}
                                </button>

                                <button 
                                    className="btn adminGroup" 
                                    //btnType="warning"                                     
                                    onClick={(e) => handleDisabled(e, groupDetails)}>
                                        {groupDetails.enabled ? "Close Signup" : "Open Signup"}
                                </button> 

                                <button 
                                    className="btn adminGroupDelete" 
                                    btnType="danger"                                     
                                    onClick={(e) => handleDeleteGroup(e, groupDetails)} >
                                        {(deleteScreen === 0 && "Remove Group")}
                                        {(deleteScreen === 1 && "Are you sure you want to remove group?")}
                                </button>
                            </nav>
                            {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}
                            {edit && <div>
                            <hr />
                            <div>
                                <div className="p-2">
                                    <h5>Group Name</h5>
                                    <p className='mb-1'>A short name for the group shown to users. (Max 50 characters)</p>
                                    <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Description</h5>
                                    <p className='mb-1'>A longer description of the group. (Max 256 characters)</p>
                                    <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                                </div>

                                <div className="p-2">
                                    <h5>Maximum Users</h5>
                                    <p className='mb-1'>The maximum number of users permitted to be in the group at a time. Leave blank for unlimited.</p>
                                    <div className="input-group">
                                        <input type="link" className="form-control" value={maxUsers} onChange={(e) => setMaxUsers(e.target.value)} aria-label="Reward Image URL"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Signup End</h5>
                                    <p className='mb-1'>The date at which users will no longer be able to use the share code to join the group.</p>
                                    <div className="input-group">
                                        <input type="date" className="form-control" value={endSignupDate} onChange={(e) => setEndSignupDate(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <h5>Access End</h5>
                                    <p className='mb-1'>The date at which the 'group' will finish, where users will lose access to the premium features of Healthify.</p>
                                    <div className="input-group">
                                        <input type="date" className="form-control" value={endGroupDate} onChange={(e) => setEndGroupDate(e.target.value)} aria-label="Reward delivery method"/>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <button className="btn adminNav" style={{width: "100%"}} onClick={(e) => handleEdit(e, groupDetails)}>Save Changes</button>
                                    {Object.entries(errors).map(([key, error]) => (<span style={{fontWeight: "bold"}}>{error}<br /></span>))}
                                </div>
                                </div>
                            </div>}
                                <hr />
                                <h3>Users</h3>
                            <p>Total Users: {groupDetails.users.length}</p>
                                {groupDetails.users.map((singleUser) => (
                                <div >
                                    <div className="justify-content-between d-flex">
                                        <p className="text-start mb-0 d-flex align-items-center gap-2">
                                            <img className="rounded-circle" src={"https://placehold.co/50?text=User+Image"} alt="user image"></img>
                                            <strong>{singleUser.firstName} {singleUser.lastName}</strong> Joined: {dayjs(singleUser.joined).format('DD/MM/YYYY')}</p>
                                        <p className="text-end mb-0"><button onClick={(e) => handleRemove(e, singleUser.id)}><i className="bi bi-trash"></i>remove</button> </p>
                                    </div>
                                    <hr />
                                </div>
                                ))
                                }
                                {(groupDetails.users.length === 0) && (<p>No further details, as there are no users in the group. Use the share code or User panel to add people to this group!</p>)}
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
