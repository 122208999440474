import React from 'react'

const TimePicker = ({value, onChange}) => {
    return (
        <input
            type="time"
            name="food_time"
            className='form-control'
            value={value}
            onChange={onChange}
        /> 
    )
}

export default TimePicker