import "./period.css";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Moment from "react-moment";

function PeriodPage() {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [cycle, setCycle] = useState("28");

  const cycleLength = parseInt(cycle);

  // Handle date selection with a max range of 7 days
  const handleDateChange = (range) => {
    if (Array.isArray(range) && range.length === 2) {
      const [start, end] = range;
      const dayDifference = (end - start) / (1000 * 60 * 60 * 24) + 1;

      if (dayDifference > 7) {
        alert("Please select a range of no more than 7 days.");
      } else {
        setDateRange(range);
      }
    }
  };

  // Extract period start and end
  const periodStart = dateRange[0];
  const periodEnd = dateRange[1] || periodStart;

  // Calculate the length of the current period
  const periodLength = (periodEnd - periodStart) / (1000 * 60 * 60 * 24) + 1;

  // Calculate ovulation day (based on the current period)
  const ovulationDay = new Date(periodStart);
  ovulationDay.setDate(ovulationDay.getDate() + cycleLength - 14);

  // Calculate the next period range
  const nextPeriodStart = new Date(periodStart);
  nextPeriodStart.setDate(nextPeriodStart.getDate() + cycleLength);

  const nextPeriodEnd = new Date(nextPeriodStart);
  nextPeriodEnd.setDate(nextPeriodEnd.getDate() + periodLength - 1);

  // Function to highlight different calendar dates
  const tileClassName = ({ date }) => {
    if (date >= periodStart && date <= periodEnd) return "current-period";
    if (date >= nextPeriodStart && date <= nextPeriodEnd) return "next-period";
    if (date.toDateString() === ovulationDay.toDateString()) return "ovulation-day";
    return null;
  };

  return (
    <>
      <div className="text-center">
        <h4>Calculate Next Period, Ovulation Day</h4>

        <label htmlFor="cycle">Select your Cycle Length: </label>
        <select
          onChange={(e) => setCycle(e.target.value)}
          defaultValue={cycle}
          className="m-2"
        >
          {[...Array(18)].map((_, i) => {
            const value = i + 28;
            return <option key={value} value={value}>{value}</option>;
          })}
        </select>
      </div>

      <p className="text-center">
        Select Your Last Period Start Date Range (max 7 days)
      </p>

      <div className="d-flex justify-content-center">
        <Calendar
          onChange={handleDateChange}
          value={dateRange}
          selectRange={true}
          className="calendar mt-0"
          tileClassName={tileClassName}
        />
      </div>

      <div className="text-center mt-4 p-2">
        <div className="row">
          <div className="d-flex justify-content-center">
            <div className="col-md-3 m-3 box">
              <p>Next Period</p>
              <Moment format="Do MMMM YYYY">{nextPeriodStart}</Moment> -{" "}
              <Moment format="Do MMMM YYYY">{nextPeriodEnd}</Moment>
            </div>
            <div className="col-md-3 m-3 box">
              <p>Ovulation Day (based on current period)</p>
              <Moment format="Do MMMM YYYY">{ovulationDay}</Moment>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeriodPage;
