import {Collapse, FloatingLabel, Form} from "react-bootstrap";
import authenticationService from "../../services/authentication.service";
import api from "services/api";
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import TextInput from "components/text/textInput";
import RealButton from "components/buttons/Button";
import "./Login-Registration.css";


export default function Login() {
  // login button
  async function loginButton(e) {

    e.preventDefault();
    console.log('You clicked submit.');
    
    // verify etc
    const errorMessages = {}
    if (usermail === ""){
      errorMessages.email = "Email cannot be blank"
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usermail))){
      errorMessages.email = "Email not in correct format"
    }

    if (userpass === ""){
      errorMessages.password = "Password cannot be blank"
    } 

    if (Object.keys(errorMessages).length === 0){
      setLoading(true);
      await authenticationService.login(usermail, userpass).then(success => {
        setLoading(false);
        // force navigate on login
        if (authenticationService.isAdmin()){
          window.location.replace('/admin');
        } else {
          window.location.replace('/account');
        }
    }, error => {
      // errors. I'm sure there's a better way to do this
        if (error.status === 403){ // account has not been verified by email
          errorMessages.backend = "Account is not verified";
          setResend(true);
        } else if (error.status === 401){ // incorrect credentials
          errorMessages.backend = "Email or Password is incorrect"
          setWrong(true);
        } else {
          errorMessages.backend = "We are unable to process your login at this time"
        }
        
        setLoading(false);
        setErrors(errorMessages)
    });
    } else {
      setErrors(errorMessages)
    }
    
  }

  // reset password. sends an email to the account associated with the email field
  async function resetPassword(e) {
    e.preventDefault();    
    setSent(true);
    const errorMessages = {}
    if (usermail === ""){
      errorMessages.email = "Email cannot be blank"
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usermail))){
      errorMessages.email = "Email not in correct format"
    }
    if (Object.keys(errorMessages).length === 0){
      errorMessages.backend = "If an account exists with this email address, check your inbox for a link to reset your password.";
      await api.post("auth/password-reset/send", {email: usermail}).then(success => {
        setErrors(errorMessages)
    }, error => {
        setErrors(errorMessages)
    });
    } else {
      setErrors(errorMessages)
    }
    
  }

  // resend verification. sends an email to the account associated with the email field. only visible after user has tried to log in with an unverified account
  async function resendVerification(e) {
    e.preventDefault();   
    setSent(true);
    const errorMessages = {}
    if (usermail === ""){
      errorMessages.email = "Email cannot be blank"
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usermail))){
      errorMessages.email = "Email not in correct format"
    }
    if (Object.keys(errorMessages).length === 0){
      await api.post("auth/resend-verification-email", {email: usermail}).then(success => {
        errorMessages.backend = "A new verification email is on its way. It is valid for ten hours.";
        setErrors(errorMessages)
    }, error => {
        if (error.status === 403){ // none of these should happen lol but just to be safe
          errorMessages.backend = "Account is already verified!";
          setResend(false);
        } else if (error.status === 401){
          errorMessages.backend = "No account found with this email. Please register first."
        } else {
          errorMessages.backend = "Something went wrong, please try again."
        }
        
        setErrors(errorMessages)
        console.log(error)
    });
    } else {
      setErrors(errorMessages)
    }
    
  }

  const [privacyDetails, setPrivacydetails] = useState(false);
  const [errorMessages, setErrors] = useState({});
  const [usermail,setUsermail] = useState("");
  const [userpass, setUserpass] = useState("");
  let [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [sent, setSent] = useState(false);

  return (
    <div className="account-container">
      <div className="w-100">
      <h2>Log in to Healthify</h2>
      <form onSubmit={loginButton}>
        <FloatingLabel controlId="email" label="E-Mail Address" className="mb-3">
          <Form.Control className="TextInput" type="email" name="email" onChange={(e) => setUsermail(e.target.value)} placeholder="name@example.com"/>
        </FloatingLabel>


          <FloatingLabel controlId="password" label="Password" className="mb-3">
            <Form.Control className="TextInput" type="password" name="password" placeholder="Password" onChange={(e) => setUserpass(e.target.value)}/>
          </FloatingLabel>

        <RealButton type="submit" text="Log In" btnType="success" width="w100" fontSize="fm" disabled={loading} other="Login-button-changes shadow-light" />
      </form>

      {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
      <p>By using this service, you agree to our <i><Link to="../terms/" >terms and conditions</Link></i>.</p>
      <p> Not signed up yet? <i><Link to="../register"> Register here</Link></i></p>
      <div>
        {resend && (
          <div>
            <RealButton text="Resend verification email" btnType="success" width="w100" fontSize="fm" other="Login-button-changes shadow-light" onClick={resendVerification}  disabled={sent} />
          </div>
        )}

        {wrong && (
          <RealButton  btnType="success" width="w100" fontSize="fm" className="mt-3  Login-button-changes shadow-light" onClick={resetPassword} disabled={sent}>Forgot Password?</RealButton>
        )}

        <button className={"btn-light rounded-top mt-2 p-2"} onClick={() => setPrivacydetails(!privacyDetails)} aria-controls="privacy" aria-expanded={privacyDetails} style={{width: "100%", border: "none"}}><i className="bi bi-caret-down-square-fill"></i> What data do we store?</button>
        <Collapse in={privacyDetails}>
          <div id="privacy" className="rounded-bottom bg-light p-2 pb-1">
          <p>At Healthify, we only request the minimum amount of data required from our users. The main types of information stored are:</p>
            <ul>
              <li>Registration Information (stored until account deletion)</li>
              <li>Tracking Information (stored until account deletion)</li>
              <li>Rewards Information (stored indefinitely)</li>
              <li>Community forum posts (stored until account deletion, publically accessable)</li>
            </ul>
            <p>Individual items of data can be deleted at any time, and you can remove all of your information by deleting your account. Data is only be accessable by Dr Gostic to allow her to provide her services.</p>
            <p>Our full privacy policy can be found <Link to="../privacy" style={{textDecoration: "none", color:"purple"}}>here</Link>. If you have any concerns please <Link to="../contact" style={{textDecoration: "none", color:"purple"}}>contact us</Link>. <b>Healthify never sells or shares your data with third parties.</b></p>
          </div>
        </Collapse>
      </div>
      </div>
      </div>
  );  
}  