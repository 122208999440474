import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import api from "services/api";
import DeleteForum from "components/forum/DeleteForum";
import StickyForum from "components/forum/StickyForum";
import authenticationService from "services/authentication.service";

export default function CommunityHome() {
  const [forumTopics, setForumTopics] = useState([]);

  useEffect(() => {
    //gets list of topics. 
    const fetchData = async () => {
        await api.get("forum/topics")
        .then(success => {
            setForumTopics(success.data);
            console.log(success)
        },
        error => {
        });
    }
    fetchData();
    
// eslint-disable-next-line    
},[]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <div className="clearfix mb-3">

              <h2 className="float-start">Community</h2>
            </div>

             {/* community topics */}
             <div>
                <div>
                    {forumTopics.map((entry, index) => (
                      <div className="m-2">
                          <div className={`rounded p-4 pb-1 communitySection `}>
                            <Link style={{ textDecoration: "none", color: "black" }} to={"topic/" + entry.id}>
                              <h4>{entry.title}</h4>
                              <p>{entry.description}</p>
                            </Link>
                            {/* last post in topic title and timestamp. A sticky and delete button appears if user is admin, validated on the back and frontend */}
                            <p>{entry.sticky && (<span><i className="bi bi-pin"></i> pinned </span>)}{entry.sticky && entry.lasttitle && ("| ")}{entry.lastPostTitle && (<span>Last Post <i>{entry.lastPostTitle}</i> at {entry.lastPostTimestamp} </span>)}{authenticationService.isAdmin() && (<span><StickyForum type="topic" id={entry.id} current={entry.sticky}/> <DeleteForum type="topic" id={entry.id}/></span>)} </p>
                          </div>
                      </div>
                    ))
                    }
                    {(forumTopics.length === 0) && (<p>No results found...  </p>)}
                </div>
                <br />
            </div>
            <div>
              <Link to="add">
              <button className="btn btn-primary" style={{width:"100%"}}>Add a post</button>
              </Link>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}
