import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SleepGraph from "./SleepGraph.js";
import Button from "components/buttons/Button.js";
import api from "services/api.js";
import dayjs from "dayjs";
import useLocalStorage from "hooks/useLocalStorage.js";

const getPreviousSunday = () => {
    const today = dayjs();
    const daysSinceSunday = today.day(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    return today.subtract(daysSinceSunday , "day").startOf("day"); // Go back past the previous week
};
    
const getNextMonday = () => {
    const today = dayjs();
    const daysUntilMonday = 8 - today.day(); // Days until next Monday
    return today.add(daysUntilMonday, "day").startOf("day"); // Go forward past the current week
};

export default function Sleep() {
    const [sleepData, setSleepData] = useState([]);

    const [fromDate, setFromDate] = useState(dayjs(getPreviousSunday()));
    const [toDate, setToDate] = useState(dayjs(getNextMonday()));


    useEffect(() => {
        const fetchData = async () => {
            await api.get("account/sleep", { params: { from_date: fromDate.format("YYYY-MM-DD"), to_date: toDate.format("YYYY-MM-DD") } })
                .then(
                    (success) => {
                        //Filter???
                        setSleepData(success.data);
                    },
                    () => {
                        console.log("error occurred.");
                    }
                );
        };
        fetchData();
    }, [fromDate,toDate]);

    return (
        <div className="account-container">
            <div className="w-100">
                <SleepGraph
                    sleepData={sleepData}
                    fromDate={fromDate}
                    toDate={toDate}
                    onPreviousWeek={() => {setFromDate(prev => prev.add(-7,"day")); setToDate(prev => prev.add(-7,"day"))}}
                    onNextWeek={() => {setFromDate(prev => prev.add(7,"day")); setToDate(prev => prev.add(7,"day"))}}
                />
                <Link to="./add">
                    <Button btnType="success" width="w100">Record sleep</Button>
                </Link>
            </div>
        </div>
    );
}
