import "index.css";
import React, { useState } from 'react';
import api from "services/api";
import RefundReward from 'components/rewards/RefundReward';
import CompleteReward from 'components/rewards/CompleteReward';

export default function AdminUserReward({userID}) {
    const [userData, setUserData] = useState ([]);
    const [logSearchTerm, setLogSearchTerm] = useState ("");
    const [userHistory, setUserHistory] = useState();
    const [dateFrom, setDateFrom] = useState ();
    const [dateTo, setDateTo] = useState ();
    const [loading, setLoading] = useState(false);
    const [completedSearch, setCompletedSearch] = useState(true);
    const [refundedSearch, setRefundedSearch] = useState(true);
    const [freeSearch, setFreeSearch] = useState(true);
    let updatedFrom, updatedTo;

    // gets current date for searchign
    const getDate = (timeshift) => {
        const now = new Date();
        now.setDate(now.getDate() - timeshift)
        return now.toISOString().slice(0, 10); // formats to "yyyy-MM-ddTHH:mm"
    };

    // handles the date searching
    async function handleUserHistory(value) {

        if (value === 0){
            updatedFrom = getDate(7)
        } else if (value === 1){
            updatedFrom = getDate(28)
        } else if (value === 2){
            updatedFrom = getDate(1000)
        }
        if (value !== 3){
            setDateFrom(updatedFrom);
            updatedTo = getDate(0)
            setDateTo(updatedTo); 

            getRewardsData(); 
        }
        setUserHistory(value);
    }

    // handles the custom date fields
    async function handleCustomDate(e, from) {
        if (from){
            updatedFrom = e.target.value;
            setDateFrom(e.target.value);
            updatedTo = dateTo;
        } else {
            updatedTo = e.target.value;
            setDateTo(e.target.value);
            updatedFrom = dateFrom;
        } 
        if (updatedFrom || updatedTo){
            getRewardsData();
        }
    }

    // handles the API request of the reward data
    async function getRewardsData(){
        setUserData([]);
        setLoading(true);
        await api.get(`/users/${userID}/rewards`, {params:{ fromDate: updatedFrom, toDate:updatedTo}}).then(success => {
            setLoading(false);
            setUserData(success.data);
        }, error =>
        {   
            setUserData(false)
        });
    }

    const filteredRewards = userData.filter(reward => 
        reward.title.toLowerCase().includes(logSearchTerm.toLowerCase())
    );

    return (
        <div>
            <nav className="nav nav-pills flex-column flex-lg-row">
                <button className="flex-lg-fill text-lg-center adminNav" value="0" onClick={(e) => {handleUserHistory(0)}} disabled={userHistory === 0}>Past Week</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={(e) => {handleUserHistory(1)}} disabled={userHistory === 1}>Past 4 Weeks</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="2" onClick={(e) => {handleUserHistory(2)}} disabled={userHistory === 2}>All</button>
                <button className="flex-lg-fill text-lg-center adminNav" value="3" onClick={(e) => {handleUserHistory(3)}} disabled={userHistory === 3}>Custom Range</button>
            </nav>
            {userHistory === 3 && 
                <div>
                    <div className="input-group">
                    <form className="form-floating">
                        <input
                            type="date"
                            id="data_from"
                            name="From"
                            className={"form-control p-3"}
                            value={updatedFrom}
                            onChange={(e) => {handleCustomDate(e, true)}}
                            /> 
                        </form>
                        <input
                            type="date"
                            id="date_to"
                            name="To"
                            className={"form-control p-3"}
                            value={updatedTo}
                            onChange={(e) => {handleCustomDate(e, false)}}
                            /> 
                    </div>
                </div>}
            <div className="container-fluid">
            <div className="row text-center">
                <p>Showing redemptions from <strong>{userHistory === 2 ?  "begining of time": dateFrom}</strong> to <strong>{dateTo}</strong></p>
            </div>
            <div className="row">
                <div className="col-10"> 
                {/* display each reward from user log */}
                    <div className="container-fluid">
                            <div className='row'>
                                <div className="col-12">
                                    {loading && (<p><strong>Loading results, please wait</strong></p>)}
                                    {filteredRewards.map((rewardentry) => (
                                        <div>
                                    {((freeSearch || (rewardentry.cost > 0)) && (completedSearch || (rewardentry.completed)) && (refundedSearch || (!rewardentry.refunded))) && ( 
                                        <div className="adminpage-userlist-infocard rounded">
                                            <p className="adminpage-userlist-infocard-header">{rewardentry.title} (transaction #{rewardentry.transaction_id}, {rewardentry.timestamp})</p>
                                            <p className="adminpage-userlist-infocard-item">{rewardentry.completed ? "transaction completed" : "transaction not completed"} | {rewardentry.refunded ? "transaction refunded" : "transaction not refunded"} | {rewardentry.cost} cost</p>
                                            <p>{(!rewardentry.completed && !rewardentry.refunded)  && <CompleteReward id={rewardentry.transaction_id}/>}{!rewardentry.refunded && <RefundReward id={rewardentry.transaction_id} />}{rewardentry.refunded && !rewardentry.completed && ("refunded rewards are illegible to be marked as complete")}</p>
                                        </div>)}
                                    </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="col-2">
                    <p><strong>Search Filters</strong></p>
                    <div className="accountRedeem-search-box">
                        <input 
                            type="text" 
                            placeholder="Search logs..." 
                            value={logSearchTerm} 
                            onChange={e => setLogSearchTerm(e.target.value)} 
                        />
                    </div>
                    <hr />
                    <p className="pb-0 mb-0">Include these transactions?</p>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="foodCheck" checked={completedSearch} onChange={(e) => {setCompletedSearch(!completedSearch)}}/>
                        <label className="form-check-label" htmlFor="foodCheck">
                            Incomplete
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="waterCheck" checked={refundedSearch} onChange={(e) => {setRefundedSearch(!refundedSearch)}}/>
                        <label className="form-check-label" htmlFor="foodCheck">
                            Refunded
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="sleepCheck" checked={freeSearch} onChange={(e) => {setFreeSearch(!freeSearch)}}/>
                        <label className="form-check-label" htmlFor="foodCheck">
                            Free
                        </label>
                    </div>
                </div>
                </div> 
            </div>
        </div>    
    );
}
