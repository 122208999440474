import { useEffect, useState } from "react";
import api from 'services/api';
import LoadingPlaceholder from "components/LoadingPlaceholder"
import Button from "components/buttons/Button.js";
import Points from "components/Points";
import { Icon } from "@iconify/react";


export default function AccountHistory() {
    const [points, setPoints] = useState(null);
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [errors, setErrors] = useState();


    useEffect(() => {
        //Run async function in use effect. 
        const fetchData = async () => {
            await api.get("users/points",)
            .then(success => {
                setPoints(success.data.points);
            },
            error => {
                console.log("Unable to load points");
            });
            // fetches user history
            await api.get("users/history/1").then(success => {
                setHistory(success.data);
            },
            error => {
                setErrors("Unable to load history");
            });
            setLoading(false);
        }
        fetchData();
    },[]);

    async function handleLoadMore(e) {
        const newPage = currentPage + 1
        await api.get("users/history/" + newPage).then(success => {
        console.log(success.data)
        setHistory(history.concat(success.data))
        setCurrentPage(newPage)
        }, error => {
            if (error.status === 404){
                setErrors("You've reached the end of your history");
            } else  {
                setErrors("A server error has occured.")
            }
        
        });
    }
    if(loading){
        return <LoadingPlaceholder/>
    }
    return (
        <div className="account-container">
            <div  className="row">
                <h1 className="text-start">Point History</h1>
                <div className="d-flex justify-content-end">
                    <Points value={points}/>
                </div>
                <div  className="container-fluid accountpage-div">
                    <div  className="row pt-3">
                        {history.map((singleItem, index) => (
                            <div key={singleItem.id}>
                                {/* rewards */}
                            {singleItem.type === 1 && (
                                <>
                                    <div className="justify-content-between d-flex">
                                        <p className="text-start"><strong><Icon icon="bi:gift" /> {singleItem.title}</strong> {singleItem.completed && (<i className="bi bi-check-circle"></i>)}</p>
                                        {singleItem.refunded 
                                        ? (<p className="text-end text-danger"><b>refunded (0)</b></p>) 
                                        : singleItem.cost>0
                                            ? <p className="text-end text-danger"><b>-{singleItem.cost} points</b></p>
                                            : null
                                        }
                                    </div>
                                    <div className="justify-content-between d-flex">
                                        <p className="text-start">Delivery Method: {singleItem.delivery}</p>
                                        <p className="text-end">Redeemed: {singleItem.timestamp}</p>
                                    </div>
                                </>
                            )}
                                {/* questionnaires */}
                            {singleItem.type === 2 && (
                                <div> 
                                    <div className="justify-content-between d-flex">
                                        <p className="text-start"><strong><Icon icon="wpf:ask-question" /> {singleItem.title}</strong></p>
                                        {singleItem.cost>0&&
                                            <p className="text-end text-success"><b>+{singleItem.cost} points</b></p>
                                        }
                                        
                                    </div>
                                    <div className="justify-content-between d-flex">
                                        <p className="text-end">Completed: {singleItem.timestamp}</p>
                                    </div>
                                </div>
                            )} 
                                {/* tasks */}
                            {singleItem.type === 3 && (
                                <div>
                                    <div className="justify-content-between d-flex">
                                        <p className="text-start"><strong><Icon icon="tdesign:task-filled" /> {singleItem.title}</strong></p>
                                        {singleItem.cost>0&&
                                            <p className="text-end text-success"><b>+{singleItem.cost} points</b></p>
                                        }
                                        
                                    </div>
                                    <div className="justify-content-between d-flex">
                                        <p className="text-end">Completed: {singleItem.timestamp}</p>
                                    </div>
                                </div>
                            )}
                        <hr />
                        </div >))
                        }
                        {(history.length === 0) && (<p>No history found!</p>)}
                        <p>Showing the most recent {history.length > 5  * currentPage ? 5 * currentPage : history.length} results.</p>
                        {errors && (<p><b>{errors}</b></p>)}
                        {(history.length === currentPage * 5) && !errors && (<button onClick={() => handleLoadMore()} className="settings-button" disabled={errors}>Load more history</button>)}
                    </div>
                </div>  
            </div>
        </div>);
}