import axios from "axios";
import authenticationService from "./authentication.service";
import TokenService from "./token.service";
import API_URL from "./api_url";
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } 
    else{
      prom.resolve();
    }
  });
  if(error){
    authenticationService.logout();
  }
  failedQueue = [];
};

//If the response has an error, then the 
//JWT token could be invalid, so try a token refresh.
//If the refresh fails, then throw an error. 
instance.interceptors.response.use(
  async (res) => {
    return res;
  },
   async (err) => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
        .then(() => {
          originalRequest.headers['Authorization'] = 'Bearer ' + TokenService.getLocalAccessToken();
          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        TokenService.updateRefreshToken()
        .then(() => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + TokenService.getLocalAccessToken();
          originalRequest.headers['Authorization'] = 'Bearer ' + TokenService.getLocalAccessToken();
          processQueue(null,);
          resolve(axios(originalRequest));
        })
        .catch(err => {
          processQueue(err);
          console.log("Expired Token.")
          reject(err);
        })
        .then(() => {
          isRefreshing = false;
        });
      });
    }
   return Promise.reject(err);
  } 
);

export default instance;