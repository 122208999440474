import {Button, InputGroup, Form, FloatingLabel, Collapse} from "react-bootstrap";
import authenticationService from "../../services/authentication.service";
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import tokenService from "../services/token.service";
import TextInput from "components/text/textInput";
import RealButton from "components/buttons/Button";
import "./Login-Registration.css";
  

export default function Register() {
  async function incrementScreen(e) {
    e.preventDefault()
    
    // input validation. checks on a screen by screen basis to stop users trawling through several screens to discover they made a mistake on the first row (nhms moment)
    if (screen === 1){
      // height, weight, gender, age
      const errorMessages = {}

      if (height === ""){
        errorMessages.height = "Height cannot be blank"
      }
      if (weight === ""){
        errorMessages.weight = "Weight cannot be blank"
      }

      var newHeight = height;
      var newWeight = weight;

      if (useMetric === false){
        newHeight = (height * 0.3048 * 100)
        newWeight = (weight * 0.4535924)
      } 
      
      if (newHeight > 250 || newHeight < 50){
        errorMessages.height = "Height is not sensible"
      }
      if (newWeight > 350 || newWeight < 20){
        errorMessages.weight = "Weight is not sensible"
      }

      // age check
      const now = new Date();
      const then = new Date(dob);
      // 18 years below
      const difference = (now - then) / 86_400_000

      if (difference < 6574.5){
        errorMessages.age = "You need to be 18 to use this service"
      } else if (then.getFullYear() < 1900) {
        errorMessages.age = "Date of Birth is invalid"
      }

      if (parseInt(gender) === 0){
        errorMessages.gender = "Please select a Gender"
      }
    
      if (Object.keys(errorMessages).length === 0){
          setErrors({})
          setLoading(true);
          await authenticationService.register(fname, lname, usermail, userpass, useMetric ? height : newHeight, useMetric ? weight : newWeight, dob, gender).then(success => {
            setLoading(false);
            setScreen(screen + 1)
          }, error => {
              //setLoginError(true);
              setLoading(false);
              if (error.status === 422){ // case where we want to tell users they can't register
                errorMessages.specific = "An account with this email address already exists"
              } else { // all other cases... server machine broken....
                errorMessages.backend = "We are unable to process your registration at this time due to an error. Please try again later."
              }
              setErrors(errorMessages)
          });
      } else {
          setErrors(errorMessages)
      }
        
    } else if (screen === 0){
      const errorMessages = {}
      // name, email, password
      if (fname === "" || lname === ""){
        errorMessages.name = "Name cannot be blank"
      }
      if (fname.toString().length  > 20){
        errorMessages.tooLongFname = "First name too long (max 20 chars)"
      }
      if (lname.toString().length  > 20){
        errorMessages.tooLongLname = "Last name too long (max 20 chars)"
      }
      if (usermail === ""){
        errorMessages.email = "Email cannot be blank"
      } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usermail))){
        errorMessages.email = "Email not in correct format"
      }
  
      if (userpass === ""){
        errorMessages.password = "Password cannot be blank"
      } else if (userpass.toString().length  < 12){
        errorMessages.password = "Password does not meet length requirements"
      } else if (userpass !== userpasstwo){
        errorMessages.password = "Passwords are not identical"
      }
      if (Object.keys(errorMessages).length === 0){
          setErrors({})
          setScreen(screen + 1)
      } else {
          setErrors(errorMessages)
      }
    }
  }

  async function decrementScreen(e) {
    if (screen >= 1){
        // do submission
        setScreen(screen -1)
    }
  }

  async function metric(e){
    setBoolMetric(prevboolMetric => !prevboolMetric);
    setUseMetric(prevMetric => !prevMetric)
    
  }
  
  const [passwComplex, setpasswComplex] = useState(false);
  const [privacyDetails, setPrivacydetails] = useState(false);
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [dob, setdob] = useState("");
  const [gender, setGender] = useState(0);
  const [errorMessages, setErrors] = useState({});
  const [usermail,setUsermail] = useState("");
  const [userpass, setUserpass] = useState("");
  const [userpasstwo, setUserpasstwo] = useState("");
  const [screen, setScreen] = useState(0);
  let [loading, setLoading] = useState(false);
  const [useMetric, setUseMetric] = useState(true);
  const [boolMetric, setBoolMetric] = useState(true);

  return (
    <div className="account-container">
          <div className="container-fluid">
            <div className="row">
              <h2>Welcome to Healthify</h2>
              <p>An app to improve your well-being by managing symptoms of chronic conditions</p>
              {screen === 0 &&(
                <div className="p-2">
                <h3>Let's get to know you!</h3>
                <div className="m-1">
                  <InputGroup>
                    <FloatingLabel controlId="fname" label="First Name">
                      <Form.Control  className="TextInput" type="text" placeholder="aaa" onChange={(e) => setfname(e.target.value)} value={fname}/>
                    </FloatingLabel>
                    <FloatingLabel controlId="lname" label="Second Name">
                      <Form.Control className="TextInput" type="text" placeholder="aaa" onChange={(e) => setlname(e.target.value)} value={lname}/>
                    </FloatingLabel>
                  </InputGroup>
                    
                </div>
                <div className="m-1">
                    <FloatingLabel controlId="email" label="E-Mail Address">
                    <Form.Control className="TextInput" type="email" name="email" placeholder="aaa" onChange={(e) => setUsermail(e.target.value)} value={usermail}/>
                    </FloatingLabel>
                  </div>

                  <div className="m-1">
                    <FloatingLabel controlId="password" label="Password">
                    <Form.Control className="TextInput" type="password" name="password" placeholder="aaa" value={userpass} onChange={(e) => setUserpass(e.target.value)} onFocus={() => setpasswComplex(!passwComplex)} onBlur={() => setpasswComplex(!passwComplex)}/>
                    </FloatingLabel>
                  </div>    
                  <Collapse in={passwComplex}>
                    <div id="password-complexity" className="rounded bg-light p-1 pb-0">
                      <p>Password must be at least 12 characters long</p>
                    </div>
                  </Collapse>
                  <div className="m-1">
                    <FloatingLabel controlId="password" label="Confirm your Password">
                    <Form.Control className="TextInput" type="password" name="password" placeholder="aaa" value={userpasstwo} onChange={(e) => setUserpasstwo(e.target.value)}/>
                    </FloatingLabel>
                  </div>     
                  
                  <RealButton text="Next" btnType="success" width="w100" fontSize="fm" other="Login-button-changes shadow-light" onClick={incrementScreen}/>
                  
                  {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}

                  <p>By using this service, you agree to our <Link to="../terms/" style={{color: "black"}}>terms and conditions</Link>.</p> 
                  
                  <button className={"btn-light rounded-top mt-2 p-2"} onClick={() => setPrivacydetails(!privacyDetails)} aria-controls="privacy" aria-expanded={privacyDetails} style={{width: "100%", border: "none"}}><i className="bi bi-caret-down-square-fill"></i> What data do we store?</button>
                  <Collapse in={privacyDetails}>
                    <div id="privacy" className="rounded-bottom bg-light p-2 pb-1">
                    <p>At Healthify, we only request the minimum amount of data required from our users. The main types of information stored are:</p>
                      <ul>
                        <li>Registration Information (stored until account deletion)</li>
                        <li>Tracking Information (stored until account deletion)</li>
                        <li>Rewards Information (stored indefinitely)</li>
                        <li>Community forum posts (stored until account deletion, publically accessable)</li>
                      </ul>
                      <p>Individual items of data can be deleted at any time, and you can remove all of your information by deleting your account. Data is only be accessable by Dr Gostic to allow her to provide her services.</p>
                      <p>Our full privacy policy can be found <Link to="../privacy" style={{textDecoration: "none", color:"purple"}}>here</Link>. If you have any concerns please <Link to="../contact" style={{textDecoration: "none", color:"purple"}}>contact us</Link>. <b>Healthify never sells or shares your data with third parties.</b></p>
                    </div>
                  </Collapse>
                </div>
              )}

            
              {screen === 1 &&(
                <div >
                  <h3>Hi, {fname}!</h3>

                  <div className="m-1">
                    {boolMetric &&(
                      <div className="mb-3">
                        <RealButton text="Metric" value="1" btnType="success" width="w50" fontSize="fm" other="remove-round-right switcher-right Login-button-changes shadow-light" />
                        <RealButton text="Imperial" value="0" btnType="warning" width="w50" fontSize="fm" other="remove-round-left switcher-right Login-button-changes shadow-light" onClick={metric}/>
                      </div>
                    )}
                    {!boolMetric &&(
                      <div className="mb-3">
                        <RealButton text="Metric" value="1" btnType="warning" width="w50" fontSize="fm" other="remove-round-right switcher-right Login-button-changes shadow-light" onClick={metric}/>
                        <RealButton text="Imperial" value="0" btnType="success" width="w50" fontSize="fm" other="remove-round-left switcher-right Login-button-changes shadow-light"/>
                      </div>
                    )}

                    <InputGroup>
                      <FloatingLabel controlId="height" label={useMetric ? "Height (cm)" : "Height (ft)"}>
                        <Form.Control className="TextInput" type="number" onChange={(e) => setHeight(e.target.value)} value={height}/>
                      </FloatingLabel>
                      <FloatingLabel controlId="lname" label={useMetric ? "Weight (kg)" : "Weight (lb)"}>
                        <Form.Control className="TextInput" type="number" onChange={(e) => setWeight(e.target.value)} value={weight}/>
                      </FloatingLabel>
                    </InputGroup>
                  </div>

                  <div className="m-1">
                    <FloatingLabel controlId="DoB" label="Date of Birth">
                      <Form.Control className="TextInput" type="date" name="DoB" onChange={(e) => setdob(e.target.value)} value={dob}/>
                      {/* according to the internet, the format changes dependant on the location of the user*/}
                    </FloatingLabel>
                  </div>

                  <div className="m-1">
                    <FloatingLabel controlId="gender" label="Gender">
                      <select className=" TextInput form-control" onChange={(e) => setGender(e.target.value)} value={gender}>
                        <option value="0">Please select an option</option>
                        <option value="1">Male</option> 
                        <option value="2">Female</option> 
                        <option value="3">Other</option> 
                        <option value="4">Prefer not to say</option> 
                      </select>
                    </FloatingLabel>
                  </div>    

                  <RealButton text="Back" btnType="danger" width="w50" fontSize="fm" other="remove-round-right switcher-right Login-button-changes shadow-light" onClick={decrementScreen} />
                  <RealButton text="Register"type="submit" btnType="success" width="w50" fontSize="fm" other="remove-round-left switcher-right Login-button-changes shadow-light" disabled={loading} onClick={incrementScreen} />

                  {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                </div>
              )}

              {screen === 2 &&(
                <div>
                  <h5>Registration successful!</h5>
                  <p>Check your email for a verification link!</p>
                  <p>Once you have verified your account, you will be able to <Link to="../../login">login</Link></p>
                </div>
              )}
            </div>                  
          </div>
        </div>
  );  
}
