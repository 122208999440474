import React from "react";
import "../components.css";

/* text: text for the button to dispaly
    btnType: success (purple), danger(gold), or warning(white)
    width: selects from list in components (currently: 100%, 95%, 80%, 50%, 20%)
    fontSize: selects from list in components (currently: fs, fm, fl fxl fxxl)
    onClick: provides onClick
    id: id
    other: space for any other classes
    disabled: disabled

    updated 15/12/24
 */
function Button({ text, btnType, width, fontSize, onClick, id, other, disabled, children, type}) {
    return (
        <button
            className={`generalButton btn btn-${btnType} ${width} ${fontSize} ${other}`}
            onClick={onClick}
            id={id}
            disabled={disabled}
            type={type}
        >
        {children || text}
        </button>
    );
}

export default Button;
