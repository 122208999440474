import { Link } from "react-router-dom";
import tokenService from "services/token.service.js";
import { useEffect, useState } from "react";
import api from 'services/api.js';
import LoadingPlaceholder from "components/LoadingPlaceholder"
import ButtonTask from "components/buttons/ButtonTask.js"
import Points from "components/Points";
import { Icon } from "@iconify/react";
import {redeem} from "components/nav/links"
import Button from "components/buttons/Button";
import Streak from "components/Streak";


export default function AccountHome() {
  const [points, setPoints] = useState(null);
  const [groupDetails, setGroupDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [streak, setStreak] = useState(0);
  const [taskData, setTaskData] = useState([]);
  const [questionData, setQuestionData] = useState([]);


  useEffect(() => {
    //Run async function in use effect. 
    const fetchData = async () => {

    await Promise.allSettled([
        api.get("users/points"),
        api.get("groups/assign"),
        api.get("account/tasks"),
        api.get("users/streaks"),
        api.get("account/questions")
    ])
    .then(results => {
        results.forEach((result, index) => {
            if (result.status === "fulfilled") {
                if (index === 0) setPoints(result.value.data.points);
                if (index === 1) setGroupDetails(result.value.data);
                if (index === 2) setTaskData(result.value.data.tasks);
                if (index === 3) setStreak(result.value.data);
                if (index === 4) setQuestionData(result.value.data.questions);
            } else {
                console.error(`Request ${index + 1} failed:`, result.reason);
            }
        });
    });
    setLoading(false);
  }
    
    fetchData();
  },[]);
  if(loading){
    return <LoadingPlaceholder></LoadingPlaceholder>
  }
  return (
    <div className="container-fluid account-container">
      <div className="row">
        <div className="col-lg-6 pb-0 pt-2">
            <h2>Welcome Back, <b>{tokenService.getFirstName()}!</b></h2>
        
          
          
          {/* when a user is in a group... */}
          {groupDetails.title && (<div>
            <h5 className="mb-0"><Icon icon="bi:people-fill"/> Your Group: {groupDetails.title}</h5>
            <i>{groupDetails.description}</i>
            <p className="mb-0">Runs until: {groupDetails.access_expires}. Got another share code? Update your group in your account settings.</p>
            </div>)}
          {/* when a user is NOT in a group... */}
          {!groupDetails.title && !loading && (<div>
            <h5><Icon icon="bi:people-fill"/> You're not in a group! Visit settings to link your account to a group if you have a share code.</h5>
            <Link to="settings"><button className="settings-button">Account Settings</button></Link>
            </div>)}
          <p></p>
        </div>
        <div className="col-lg-6 pt-0 pb-3">
          <Points value={points}/>
          <Streak value={streak}/>
          <Link to="redeem"><Button btnType="success" width="w100" fontSize="fl">Redeem points <Icon icon={redeem.icon}/></Button></Link>
        </div>
        </div>

        <div className="row" style={{width:"100%"}} >
        {questionData.map((individualData, index) => (
              <div key={individualData.id} className="col-lg-6 pt-1 pt-lg-3 pb-3">
                        <Link style={{textDecoration: `none`}} to="question" state={individualData}>
                          <ButtonTask 
                            title={individualData.title.length > 34 ? individualData.title.slice(0,34) + "..." : individualData.title} 
                            description={individualData.description.length > 40 ? individualData.description.slice(0,40) + "..." : individualData.description}
                            numberOfPoints={individualData.points}
                            taskType="question" 
                            titleSize="fl" 
                            width="w100"
                            descriptionSize="fm" 
                            id="openModal"/>
                        </Link>
              </div>))}
        {taskData.map((individualData, index) => (
          <div key={individualData.id} className="col-lg-6 pt-1 pt-lg-3 pb-2">
            <Link style={{textDecoration: `none`}} to="task" state={individualData}>
              <ButtonTask 
                title={individualData.title.length > 34 ? individualData.title.slice(0,34) + "..." : individualData.title} 
                description={individualData.description.length > 40 ? individualData.description.slice(0,40) + "..." : individualData.description}
                numberOfPoints={individualData.points}
                taskType="task" 
                titleSize="fl" 
                width="w100"
                descriptionSize="fm" 
                id="openModal"/>
            </Link>
          </div>))
          }
          {taskData.length === 0 &&                       
          <div className="col-lg-6 p-5 pt-1 pt-lg-3 pb-3">
            <h5>You've got no tasks to complete at the moment!</h5>
          </div>
            }

                      {questionData.length == 0 &&                       
                      <div className="col-lg-6 p-5 pt-1 pt-lg-3 pb-3">
                        <h5>You've got no questionnaires to complete at the moment!</h5>
                        </div>}
        </div>
    </div>
  );
}