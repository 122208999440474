import React from "react";
import "../components.css";

// eslint-disable-next-line
{/* checked: what the checkbox is currently set to
onChange: function that changes the checked state
label: text to display next to the checkbox
 */}
function checkbox({checked, onChange, label}) {
    return (
        <div className="form-check">
        <input className="form-check-input" type="checkbox" id={label} checked={checked} onChange={onChange}/>
        <label className="form-check-label" htmlFor={label}>
            {label}
        </label>
    </div>
    );
}
export default checkbox;
