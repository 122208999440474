import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <div>
      <div className="container-fluid">
          <div className="row">
              <div className="col-lg-2"></div>
                  <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
                    <h1>Healthify Privacy Policy</h1>
                    <p>Your privacy is important to us. Healthify will never receive or share data with a third party company without your permission.</p>
                    <p>This privacy notice tells you what to expect us to do with your personal information.</p>
                    <ol>
                      <li><a href="#contact">Contact Details</a></li>
                      <li><a href="#collect">What information we collect, use and why</a></li>
                      <li><a href="#law">Lawful bases and data protection rights</a></li>
                      <li><a href="#where">Where we get personal information from</a></li>
                      <li><a href="#long">How long we keep information</a></li>
                      <li><a href="#confidentiality">Duty of Confidentiality</a></li>
                      <li><a href="#complaints">How to complain</a></li>
                    </ol>
                    <p>This policy was last updated on the 10th February 2025</p>
                    <hr />
                    <h5 id="contact">Contact Details</h5>
                    <p>Email: <a href="mailto:admin@myhealthify.co.uk">admin@myhealthify.co.uk</a> (or use the <Link to="../contact">Webform</Link>)</p>
                    <hr />
                    <h5 id="collect">What information we collect, use and why</h5>
                    <p>We collect or use the following information to provide patient care, services, pharmaceutical products and other goods:</p>
                    <ul>
                      <li>Name, address and contact details</li>
                      <li>Gender</li>
                      <li>Date of birth</li>
                      <li>Health information (including medical conditions, allergies, medical requirements and medical history)</li>
                      <li>Information about care needs (including disabilities, home conditions, medication and dietary requirements and general care provisions)</li>
                      <li>Health information</li>
                    </ul>
                    <p>We collect or use the following personal information for patient app or portal functionality:</p>
                    <ul>
                      <li>Names and contact details</li>
                      <li>Account information, including registration details</li>
                      <li>Information used for security purposes</li>
                    </ul>
                    <p>We collect or use the following personal information for medical research if you have opted in:</p>
                    <ul>
                      <li>Records of consent, where appropriate</li>
                      <li>Anonymised health information</li>
                    </ul>
                    <p>We collect or use the following personal information for dealing with queries, complaints or claims:</p>
                    <ul>
                      <li>Names and contact details</li>
                      <li>Purchase or service history</li>
                    </ul>
                    <p>For the purposes of development and site security, your IP address and pages you request are stored for 30 days to debug the application and block malicious traffic.</p>
                    <hr />
                    <h5 id="law">Lawful bases and data protection rights</h5>
                    <p>Under UK data protection law, we must have a “lawful basis” for collecting and using your personal information. There is a list of possible lawful bases in the UK GDPR. You can find out more about lawful bases on the <a href="https://ico.org.uk/for-the-public/">ICO’s website</a>. Which lawful basis we rely on may affect your data protection rights which are in brief set out below. You can find out more about your data protection rights and the exemptions which may apply on the ICO’s website:</p>
                    <ul>
                      <li>Your right of access - You have the right to ask us for copies of your personal information. You can request other information such as details about where we get personal information from and who we share personal information with. There are some exemptions which means you may not receive all the information you ask for.</li>
                      <li>Your right to rectification - You have the right to ask us to correct or delete personal information you think is inaccurate or incomplete. You can read more about this right here.</li>
                      <li>Your right to erasure - You have the right to ask us to delete your personal information.</li>
                      <li>Your right to restriction of processing - You have the right to ask us to limit how we can use your personal information.</li>
                      <li>Your right to object to processing - You have the right to object to the processing of your personal data.</li>
                      <li>Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you.</li>
                      <li>Your right to withdraw consent – When we use consent as our lawful basis you have the right to withdraw your consent at any time.</li>
                    </ul>
                    <p>If you make a request, we must respond to you without undue delay and in any event within one month. To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.</p>
                    <p>Our lawful bases for collecting or using personal information to provide patient care, services, pharmaceutical products and other goods, for patient app or portal functionality, for medical research or archiving purposes and dealing with queries, complaints or claims are:</p>
                    <ul>
                      <li>Consent - we have permission from you after we gave you all the relevant information. All of your data protection rights may apply, except the right to object. To be clear, you do have the right to withdraw your consent at any time, which can be done by deleting your account or contacting us.</li>
                    </ul>
                    <hr />
                    <h5 id="where">Where we get personal information from</h5>
                    <ul>
                      <li>Directly from you</li>
                    </ul>
                    <p>Healthify does not obtain information from third party sources or share your information with third party sources without explicit permission from you.</p>
                    <hr />
                    <h5 id="long">How long we keep information</h5>
                    <p>We keep your tracking information and account details until you delete your account. This can be done at any time from your account settings or by contacting us.</p>
                    <p>We keep the transaction information for your reward redemptions indefinitely to ensure we have historical records of transactions. Upon account deletion, this data is anonymised</p>
                    <p>If you email us, we keep your contact information and query indefinitely unless you ask us to delete it.</p>
                    <hr />
                    <h5 id="confidentiality">Duty of Confidentiality</h5>
                    <p>We are subject to a common law duty of confidentiality. However, there are circumstances where we will share relevant health and care information. These are where:</p>
                    <ul>
                      <li>you’ve provided us with your consent (we have taken it as implied to provide you with care, or you have given it explicitly for other uses);</li>
                      <li>we have a legal requirement (including court orders) to collect, share or use the data;</li>
                      <li>on a case-by-case basis, the public interest to collect, share and use the data overrides the public interest served by protecting the duty of confidentiality (for example sharing information with the police to support the detection or prevention of serious crime);</li>
                      <li>If in England or Wales – the requirements of The Health Service (Control of Patient Information) Regulations 2002 are satisfied; or</li>
                      <li>If in Scotland – we have the authority to share provided by the Chief Medical Officer for Scotland, the Chief Executive of NHS Scotland, the Public Benefit and Privacy Panel for Health and Social Care or other similar governance and scrutiny process.</li>
                    </ul>
                    <hr />
                    <h5 id="complaints">How to complain</h5>
                    <p>If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.</p>
                    <p>If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.</p>
                    <p className="pb-0 mb-0">The ICO’s address:</p>
                    <div className="ps-3 mt-1">
                      <p className="m-0">Information Commissioner’s Office</p>
                      <p className="m-0">Wycliffe House</p>
                      <p className="m-0">Water Lane</p>
                      <p className="m-0">Wilmslow</p>
                      <p className="m-0">Cheshire</p>
                      <p className="m-0">SK9 5AF</p>
                      <p className="mt-1 m-0">Helpline number: <a href="tel:0303121113">0303 123 1113</a></p>
                      <p className="m-0">Website: <a href="https://www.ico.org.uk/make-a-complaint">https://www.ico.org.uk/make-a-complaint</a></p>
                    </div>
                    <hr />
                    <p>View our <Link to="../terms" onClick={() => {document.documentElement.scrollTo({top: 0,left: 0,behavior: 'instant'});}}>terms and conditions</Link>.</p>
                  </div>
             </div>
      </div>
    </div>
  );
}
