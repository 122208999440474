export const API_URL =  getApiUrl()


function getApiUrl(){
    if(process.env.REACT_APP_API_SOURCE_URL){
        return process.env.REACT_APP_API_SOURCE_URL
    }
    if(process.env.REACT_APP_API_SOURCE === "local"){
        return "http://localhost:5000/api/v2/"
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
        return "https://backend-dev.coolify.edcalvert.net/api/v2/"
    }
    else{
        return "https://backend-dev.coolify.edcalvert.net/api/v2/"
    }

}
export default API_URL;