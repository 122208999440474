import React, { useState } from "react";
import FeedbackWidget from "./Feedback";
import MonikaFeedbackInput from "./giveFeedback";

export default function DemoFeedback() {
    const [feedbacks, setFeedbacks] = useState([
        "Good stuff!",
        "Loving this.",
        "Amazing job bro!",
        "What a champ"
    ]);

    const handleFeedbackSubmit = (text) => {
        if (text && text.trim()) {
            setFeedbacks([...feedbacks, text.trim()]);
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <h2>Feedback Widget Demo</h2>
            <FeedbackWidget feedbacks={feedbacks} />
            <h2 style={{ marginTop: "40px" }}>Monika Leave Feedback Demo</h2>
            <MonikaFeedbackInput onSubmitFeedback={handleFeedbackSubmit} />
        </div>
    );
}
