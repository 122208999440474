import { useLocation, Navigate, Outlet } from "react-router-dom";
import authenticationService from "services/authentication.service"

const RequireLoggedOut = () => {
    const location = useLocation();

    return (
        !authenticationService.currentUserValue 
            ? <Outlet />
            : <Navigate to="/account" state={{ from: location }} replace />
    );
}

export default RequireLoggedOut;