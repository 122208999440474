import React from "react";
import api from "services/api";

// generic button for sticky-ing community content

function StickyForum({type, id, current}) {
    async function handleSticky() {
        if (type === "thread"){
            var url = "forum/threads/" + id
        } else {
            // eslint-disable-next-line
            var url = "forum/topics/" + id
        }
        await api.patch(url, {
            "sticky" : !current
        }).then(success => {
            window.location.reload(false)
        }, error =>
        {
            console.log("error" + error);
        });
    };
    
    return (
            <button onClick={handleSticky}><i className="bi bi-pin"></i> {current ? "unpin" : "pin"}</button> 
        );
}

export default StickyForum;