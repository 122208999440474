import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import api from "services/api";
import tokenService from "services/token.service";
import DeleteForum from "components/forum/DeleteForum";
import BanForum from "components/forum/BanForum";
import authenticationService from "services/authentication.service";

export default function CommunityTopic() {
  const [forumTopics, setForumTopics] = useState([]);
  const [title, setTitle] = useState("");
  const [usrtext, setUsrtext] = useState("");
  const [errorMessages, setErrors] = useState([]);
  const location = useLocation();
  let navigate = useNavigate();

  // url for getting thread posts
  var urlOne = "forum/threads/" + location.pathname.split("/").pop() +"/posts"
  // url for getting thread metadata
  var urlTwo = "forum/threads/" + location.pathname.split("/").pop()
  // url for adding new posts

  useEffect(() => {
    //Run async function in use effect. 
    const fetchData = async () => {
        // get post information
        await api.get(urlOne)
        .then(success => {
            setForumTopics(success.data);
            console.log(success)
        },
        error => {

        });
        //get thread information
        await api.get(urlTwo)
        .then(success => {
            setTitle(success.data);
            //console.log(success.data)
        },
        error => {
          if (error.status === 404){
            navigate(-1);
          }
        });
    }
    fetchData();
    
// eslint-disable-next-line    
},[]);

  // adds post
  async function handleAddPost(e) {
      e.preventDefault();      
      const errorMessages = {}
      // input validation
      if (usrtext === ""){
        errorMessages.text = "Text cannot be blank"
      } else if (usrtext.toString().length  > 2000){
        errorMessages.tooLongText = "Post too long (max 2000 chars)"
      }

      if (Object.keys(errorMessages).length === 0){
        const newForumEntry = {
            text: usrtext,
        };
            // api request
            await api.post(urlOne, newForumEntry).then(success => {
              // reload on success (it updates the post lol)
                window.location.reload(false)
            }, error =>
            { 
              // if error, update user
              setErrors(error.message.data);
            });
          } else {
            setErrors(errorMessages)
          };
};

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <div className="clearfix mb-3">
              <h2>{title.title}</h2>
              <p>Posted on {title.timestamp}</p>
              <p><Link to="../" style={{textDecoration:"none"}}>Community</Link> / <Link to={"../topic/" + title.topicId} style={{textDecoration:"none"}}>{title.topicName}</Link> / {title.title}</p>
            </div>

             {/* user post data */}
             <div>
                <div>
                    {forumTopics.map((entry, index) => (
                      <div className="m-2">
                          <div className={`rounded p-4 pb-1 communitySectionNoHover`}>
                            {/* displays if user is administrator or not */}
                            <span><strong>{entry.author}</strong>{entry.isAuthorAdmin && (<span> <i className="bi bi-shield"></i> admin</span>)}</span>
                            <p>{entry.text}</p>
                            <p>{entry.timestamp}</p>
                            {/* This needs to refresh on click of deletion but its not working and I am tired */}
                            {/* but, this is a deletion button that is visible to administrators and authors of posts. A ban button is visible to administrators. These are validated on backend to ensure user is certainly an admin */}
                            <p>{(authenticationService.isAdmin() || (tokenService.getUserId() === entry.authorId)) && (<DeleteForum type="post" id={entry.id}/>)}{authenticationService.isAdmin() && (tokenService.getUserId() !== entry.authorId) && (<BanForum id={entry.id}/>)}</p> 
                          </div>
                      </div>
                    ))
                    }
                </div>
                <br />
            </div>
            {authenticationService.currentUserValue && (
              <div className={`rounded p-4 "communitySectionNoHover"}`} style={{width: "100%"}}>
              <p ><strong>Add your reply</strong></p>
              <div className="input-group">
                <textarea className="form-control" style={{ minHeight: "100px", color: "black" }} type="text" aria-label="form-input" onChange={(e) => setUsrtext(e.target.value)}/>
                <button type="button" className="btn btn-success" onClick={handleAddPost}><i className="bi bi-send" label="send"></i></button>
              </div>
              {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
            </div>
            )}
            {!authenticationService.currentUserValue && (
              <div className="rounded p-4" style={{width: "100%", background: "white"}}>
              <p style={{ color: "000000" }}><strong><Link to="../../login/" style={{textDecoration:"none"}}>Log in</Link> to contribute!</strong></p>
              </div>
            )}
            
          </div>
        </div>
      </div>
    </div>
  );
}
