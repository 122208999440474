import React, { useState } from "react";
import { Icon } from '@iconify/react';
import Button from "components/buttons/Button.js";
import "./feedback.css";

export default function MonikaFeedbackInput({ feedback_id }) {
    const [isOpen, setIsOpen] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = () => {
        // post to feedback endpoint...
        setFeedbackText("");
        setIsOpen(false);
    };
    console.log(feedback_id);

    return (
        <div className="monika-feedback-input-container" style={{ position: "relative" }}>
            <div
                onClick={togglePopup}
                style={{
                    cursor: "pointer",
                    display: "inline-block",
                }}
            >
                <Icon icon="cil:speech"  />
            </div>
            {isOpen && (
                <div
                    className="monika-feedback-popup-overlay"
                    onClick={togglePopup}
                >
                    <div
                        className="monika-feedback-popup"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="monika-feedback-popup-header">
                            <h4 style={{ margin: 0 }}>Leave Feedback</h4>
                        </div>
                        <div className="monika-feedback-popup-body">
                            <textarea
                                className="form-control"
                                style={{ width: "100%", minHeight: "150px" }}
                                value={feedbackText}
                                onChange={(e) => setFeedbackText(e.target.value)}
                            />
                        </div>
                        <div className="monika-feedback-popup-footer" style={{ textAlign: "right" }}>
                            <Button
                                text="Submit"
                                btnType="success"
                                width="w15"
                                fontSize="fm"
                                other="rounded-start rounded-end-0"
                                onClick={handleSubmit}
                            />
                            <Button
                                text="Close"
                                btnType="danger"
                                width="w15"
                                fontSize="fm"
                                other="rounded-end rounded-start-0"
                                onClick={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
