import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import api from 'services/api';
import Points from "components/Points";
import Button from "components/buttons/Button";

// Function to calculate whether a user has enough points to redeem a reward
function Redeemable({currentPoints, value}){
    if (currentPoints >= value){
        return <p  className="float-end small">You can redeem this</p>
    } else {
        return <p  className="float-end small">Not enough points</p>
    }
}

export default function AccountRedeem() {
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [points, setPoints] = useState(null);
    const [rewards, setRewards] = useState([])

    useEffect(() => {
        const fetchData = async () => {
             // Get list of rewards from API
            await api.get("account/rewards/available",)
            .then(success => {
                setRewards(success.data);
            });
            // Get points from API
            await api.get("users/points",)
            .then(success => {
                setPoints(success.data.points);
            })
        }
        fetchData();
    },[]);

    // Search rewards
    const filteredRewards = rewards.filter(reward => 
        reward.title.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (

        <div  className="account-container">
            <div  className="container-fluid ">
            <h1 className="text-start">Redeem</h1>
            <div className="d-flex justify-content-end">
                <Points value={points}/>
            </div>
            <div  className="row">
                <div  className="col-md-6">
                    <h3>{rewards.length} rewards</h3>
                </div>
                <div  className="col-md-6 pb-3 justify-content-md-end">
                    <div className="accountRedeem-search-box">
                        <input 
                            type="text" 
                            placeholder="Search rewards..." 
                            value={searchTerm} 
                            onChange={e => setSearchTerm(e.target.value)} 
                        />
                    </div>
                </div>
                <Link to="../history"><Button btnType="success">Points History</Button></Link>
            </div>
            <div  className="row">

                {filteredRewards.map(award => (
                    <div className="col-md-6 p-md-3 pb-3" key={award.id}>
                        <Link to={"/account/redeem/" + award.id} style={{textDecoration: 'none'}} state={{ from: location }}>
                            <div  className="accountRedeem-button d-flex rounded m-0" style={{backgroundImage: `url(${award.image_url ? award.image_url : process.env.PUBLIC_URL + '/no_image.png'})`, backgroundSize: 'cover'}}>
                                <div  className="accountRedeem-caption align-self-end p-3 pb-0">
                                    <p  className="accountpage-buttonText align-bottom">{award.title}</p>
                                    <hr/>
                                    <div  className="row">
                                        <div  className="col-sm-6"><p  className="float-start small">{award.cost} points</p></div>
                                        <div  className="col-sm-6"><Redeemable currentPoints={points} value={award.cost}/></div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))} 
            </div>
            </div>     
        </div>
    );
}