import React from "react";
import "./DailyFoodGrid.css"; 
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import LoadingPlaceholder from "components/LoadingPlaceholder";

const DailyFoodGrid = ({food}) => {
  if( !food){
    return <LoadingPlaceholder/>
  }else if(food.length ==0){
    return <div className="dfg-min-height-container">No food on this day</div>
  }

  return (
    <div className="dfg-min-height-container">
      
      {food && food.sort((a,b) => dayjs(a.time_period).valueOf() - dayjs(b.time_period).valueOf()).map((event) => (
        <div
          key={event.id}
          className="food-event"
        >
          <div className="d-flex justify-content-between align-items-center">
            <strong>{event.food_name}</strong>
            <div className="d-flex gap-2">
              <Icon icon={false? "bi:chat-right": "bi:chat-right-dots-fill"} className="dfg-icon"/>
              <Icon icon="bi:pencil" className="dfg-icon" />
              <Icon icon="bi:trash" className="dfg-icon"/>
            </div>
          </div>
          <p className="time-range">{dayjs(event.time_period).format("HH:mm")} </p>
          
          <span className="description">{event.description} </span>
        </div>
      ))}

    </div>
  );
};

export default DailyFoodGrid;
