import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import api from 'services/api';
import {Button, InputGroup} from "react-bootstrap";
import LoadingPlaceholder from "components/LoadingPlaceholder";


export default function AccountSurvey() {
  const location = useLocation();
  const questionData = location.state;

  const [updatedQuestionData, setUpdatedQuestionData] = useState([])
  const [points, setPoints] = useState(0)
  const navigate = useNavigate(); // For navigation
  const [errors, setErrors] = useState("");
  const [questions, setQuestions] = useState([]);

  const handleOptionChange = (event) => {
      setSelectedOption(parseInt(event.target.value));
      setCurrentQuestions(questions.slice((event.target.value * 4 - 4), (event.target.value * 4)));
  };
  
  const [selectedOption, setSelectedOption] = useState(1);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [pageTotal, setPageTotal] = useState(1);
  const [userResponse, setResponse] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    //Run async function in use effect. 
    const fetchData = async () => {
        await api.get("account/questions/" + questionData.id)
        .then(success => {
            console.log(success.data)
            setUpdatedQuestionData(success.data);   
            setQuestions(success.data.questions);   
            setCurrentQuestions(success.data.questions.slice(0, 4));
            setResponse({...Array.from({ length: success.data.questions.length}, () => null)}) ;
            setPageTotal(Math.ceil(success.data.questions.length / 4))
        },
        error => {
          console.log("error")
        });

        await api.get("users/points",)
        .then(success => {
            setPoints(success.data.points);
        });
        setLoading(false);
    }
    fetchData();
     
  // eslint-disable-next-line    
  },[]);


  async function nextButton(e) {
    if (selectedOption === pageTotal){
        setErrors({})
        const errorMessages = {}
        for (let i = 0; i < questions.length; ++i) {
            //console.log(userResponse[i])
            if ((userResponse[i] === null || userResponse[i] === "-1") && questions[i].required === "T" && questions[i].type !== "msq"){
                errorMessages[i] = "Question  " + (i+1) + " required!" }
            if (userResponse[i] !== null){
              if (questions[i].type === "textarea"){
                if (userResponse[i].toString().length > 256){
                    errorMessages[i] = "Question  " + (i+1) + ": text response too long"
                } 
              } else if (questions[i].type === "number" ){
                if (Number(userResponse[i]) < 0 ){
                      errorMessages[i] = "Question  " + (i+1) + ": number can't be less than zero"
                } else if (Number(userResponse[i]) === ""){
                  errorMessages[i] = "Question  " + (i+1) + ": needs to be a number"
                }
                    
              } else if (questions[i].type === "mcq"){
                userResponse[i] = Number(userResponse[i]);
                if (userResponse[i] === -1 ){
                      errorMessages[i] = "Question  " + (i+1) + ": required!"}
              } else if (questions[i].type === "range"){
                if (userResponse[i] < 0 || userResponse[i] > 100  ){
                      errorMessages[i] = "Question  " + (i+1) + ": out of range"
              } else if (questions[i].type === "binary"){
                if (userResponse[i].toString().length > 256){
                  errorMessages[i] = "Question  " + (i+1) + ": text response too long"
              } 
              }
            }
            
            }
          } 
        if (Object.keys(errorMessages).length === 0){
          console.log(userResponse)
            await api.post("/account/questions/" +questionData.id, {responses: userResponse}).then(success => {
              navigate('../success', {state: {title: updatedQuestionData.title, points: updatedQuestionData.points, totalPoints: points + updatedQuestionData.points}})
              }, error => {
                setErrors(error.status)
            });
          
        } else {
            setErrors(errorMessages)
        }
        
    } else{
        setSelectedOption(selectedOption + 1)
        setCurrentQuestions(questions.slice((selectedOption * 4), (selectedOption * 4) + 4));
    }
    
    
  }

  async function prevButton(e) {
    if (selectedOption > 1){
        setSelectedOption(selectedOption - 1)
        setCurrentQuestions(questions.slice((selectedOption * 4) - 8, (selectedOption * 4) - 4));
    }    
  }

  const changeForm = event => {
    console.log(userResponse)
    const { id, value, checked } = event.target;
    if (id.includes(".")){
      setResponse(prevState => ({
        ...prevState,
        [id]: checked,
      }));
    } else { 
      setResponse(prevState => ({
        ...prevState,
        [id]: value,
      }));
    }

  };

  if (!questionData) {
    return null;
  }
    
   return (
    <div>
       {loading && (<LoadingPlaceholder></LoadingPlaceholder>)}
        {!loading && ( 
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-2"></div>
                <div className="col-sm-12 col-lg-8">
                  <div> 
                      <div className="container-fluid accountpage-div">
                        {pageTotal !== 1 && (
                              <div className="row pt-4">
                                  <div className="text-center">
                                      {[...Array(pageTotal)].map((e, i) =>
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption === i + 1} name="inlineRadioOptions" id="screen1" value={i + 1}/>
                                      </div>)}
                                      <p>Page {selectedOption} of {pageTotal}</p>
                                  </div>
                              </div>)}
                          <div className="row">
                              <div className="col-lg-6 col-sm-12 p-5 pb-3 pt-xl-5 pt-5  ">
                              <h2>{updatedQuestionData.title}</h2>
                              <p>{updatedQuestionData.description}</p>
                              <p>{updatedQuestionData.points} points</p>
                              <img src={updatedQuestionData.image_url} className="w100 rounded" alt="" role="presentation"></img>
                              <hr />


                              </ div>
                              <div className="col-lg-6 col-sm-12 p-1 p-lg-5 pt-0 pb-3 ">
                                <div className="p-2">
                                {currentQuestions.map((entry, index) => (
                                        <div>
                                            <h5>{entry.title}</h5>
                                            <p>{entry.description ? entry.description + " | ": null}  {entry.required === "T" ? "required": "not required"} {entry.type == "msq" && " | select all that apply"}</p>
                                            {entry.type === "textarea" && (
                                              <div>
                                                <input type="textarea" className="form-control" onChange={changeForm} id={index + (selectedOption * 4) - 4} value={userResponse[index + (selectedOption * 4) - 4] ? userResponse[index + (selectedOption * 4) - 4] : ""}></input>
                                              </div>
                                            )}
                                            {entry.type === "number" && (
                                              <div>
                                                <input type="number" className="form-control" onChange={changeForm} id={index + (selectedOption * 4) - 4} value={userResponse[index + (selectedOption * 4) - 4] ? userResponse[index + (selectedOption * 4) - 4] : Number(0)}></input>
                                              </div>
                                            )}
                                            {entry.type === "range" && (
                                                <div>
                                                <input type="range" className="form-control" onChange={changeForm} id={index + (selectedOption * 4) - 4} value={userResponse[index + (selectedOption * 4) - 4] ? userResponse[index + (selectedOption * 4) - 4] : Number(50)}></input>
                                                  <div>
                                                      <p className="form-label float-start">1</p>
                                                      <p className="form-label float-end">10</p>
                                                      <br />
                                                  </div>
                                              </div>
                                            )}
                                            {entry.type === "binary" && (
                                                  <div>
                                                      <InputGroup style={{width: "100%"}}>
                                                          <Button style={{width: "50%"}}  value="No" id={index + (selectedOption * 4) - 4} onClick={changeForm}>No</Button> 
                                                          <Button style={{width: "50%"}}  value="" id={index + (selectedOption * 4) - 4} onClick={changeForm}>Yes</Button> 
                                                      </InputGroup>
                                                      {(userResponse[index + (selectedOption * 4) - 4]) !== "No" && (userResponse[index + (selectedOption * 4) - 4]) !== null && (
                                                          <div>
                                                              <p className="pt-1 pb-0 mb-1 mt-1">Please Specify</p>
                                                              <input type="text" className="form-control" onChange={changeForm} id={index + (selectedOption * 4) - 4} value={userResponse[index + (selectedOption * 4) - 4] ? userResponse[index + (selectedOption * 4) - 4] : ""}></input>
                                                          </div>
                                                      )}
                                                  </div>

                                            )}
                                            {entry.type === "mcq" && (
                                                <div>
                                                <select className="form-control" onChange={changeForm} id={index + (selectedOption * 4) - 4} value={userResponse[index + (selectedOption * 4) - 4] ? userResponse[index + (selectedOption * 4) - 4] : "-1"}>
                                                  <option value="-1">Select an option</option> 
                                                {
                                                  entry.options.split("|").map( (option,opindex) => 
                                                    <option value={opindex}>{option}</option> ) 
                                                }</select>
                                              </div>
                                            )}
                                            {entry.type === "msq" && (
                                                <div>
                                                {
                                                  entry.options.split("|").map((option,opindex) => {
                                                    return <div key={option} ><input onChange={changeForm} className="form-check-input" type="checkbox" id={`${index + (selectedOption * 4) - 4}.${opindex}`} checked={userResponse[index + (selectedOption * 4) - 4]?.[opindex]}/> {option}</div> }) 
                                                }
                                              </div>
                                            )}
                                            
                                            <hr />
                                        </div>
                                        
                                    ))
                                    }
                                {selectedOption === 1 && pageTotal !== selectedOption && (
                                    <Button style={{width: "100%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Next</Button>
                                )}
                                {pageTotal === 1 && pageTotal == selectedOption && (
                                  <Button style={{width: "100%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Finish</Button> 
                                )}
                                {pageTotal === selectedOption && pageTotal !== 1 && (
                                    <InputGroup style={{width: "100%"}}>
                                        <Button style={{width: "50%"}} variant="secondary" type="submit" value="REGISTER" onClick={prevButton}>Previous</Button> 
                                        <Button style={{width: "50%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Finish</Button> 
                                    </InputGroup>
                                )}
                                {pageTotal !== selectedOption && selectedOption !== 1 && (
                                    <InputGroup style={{width: "100%"}}>
                                        <Button style={{width: "50%"}} variant="secondary" type="submit" value="REGISTER" onClick={prevButton}>Previous</Button> 
                                        <Button style={{width: "50%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Next</Button> 
                                    </InputGroup>
                                )}

                                  {Object.entries(errors).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                              </div>
                      </div>
                    </div>
                  </div>
                <div className="col-lg-2"></div>
              </div>
            <div>
          </div>
        </div>
      </div>
    </div>)}
    </div>

  );  
}  
 
