import {useState} from 'react'
import Button from 'components/buttons/Button'
import api from "services/api"

const UpdateGroup = ({groups, currentGroup, userId, onClose}) => {
    const [userGroup, setUserGroup] = useState(groups?.find(group => group.id === currentGroup)?.id|| "0");
    const [errors, setErrors] = useState("");
    
    async function handleUpdateGroup() {
        await api.post(`/groups/assign/${userId}`, {group_id: userGroup}).then(success => {       
            console.log(success.data);
            onClose(parseInt(userGroup));
        }, error =>
        {
            console.log(error);
            setErrors(error.response.data.message);
        });
        }

    return (
        <div className=' my-3 py-3 border rounded-3'>
            <div className='d-flex flex-row gap-2 align-items-center'>
                <b className='text-nowrap mb-0 '>Assign to group: </b>
                <select className="form-control" onChange={(e) => setUserGroup(e.target.value)} value={userGroup}>
                <option value="0">Unassigned</option> 
                {
                    groups.map( (group,) => 
                    <option key={group.id} value={group.id}>{group.title}</option> ) 
                }
                </select>  
            </div>
            <div className='d-flex gap-3 mt-3'>
                <Button btnType="secondary" onClick={()=>onClose()} width="w-100">Cancel</Button> <Button btnType="success" width="w-100" onClick={() => handleUpdateGroup()}>Save</Button>
            </div>
            {errors && (<span style={{fontWeight: "bold"}}>{errors}<br /></span>)}
        </div>
    )
}

export default UpdateGroup