import React, { useEffect, useState } from "react";
import GroupedUsersList from "components/users/GroupedUsersList";
import UserRecord from "components/users/UserRecord";
import api from "services/api";
import LoadingPlaceholder from "components/LoadingPlaceholder";
import { useLocation, useNavigate } from "react-router-dom";

const AdminUsersPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');

  const [groupedUsers, setGroupedUsers] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState(userId);

  async function getUsers(){
    await api.get("/users").then(
      (success) => {
        const result = success.data.groups;
        const sortedResult = result.sort((a, b) => a.group_id > b.group_id);
        console.log(sortedResult);
        setGroupedUsers([...sortedResult]);
        console.log(success.data);
      },
      (error) => {
        console.log("Unable to load users");
      }
    );
  }

  async function getGroups() {
    await api.get("/groups").then(
      (success) => {
        setGroups(success.data);
      },
      (error) => {
        console.log("Unable to load groups");
      });
  }

  useEffect(() => {
    //Gets a list of users
    const fetchData = async () => {
      await getUsers();
      await getGroups();
    };
    fetchData();
  }, []);

  function setUser(id){
    setSelectedUser(id);
    navigate(`?userId=${id}`)
  }


  if (!groupedUsers) {
    return <LoadingPlaceholder/>;
  }
  return (
    <div className="container-lg">
      <h2 className="text-center pt-3">Users</h2>
      <div className="row">
        <div className="col-4">
          <GroupedUsersList
            selectedUser={selectedUser}
            onSelectUser={(e) => setUser(e)}
            groupedUsers={groupedUsers}
          />
        </div>
        <div className="col-8">
          <UserRecord id={selectedUser} groups={groups}  onRefreshGroups={(group_id)=> getUsers() }/>
        </div>
      </div>
    </div>
  );
};

export default AdminUsersPage;
