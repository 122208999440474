import React, {useState, useEffect} from 'react';
import "index.css";
import api from "services/api";
import tokenService from 'services/token.service';

// view, create, update, disable and delete rewards

export default function AdminRewards() {
    const [rewards, setRewards] = useState ([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [rewardData, setRewardData] = useState();
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [author, setAuthor] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [image, setImage] = useState("");
    const [delmeth, setdelmeth] = useState("");
    const [quant, setquant] = useState(0);  
    const [maxper, setmaxper] = useState(0);  
    const [cost, setCost] = useState(0); 
    const [edit, setEdit] = useState(false); 
    const [errors, setErrors] = useState([]);
    const [title, setTitle] = useState([]);
    const [enabled, setEnabled] = useState(true);

        // general input validation as it is used twice
    function handleInputValidation(){
        const errorMessages = []
        if (title === ""){
            errorMessages.push("Title cannot be blank")
        } else if (title.toString().length  > 50){
            errorMessages.push("Title too long (max 50 characters)")
        }

        if (description === ""){
            errorMessages.push("Description cannot be blank")
        } else if (description.toString().length  > 500){
            errorMessages.push("Description too long (max 500 characters)")
        }

        if (delmeth === ""){
            errorMessages.push("Delivery method cannot be blank")
        } else if (delmeth.toString().length  > 40){
            errorMessages.push("Delivery method too long (max 40 characters)")
        }

        if (image.toString().length  > 200){
            errorMessages.push("Image URL too long (max 200 characters)")
        }

        if (quant <= 0){
            errorMessages.push("Quantity cannot be zero or negative")
        }

        if (maxper <= 0){
            errorMessages.push("Maximum redemptions cannot be zero or negative")
        }

        if (cost < 0){
            errorMessages.push("Cost cannot be less than zero")
        } else if (cost > 1000){
            errorMessages.push("Cost cannot be greater than 1000")
        }
        return errorMessages
    }

    useEffect(() => {
        // Gets a list of rewards. 
        const fetchData = async () => {
            await api.get("account/rewards",)
            .then(success => {
                setRewards(success.data);
            },
            error => {
                console.log("Unable to load rewards");
            });
        }
        fetchData();
    },[]);

    // gets the details for a specific reward
    async function handleSelectReward(selRew) {
    setLoading(true);
    handleClear();
    await api.get("account/rewards/" + selRew.id + "/admin").then(success => {
        handleSetData(success.data)
        setLoading(false);
    }, error =>
    {
        console.log("error" + error);
    });
    }

        // yeah there must be a better way of doing this
    async function handleSetData(rewData){
        setRewardData(rewData)
        setDescription(rewData.description);
        setAuthorName(rewData.author)
        setAuthor(rewData.author_id);
        setImage(rewData.image);
        setdelmeth(rewData.delivery);
        setquant(rewData.quantity);
        setmaxper(rewData.max);
        setCost(rewData.cost);
        setTitle(rewData.title);

    }

        // handles the updating of reward information. validates input, posts the thing and disables editing
    async function handleUpdate() {
        setErrors([])
        let inputErrors = handleInputValidation()
        if (inputErrors.length === 0){
            const updateData = {
                description: description,
                author_id: author,
                image_url: image,
                delivery_method: delmeth,
                quantity: Number(quant),
                max_redemptions: Number(maxper),
                cost: Number(cost),
            }
            console.log(updateData)
            await api.put("account/rewards/" + rewardData.id , updateData).then(success => {
                setErrors([])
                setEdit(false)
                
            }, error =>
            {
                setErrors([error.response.data.message]);
            });
        } 
        else{
            // setErrors(inputErrors);
        }
    }

        // handles creation of rewards. validates input, posts to backend and navigates to the new reward page
    async function handleAdd() {
        let inputErrors = handleInputValidation()
        if (inputErrors.length === 0){
            const addData = {
                title: title,
                description: description,
                image_url: image,
                delivery_method: delmeth,
                quantity: parseInt(quant),
                max_redemptions: parseInt(maxper),
                cost: parseInt(cost),
                enabled: enabled
            }
            await api.post("account/rewards", addData).then(success => {
                handleSelectReward(success.data);
                setErrors([]);
            }, error =>
            {
                setErrors(["An error has occured. Please try again."])
            });
        }
        else{
            setErrors(inputErrors);
        }
    }

        // handles the disabling of rewards
    async function handleDisabled() {
        setErrors([])
        await api.patch("account/rewards/" + rewardData.id, {enabled : !rewardData.enabled} ).then(success => {
            setEdit(false);
            rewardData.enabled = !rewardData.enabled
            setErrors(["Updated Successfully"])
        }, error =>
        {
            setErrors([error.response.data.message])
        });
    }
         // handles the deletion of rewards
    async function handleDeletion() {
        setErrors([])
        if (rewardData.redeemed === 0){
            await api.delete("account/rewards/" + rewardData.id ).then(success => {
                window.location.reload(false)
            }, error =>
            {
                setErrors([error.data.message])
            });
        } 
    }
        // handles the selection of a new user reward. this surely can be done better
    async function handleClear() {
        setErrors([]);
        setRewardData();
        setDescription("");
        setAuthorName("")
        setAuthor("");
        setImage("");
        setdelmeth("");
        setquant(0);
        setmaxper(0);
        setCost(0);
        setTitle("");
        setEdit(false);

    }

    const filteredRewards = rewards.filter(reward => 
        reward.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
                <div className="col-lg-10">
                    <h1>Rewards</h1>
                    <hr />
                    {!rewardData && !loading && (
                        <div>
                        <p>Rewards are redeemable against points gained through interactions with the app. Rewards can be edited and disabled at any time by selecting the reward from the bar on the right. Only rewards that have never been redeemed can be completley deleted, whereas other rewards will be disabled, preventing new users from redeeming them. You are also unable to change the point value of rewards which have been redeemed. Users can redeem a single reward more than once unless a maximum redemptions field is specified.</p>
                        <p>When a user redeems a reward, you receive an email with their contact information to organise the sending of the reward. At the same time, they receive a conformation email which contains your contact information.</p>
                        <h3>Create a Reward</h3>
                        <hr />
                        <div>
                            <div className="p-2">
                                <h5>Title</h5>
                                <p className='mb-1'>A short title shown to users in the redemption store and in conformation emails. Maximum 50 characters.</p>
                                <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                            </div>

                            <div className="p-2">
                                <h5>Description</h5>
                                <p className='mb-1'>A longer description shown to users on a reward page. Maximum 500 characters.</p>
                                <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                            </div>

                            <div className="p-2">
                                <h5>Image URL</h5>
                                <p className='mb-1'>An image shown to users on the redemption store page. Not required, if left blank, a placeholder image will be shown.</p>
                                <div className="input-group">
                                    <input type="link" className="form-control" value={image} onChange={(e) => setImage(e.target.value)} aria-label="Reward Image URL"/>
                                    <div className="input-group-append">
                                        <button className="ps-2 pe-2 adminNav rounded-end" onClick={() => window.open(image)} type="button">view <i className="bi bi-box-arrow-up-right"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2">
                                <h5>Delivery Method</h5>
                                <p className='mb-1'>How the users will receive the reward.</p>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={delmeth} onChange={(e) => setdelmeth(e.target.value)} aria-label="Reward delivery method"/>
                                </div>
                            </div>

                            <div className="row p-2">
                                <div className="col-lg-4 col-12">
                                    <h5 htmlFor="quantity">Quantity</h5>
                                    <p className='mb-1'>The total number of this reward you are willing to give out.</p>
                                    <input type="number" className="form-control" value={quant} onChange={(e) => setquant(e.target.value)} aria-label="Reward Quantity" id="quantity" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <h5 htmlFor="maxred">Max Redemptions Per User</h5>
                                    <p className='mb-1'>The limit of how many times a single user can redeem the reward.</p>
                                    <input type="number" className="form-control" value={maxper} onChange={(e) => setmaxper(e.target.value)} aria-label="Reward Max Redemptions per User" id="maxred" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <h5 htmlFor="reward_cost">Cost</h5>
                                    <p className='mb-1'>The cost of the reward in points. Minimum of 0 (free), maximum of 1000.</p>
                                    <input type="number" className="form-control" value={cost} onChange={(e) => setCost(e.target.value)} aria-label="Reward Cost" id="reward_cost" />
                                </div>
                            </div>
                            <div className="p-2">
                                <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="waterCheck" checked={enabled} onChange={() => {setEnabled(!enabled)}}/>
                                        <p className='mb-1'>By default, this reward will be <b>immediatley</b> available to all users. Uncheck this box to create this reward but hide it from users. Rewards can be hidden and unhidden at any time by selecting the record from the panel on your right.</p>
                                </div>
                                <p className='mb-1'>The email address linked to this account will receive an email every time a user redeems a reward to alert you to deliver their reward. This address is also provided to the users who choose to redeem this reward, in order to contact you. If you would like to give the users a different email address, please change the email linked to this account in your account settings.</p>
                                <button className="btn adminNav" style={{width: "100%"}} onClick={handleAdd}>Add Reward</button>
                                {errors.map((key, error) => <span style={{fontWeight: "bold"}}>{key}<br /></span>)}
                            </div>
                            </div>
                        </div>)}
                    {!rewardData && loading && (<p>Loading, please wait</p>)}
                    {rewardData && (<div>
                            {/* <input className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-title" value={title} onChange={(e) => setTitle(e.target.value)} disabled={true}/> */}
                            <h3>{rewardData.title}</h3>
                            <nav className="nav nav-pills flex-column flex-lg-row">
                                <button className="flex-lg-fill text-lg-center adminNav" value="0" onClick={() => setEdit(!edit)}>{edit ? "Editing.." : "Edit"}</button>
                                <button className="flex-lg-fill text-lg-center adminNav" value="1"  onClick={handleDisabled}>{rewardData.enabled ? "Disable" : "Enable"}</button>
                                <button className="flex-lg-fill text-lg-center adminNav" value="2" disabled={rewardData.redeemed !== 0} onClick={handleDeletion}>Delete</button>
                            </nav>
                            <span className="text-danger">{rewardData.redeemed !== 0 && ("Deletion of this reward is not possible as it has been redeemed. To stop offering this reward, disable it. ")}{!rewardData.enabled && ("This reward is currently disabled and therefore it is not visible to users.")}</span>
                            <div className="p-2">
                                <h5>Description</h5>
                                <textarea className="form-control" style={{ minHeight: "50px"}} type="text" aria-label="reward-description" value={description} onChange={(e) => setDescription(e.target.value)} disabled={!edit}/>
                            </div>

                            <div className="p-2">
                                <h5>Author</h5>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={authorName} aria-label="Reward author" disabled/>
                                    <div className="input-group-append">
                                        <button className="ps-2 pe-2 adminNav rounded-end" type="button" onClick={() => {(setAuthor(tokenService.getUserId())); setAuthorName(tokenService.getFirstName() + " " + tokenService.getLastName())}} disabled={!edit || (author === tokenService.getUserId())}>{(author === tokenService.getUserId()) && ("that's you!")} {(author !== tokenService.getUserId()) && ("take control?")}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2">
                                <h5>Image URL</h5>
                                <div className="input-group">
                                    <input type="link" className="form-control" value={image} onChange={(e) => setImage(e.target.value)} aria-label="Reward Image URL" disabled={!edit}/>
                                    <div className="input-group-append">
                                        <button className="ps-2 pe-2 adminNav rounded-end" onClick={() => window.open(image)} type="button">view <i className="bi bi-box-arrow-up-right"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2">
                                <h5>Delivery Method</h5>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={delmeth} onChange={(e) => setdelmeth(e.target.value)} aria-label="Reward delivery method" disabled={!edit}/>
                                </div>
                            </div>

                            <div className="row p-2">
                                <div className="col-lg-4 col-12">
                                    <h5 htmlFor="quantity">Quantity (available {quant - rewardData.redeemed})</h5>
                                    <input type="number" className="form-control" value={quant} onChange={(e) => setquant(e.target.value)} aria-label="Reward Quantity" id="quantity" disabled={!edit}/>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <h5 htmlFor="maxred">Max Redemptions Per User</h5>
                                    <input type="number" className="form-control" value={maxper} onChange={(e) => setmaxper(e.target.value)} aria-label="Reward Max Redemptions per User" id="maxred" disabled={!edit}/>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <h5 htmlFor="reward_cost">Cost</h5>
                                    <input type="number" className="form-control" value={cost} onChange={(e) => setCost(e.target.value)} aria-label="Reward Cost" id="reward_cost" disabled={!edit || rewardData.redeemed !== 0}/>
                                    {rewardData.redeemed !== 0 && edit && (<p className="text-danger">You can't edit this as the reward has been redeemed.</p>)}
                                </div>
                            </div>
                            {edit && (<button className="btn adminNav" style={{width: "100%"}} onClick={handleUpdate}>Save Changes</button>)}
                            {errors.map((key, error) => (<span style={{fontWeight: "bold"}}>{key}<br /></span>))}
                            <hr />
                            <h3>Redemption History</h3>
                            <p>Total Redemptions: {rewardData.redeemed}</p>
                            {rewardData.redemption_history.map((singleItem) => (
                            <div >
                                <div className="justify-content-between d-flex">
                                    <p className="text-start mb-0"><strong>{singleItem.name}</strong> {singleItem.email}</p>
                                    <p className="text-end mb-0">Redeemed: {singleItem.timestamp}</p>
                                </div>
                                <p>{singleItem.refunded && ("transaction refunded & ")} {singleItem.completed ? "transaction complete" : "transaction incomplete"}</p>
                                <hr />
                            </div>
                            ))
                            }
                            {(rewardData.redemption_history.length === 0) && (<p>No history found.</p>)}
                    </div>)}
                </div>
                <div className="col-lg-2">
                <button className="btn adminNav" style={{width: "100%"}} onClick={handleClear}>Add a Reward</button>

                <div className="adminpage-search-box">
                        <input 
                            type="text" 
                            placeholder="Search rewards..." 
                            value={searchTerm} 
                            onChange={e => setSearchTerm(e.target.value)} 
                        />
                    </div>
                    <div className="adminpage-sidebar" >
                        <div className="adminpage-user-list-box">
                            <div className="adminpage-user-list">
                                {filteredRewards.map((single_reward) => (
                                    <div 
                                        value={single_reward.id}
                                        className="adminpage-user-box"
                                        onClick={(e) => {handleSelectReward(single_reward)}}
                                    >
                                        {single_reward.title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}
