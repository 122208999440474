import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "index.css";
import Button from "components/buttons/Button.js"
import dayjs from "dayjs";

export default function FoodPageConfirm() {
    const location = useLocation(); //
    const params = new URLSearchParams(location.search);
    const dateString = params.get('date');
    const date = dateString ? dayjs(dateString) : dayjs();
    const navigate = useNavigate();
    const foodType = location.state.food;

    console.log(location);

    if (!foodType) {
        navigate('../');
        return null;
    }
    
    return (

        <div className="account-container">
            <div className="container container-fluid position-relative text-center">

                <h2 className="mb-3">{foodType === 1? "Food" : "Water"} Successfully Logged!</h2>
                <div className="mb-3">
                    <Link to={foodType === 1 ? `../food/addFood?date=${date.toISOString()}` : "../food/addWater"}className="p-2">
                        <Button text={`Add more ${foodType === 1? "Food" : "Water"}`} btnType="success" width="w100" fontSize="fm"/>
                    </Link>
                </div>
                <Link to={`../food?date=${date.toISOString()}`} className="p-2">
                    <Button text="Back to log" btnType="success" width="w100" fontSize="fm"/>
                </Link>
            </div>
        </div>
    );
}