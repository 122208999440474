import React from "react";
import "../components.css";

// eslint-disable-next-line
{/* rows: the number of rows for the input
    value: value
    altText: alt text
    onChange: onChange
    type: type
    onFocus: onFocus
    onBlur: onBlur
    placeholder: placeholder text in the box
    width: width of text box from list (currently: 100%, 95%, 80%, 50%, 20%, 10%)
    disabled: disabled, true of false
    other: other

    When rows is 1, it uses an input, otherwise it uses a textarea
    
    updated 24/1/25
 */}
function TextInput({rows, value, altText, onChange, type="text", onFocus, onBlur, placeholder, width, disabled, className}) {
    if (rows === "1") {
        return (
            <input
                type={type}
                className={`TextInput ${width} h-${rows}  ${className}`}
                rows={rows}
                value={value}
                alt={altText}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                disabled={disabled}
            ></input>
        );
    }

    return (
        <textarea
            type="text"
            className={`TextInput ${width} h-${rows} ${className}`}
            rows={rows}
            value={value}
            alt={altText}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
        ></textarea>
    );
}
export default TextInput;
