import { Outlet, Link } from "react-router-dom";
import "index.css"; 

export default function Layout() {

    return (
        <div >
            <div className={"mainContent"}>
            <Outlet />
            </div>
        <footer className={`container-fluid footerStyle `}>
            <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-sm-12 col-lg-4 p-2 pt-2 pt-lg-4">
                <p className="text-white text-start m-0">This app is not a replacement for medical advice. Consult with your GP if you have any concerns about the suitability of this programme for you.</p>
            </div>
            <div className="col-sm-12 col-lg-4 p-2 pt-0 pt-lg-2">
                <Link to="contact" style={{ textDecoration: `none` }} onClick={() => {document.documentElement.scrollTo({top: 0,left: 0,behavior: 'instant'});}}>
                <p className="text-white text-lg-end text-start m-0">Contact</p>
                </Link>
                <Link to="privacy" style={{ textDecoration: `none` }} onClick={() => {document.documentElement.scrollTo({top: 0,left: 0,behavior: 'instant'});}}>
                <p className="text-white text-lg-end text-start m-0">Privacy</p>
                </Link>
                <Link to="terms" style={{ textDecoration: `none` }} onClick={() => {document.documentElement.scrollTo({top: 0,left: 0,behavior: 'instant'});}}>
                <p className="text-white text-lg-end text-start m-0">Terms</p>
                </Link>
                <Link to="https://monikagostic.com/" style={{ textDecoration: `none` }}>
                <p className="text-white text-lg-end text-start m-0">Dr Monika Gostic Ltd <i className="bi bi-box-arrow-up-right"></i></p>
                </Link>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-sm-12 col-lg-8 p-2 pt-0 m-0">
                <hr />
                <div className="text-center" >
                <small className="text-white m-0"><b>Team Delta <i className="bi bi-triangle-half"></i></b> for Dr Monika Gostic. A CS3028 and CS3528 project.<br /></small>
                <small className="text-white m-0">Copyright 2024 - 2025.</small>
                </div>
            </div>
            <div className="col-lg-2"></div>
            </div>
            </footer>
        </div>

    )
}
