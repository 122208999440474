import React from 'react'
import authenticationService from 'services/authentication.service'
import { Link } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'

const HealthifyLogo = () => {
    if (authenticationService.currentUserValue){
        if (authenticationService.isAdmin()){
        return (<Link to="/admin" style={{textDecoration: "none"}}>
            <Navbar.Brand className="headerName">HealthifyAdmin</Navbar.Brand>
        </Link>)
        } else {
        return (<Link to="/account/" style={{textDecoration: "none"}}>
            <Navbar.Brand className="headerName">Healthify</Navbar.Brand>
        </Link>)
        }
    } else {
        return(<Link to="/" style={{textDecoration: "none"}}>
        <Navbar.Brand className="headerName">Healthify</Navbar.Brand>
        </Link>)
    }
}

export default HealthifyLogo