import React, {useTransition, useState, useEffect } from 'react';
import "index.css";
import "./admin.css"
import api from "services/api";
import AdminUserReward from 'pages/admin/AdminUserReward'
import AdminUserHistory from 'pages/admin/AdminUserHistory'
import AdminUserTasks from 'pages/admin/AdminUserTasks'
import AdminUserQuestions from 'pages/admin/AdminUserQuestions'
import DeleteUser from 'components/deleteUser'

export default function AdminUsers() {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([])
    const [userDetails, setUserDetails] = useState("");
    const [userName, setUserName] = useState("");
    const [userScreen, setUserScreen] = useState();
    const [, startTransition] = useTransition();
    const [groups, setGroups] = useState([]);
    const [userGroup, setUserGroup] = useState(0);
    const [errors, setErrors] = useState("");
    const [open, setOpen] = useState([])

    useEffect(() => {
        //Gets a list of users
        const fetchData = async () => {
            await api.get("/users")
            .then(success => {
                setUsers(success.data.groups);
                console.log(success.data.groups)
            },
            error => {
                console.log("Unable to load users");
            });
            await api.get("/groups")
            .then(success => {
                setGroups(success.data);
            },
            error => {
                console.log("Unable to load groups");
            });
        }
        fetchData();
    },[]);
    
      // handles the user screen selected. uses transitions
    async function handleUserScreen(e) {
        startTransition(() => {
            setUserScreen(parseInt(e.target.value));
        });
    }

    async function handleUpdateGroup(value) {
        setUserGroup(value)
        await api.post(`/groups/assign/${userDetails.id}`, {group_id: value}).then(success => {       
            console.log(success.data);
        }, error =>
        {
            console.log(error)
            setErrors(error.message)
            return // generally we need better error handlign
        });
        }
    

    const toggleOpen = (group) => {
        console.log(group)
        console.log(open)
        if (open.includes(group.group_id)) {
            setOpen(open.filter(users => users !== group.group_id))
        } else {
            let newOpen = [...open]
            newOpen.push(group.group_id)
            setOpen(newOpen)
        }

    }

    // handles user selection. sends an API request to get user information

async function handleSelectUser(e, selUser) {
    setUserDetails([]);
    setUserScreen();
    setErrors();
    setUserName(selUser.name);
    await api.get(`/users/${selUser.id}`).then(success => {
        setUserDetails(success.data);
        if (success.data.group_id){
            setUserGroup(success.data.group_id);
        } else{
            setUserGroup(0)
        }
        
        console.log(success.data);
    }, error =>
    {
       return // generally we need better error handlign
    });
}

    return (
        <div className="adminpage-container">
            <div className="adminpage-main-content">
            <div className="container-fluid">
            <div className="row">
            <div className="col-lg-2 ps-0 ms-0">
                    <div className="adminpage-search-box bg-white pb-0 mb-0">
                        <input 
                            type="text" 
                            placeholder="Search users..." 
                            value={searchTerm} 
                            onChange={e => setSearchTerm(e.target.value)} 
                        />
                    </div>
                    <div className="adminpage-sidebar bg-white pt-0 mt-0" >
                        <div >
                            <div >
                                {users.map((group, index) => (
                                    <div 
                                        value={group.group_id}
                                        className="text-black pb-0 "
                                    >
                                        <button className="btn" onClick={() => {toggleOpen(group)}}>{open.includes(group.group_id) ? <i className="bi bi-arrow-down-square"></i> : <i className="bi bi-arrow-right-square"></i>} {group.title ? group.title : "Unassigned"}</button>
                                        {open.includes(group.group_id) ? (<div>{group.users.map((single_user) => <p><button value={single_user.id} disabled={userDetails.id === single_user.id}className="btn p-0 ps-4 " onClick={(e) => {handleSelectUser(e, single_user)}}>{single_user.name}</button></p>)}<hr/></div>) : null}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10">
                    {userDetails && (<div className="adminpage-info-box">
                        <div className="p-4">
                            <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Profile" className="adminpage-info-picture"/>
                            <h2>{userName && (("Information for " + userName)  || "Loading.. ")}</h2> 
                            <select className="form-control" onChange={(e) => handleUpdateGroup(e.target.value)} value={userGroup}>
                            <option value="0">Unassigned</option> 
                                {
                                    groups.map( (group,index) => 
                                    <option key={group.id} value={group.id}>{group.title}</option> ) 
                                }</select>  
                            {errors && (<span style={{fontWeight: "bold"}}>{errors}<br /></span>)}
                            <DeleteUser userID={userDetails.id} />
                            <hr className="adminpage-info-divider" />
                            <div className="adminpage-info-details">
                                <div className="adminpage-info-column">
                                    <p>DoB: {userDetails.dob}</p>
                                    <p>Height: {userDetails.height}m</p>
                                    <p>Weight: {userDetails.weight}kg</p>
                                    <p>Gender: {userDetails.gender}</p>
                                </div>
                                <div className="adminpage-info-column">
                                    <p>Email: {userDetails.email}</p>
                                    <p>Points: {userDetails.points}</p>
                                    <p>Days in Program: {userDetails.confirmed_on}</p>
                                    <p>Last Activity: {userDetails.last_activity}</p>
                                </div>
                            </div>
                        </div>
                        <nav className="nav nav-pills flex-column flex-lg-row">
                            <button className="flex-lg-fill text-lg-center adminNav" value="0" onClick={handleUserScreen} disabled={userScreen === 0}>Tracking</button>
                            <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={handleUserScreen} disabled={userScreen === 1}>Tasks</button>
                            <button className="flex-lg-fill text-lg-center adminNav" value="2" onClick={handleUserScreen} disabled={userScreen === 2}>Questions</button>
                            <button className="flex-lg-fill text-lg-center adminNav" value="3" onClick={handleUserScreen} disabled={userScreen === 3}>Rewards</button>
                        </nav>
                    </div>
                )}
                {/* the individual "pages" a user can have are seperate components! #ilovereact */}
                {userScreen === 0 && <div>
                    <h3>{userName}'s Tracking</h3>
                    <AdminUserHistory userID={userDetails.id}/>
                    </div>
                    }
                {userScreen === 1 && <div>
                    <h3>Tasks</h3>
                    <AdminUserTasks userID={userDetails.id}/>
                    </div>}
                {userScreen === 2 && <div>
                    <h3>{userName}'s Questions</h3>
                    <AdminUserQuestions userID={userDetails.id}/>
                    </div>
                    }
                {userScreen === 3 && <div>
                    <h3>{userName}'s Rewards</h3>
                    <AdminUserReward userID={userDetails.id}/>
                    </div>
                    }
                {!userDetails && (<h1>Select a user from the sidebar</h1>)}
                </div>

            </div>
        </div>
    </div>
    </div>
    );
}
