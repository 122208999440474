import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "index.css";
import api from "services/api.js";
import "./FoodPage.css"
import FoodCalendar from "pages/account/tracking/food/DailyFoodGrid"
import DatePicker from "components/date/DatePicker";
import dayjs from "dayjs";
import StaticPlusButton from "components/buttons/StaticPlusButton";
import Button from "components/buttons/Button"
import CircularProgress from "components/progress/CircularProgress"
import LoadingPlaceholder from "components/LoadingPlaceholder";



function FoodPage() {
    const location = useLocation(); //
    const params = new URLSearchParams(location.search);
    const dateString = params.get('date');
    const date = dateString ? dayjs(dateString) : dayjs();

    const [foodFormState, setFoodFormState] = useState({ isLoading : true,isSubmitting: false})
    const [foodHistory, setFoodHistory] = useState([]);
    const [waterHistory, setWaterHistory] = useState([]); // State for water history
    const [dateDisplay, setDateDisplay] = useState(date); //Store the current date as dayjs
    const [water, setWater] = useState(0);
    const [waterTarget, setWaterTarget] = useState(2700);
    const [loading, setLoading] = useState(true)
    const [waterProcessing, setWaterProcessing] = useState(false)
    

    const totalWater = waterHistory.reduce((array, value) =>  array + value.quantity , 0)

    
    const [errorMessages, setErrors] = useState({});

    async function addWater(){
        if (water > 2000 || water <= 0){
            setErrors({water: "Please enter a valid amount of water (0-2000ml)"})
            return;
        }
        setWaterProcessing(true)
        await api.post("account/water/add",{
            time_period: dateDisplay.toISOString(),
            quantity: water
        }).then(()=>{
            setWaterHistory(prev=> ([...prev, {
                quantity: Number(water)
            }]))
            setWater(0);
        })
        setWaterProcessing(false)
    }

    async function deleteFood(foodId){
        const errorMessages = {}  

        await api.delete("account/food/" + foodId).then(success => {
            console.log("food deleted")
            setFoodHistory(foodHistory.filter(foodHistory => foodHistory.id !== foodId));
        }, error =>{
            errorMessages.backend = "A server error has occured."
            setErrors(errorMessages)
        });
        setFoodFormState({ ...foodFormState, isSubmitting: false });    
    }   

    useEffect(() => {
        const fetchData = async () => {
            const queryDate = dateDisplay.format("YYYY-MM-DD");
            await api.get("account/food/list", {params: { from_date: queryDate, to_date: queryDate }})
            .then(success => {
                setFoodHistory(success.data);
            },
            error => {
                console.log("error occured. ");
            });
            await api.get("account/water/list", {params: { from_date: queryDate, to_date: queryDate }})
            .then(success => {
                console.log("got data", success.data)
                setWaterHistory(success.data) 
            }, 
            error =>{
                console.log("error occured while loading water history " );
            });
            await api.get("account/water/target" , {params: { date: queryDate}})
            .then(success => {
                console.log("got target", success.data)
                setWaterTarget(success.data) 
            }, error =>{
                console.log("error occured while loading water target " );
            }); 
            setLoading(false);
        }
        setFoodFormState({ ...foodFormState, isLoading: false });

        fetchData();
        
    // eslint-disable-next-line    
    },[dateDisplay]);

    return (
    <div className="account-container">
        {loading && <LoadingPlaceholder />}
        {!loading && 
        <div className=" text-center justify-center position-relative">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h1>Your Nutrition</h1>
                </div>
                <div className="col-12 col-md-6">
                    <DatePicker value={dateDisplay} onChange={setDateDisplay} />
                </div>
            </div>
            
            
            {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
            <div className="row mt-3 ">
                <div className="hydration-group">
                    <h3>Hydration</h3>

                    <div className="cup-container">
                        <div className="water" style={{ height: `${(totalWater / (waterTarget.target ? waterTarget.target : 2700)) * 100}%` }}></div>
                    </div>
                    <small>{totalWater} / {waterTarget.target ? waterTarget.target : 2700} ml</small>
                    <div className="d-flex flex-column justify-content-center align-items-center max-width-input-group my-3">
                        <div className="d-flex gap-2 mb-2 item w-100">
                            {["50", "100", "250"].map((amount) => (
                                <button 
                                    key={amount}
                                    className="btn btn-outline-secondary p-2 w-100"
                                    onClick={() => setWater((prev) => Number(prev) + Number(amount))}
                                >+{amount}ml</button>
                            ))}
                        </div>
                        <div className="d-flex item w-100 mt-1">
                            <div className="input-group">
                                <input 
                                    type="number" 
                                    className="form-control py-2 w-50" 
                                    value={water}
                                    onChange={(e) => setWater(e.target.value)}
                                />
                                <button className="btn btn-success  w-50" onClick={()=> addWater()} disabled={waterProcessing}>Add </button>
                            </div>
                        </div>
                        <Link to="target">
                            <Button other="mt-2 pt-2" text={waterTarget.isSet ? "Update your target" : "Set a target"} btnType="success" width="w100" fontSize="fm" />
                        </Link>
                    </div>
                </div>
            </div>


        
            <h2 className="mt-4">Food History</h2>
            <div className=" pb-3">
                <Link to={`./AddFood?date=${dateDisplay.toISOString()}`} >
                    <Button other="pt-2" text="Add food" btnType="success" width="w100" fontSize="fm" />
                </Link>
            </div>
            <FoodCalendar food={foodHistory}/>
            
            
        </div>}
    </div>
    );
}

export default FoodPage;
