import { useState, useEffect } from "react";
import GroupedUsersListItem from "./GroupedUsersListItem";

const GroupedUsersList = ({ groupedUsers, onSelectUser, selectedUser }) => {
  const [show, setShow] = useState(setUserGroupOpen())
  
  function setUserGroupOpen(){
    return Object.fromEntries(
      groupedUsers.map(group => [
        group.group_id, 
        group.users.some(user => user.id === selectedUser) // Use `some()` for readability
      ])
    )
  }
  useEffect(()=>{
    setShow(setUserGroupOpen())
  },[groupedUsers])

  if (!groupedUsers || groupedUsers.length === 0) {
    return <div>No groups</div>;
  }

  const toggleGroup = (group_id) => {
    setShow(prev => 
      Object.fromEntries(
        Object.keys(prev).map(id => [id, String(id) === String(group_id) && !prev[id]]) 
      )
    );
  };
  

  return (
    <div>
      {groupedUsers.map((group) => (
        <GroupedUsersListItem
          group={group}
          selectedUser={selectedUser}
          key={group.group_id}
          onSelectUser={onSelectUser}
          show={show[group.group_id]}
          onShowChange={toggleGroup}
        />
      ))}
    </div>
  );
};

export default GroupedUsersList;
