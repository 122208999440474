import React, {useState, useEffect} from "react";
import api from "services/api";
import validator from 'validator'
import tokenService from "services/token.service";

// generic holder for settings popup

function SettingsPopup({isOpen, onClose, type}) {
    const [screen, setScreen] = useState(0); // screen counter
    const [npasso, setnpasso] = useState(""); // old password or height
    const [npasst, setnpasst] = useState(""); // new password or weight
    const [pass, setPass] = useState(""); // new password conformation or gender
    const [errorMessages, setErrors] = useState({});

    useEffect(() => {
        // Fetches user details if required
            const fetchData = async () => {
                if (type === "details"){
                    await api.get("/users/anthropometric")
                    .then(success => {
                        setPass(success.data.height)
                        setnpasso(success.data.weight)
                        setnpasst(success.data.gender)
                    },
                    error => {
                        return
                    });
                    } else if (type === "sharecode"){
                        await api.get("/groups/assign")
                        .then(success => {
                            console.log(success.data)
                            setPass(success.data.title)
                            setnpasso(success.data.access_expires)
                        },
                        error => {
                            return
                        });
                    }
                }
        fetchData();
    // eslint-disable-next-line    
    },[isOpen]);

    // updates user password
    async function updatePass(e){
        if (screen === 0){
            const errorMessages = {}

            //validation
            if (npasso !== npasst){
                errorMessages.notIdentical = "Passwords are not identical"
            } else if (!validator.isStrongPassword(npasso, {minLength: 8, minNumbers: 1, minSymbols: 1 })){
                errorMessages.password = "Password does not meet compelxity requirements"
            }
            
            if (Object.keys(errorMessages).length === 0){
                const newEntry = {
                    oldpassword: pass,
                    newpassword: npasso
                };
                await api.post("/auth/update/password", newEntry).then(success => {
                    setScreen(1);
                    setErrors({})
                }, error =>
                {
                    errorMessages.oops = "An error has occured"
                    setErrors(errorMessages);
                });
            } else {
                setErrors(errorMessages)
            }
        } else if (screen === 1){
            // reload on finish
            window.location.reload(false);
        }
    }

    // updates "anthropometric user information" (height, weight and gender)
    async function updateDeets(e){
        if (screen === 0){
            const errorMessages = {}

            // input validation
            if (pass === ""){
                errorMessages.height = "Height cannot be blank"
            }
            if (npasso === ""){
            errorMessages.weight = "Weight cannot be blank"
            }

            if (pass > 250 || pass < 50){
            errorMessages.height = "Height is not sensible"
            }
            if (npasso > 350 || npasso < 20){
            errorMessages.weight = "Weight is not sensible"
            }
            
            if (Object.keys(errorMessages).length === 0){
                const newEntry = {
                    height: parseFloat(pass),
                    weight: parseFloat(npasso),
                    gender: parseInt(npasst)
                };
                await api.put("/users/anthropometric", newEntry).then(success => {
                    setScreen(1);
                    setErrors({})
                }, error =>
                {
                    errorMessages.oops = "An error has occured"
                    setErrors(errorMessages);
                });
            } else {
                setErrors(errorMessages)
            }
        } else if (screen === 1){
             // reload on finish
            window.location.reload(false);
        }
       
    }

    // updates user email (probably want some password verification on this)
    async function updateEmail(e){
        if (screen === 0){
            const errorMessages = {}
            // input validation
            if (npasso === npasst){
                errorMessages.notIdentical = "Emails cannot be identical"
            } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(npasst))){
                errorMessages.email = "Email not in correct format"
            }
            
            if (Object.keys(errorMessages).length === 0){
                const newEntry = {
                    oldemail: npasso,
                    newemail: npasst
                };
                await api.put("/auth/update/email", newEntry).then(success => {
                    setScreen(1);
                    setErrors({})
                }, error =>
                {
                    errorMessages.oops = "An error has occured"
                    setErrors(errorMessages);
                });
            } else {
                setErrors(errorMessages)
            }
        } else if (screen === 1){
             // reload on finish
            window.location.reload(false);
        }
       
    }

    // guess what. it updates the user name
    async function updateName(e){
        if (screen === 0){
            const errorMessages = {}

            if (npasso === "" || npasst === ""){
                errorMessages.name = "Name cannot be blank"
            }
            if (npasso.toString().length  > 20){
            errorMessages.tooLongFname = "First name too long (max 20 chars)"
            }
            if (npasst.toString().length  > 20){
            errorMessages.tooLongLname = "Last name too long (max 20 chars)"
            }
            
            if (Object.keys(errorMessages).length === 0){
                const newEntry = {
                    fname: String(npasso),
                    lname: String(npasst)
                };
                await api.put("/auth/update/name", newEntry).then(success => {
                    setScreen(1);
                    setErrors({})
                    tokenService.setNewName(npasso, npasst)
                }, error =>
                {
                    errorMessages.oops = "An error has occured"
                    setErrors(errorMessages);
                });
            } else {
                setErrors(errorMessages)
            }
        } else if (screen === 1){
            // reload on finish
            window.location.reload(false);
        }
    }

        // updates "non-confidential user information" (height, weight and gender)
        async function updateGroup(e){
            if (screen === 0){
                const errorMessages = {}
    
                // some very basic input validation
    
                if (npasst.toString().length > 50 || npasst.toString().length < 5){
                errorMessages.sharecode = "Share code is not sensible"
                }

                
                if (Object.keys(errorMessages).length === 0){
                    const newEntry = {
                        share_code: npasst,
                    };
                    await api.post("/groups/assign", newEntry).then(success => {
                        setScreen(1);
                        setPass(success.data.title);
                        setnpasso(success.data.access_expires);
                        setErrors({})
                    }, error =>
                    {
                        errorMessages.oops = "An error has occured. If the share code is correct, please check with your group administrator."
                        setErrors(errorMessages);
                    });
                } else {
                    setErrors(errorMessages)
                }
            } else if (screen === 1){
                 // reload on finish
                window.location.reload(false);
            }
        }


    if (!isOpen) return null; // Don't render if modal is not open

    // this is like a big if statement so surely there's a better way of doing this
    if (type === "password") return (
        <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
            {screen === 0 && (
                <div>
                    <h2>Update Password</h2>
                    <div className="p-2">
                        <p>Current Password</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="password" aria-label="old-pass" onChange={(e) => setPass(e.target.value)}/>
                    </div>
                    <div className="p-2">
                        <p>New Password</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="password" aria-label="new-pass-one" onChange={(e) => setnpasso(e.target.value)}/>
                    </div>
                    <div className="p-2 pt-0">
                        <p>Confirm Password</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="password" aria-label="new-pass-two" onChange={(e) => setnpasst(e.target.value)}/>
                    </div>
                </div>
            )}
            {screen === 1 &&  <h2>Password Updated!</h2> }
            
            
            <div className="input-group">
                <button className="btn-success btn" style={{width:"100%"}} onClick={updatePass}>
                    Next
                </button>
                </div> 
                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
  
            
            <button className="modal-close" onClick={onClose}>
            <i className="bi bi-x"></i>
            </button>
        </div>
      </div>
    )

    if (type === "sharecode") return (
        <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
            {screen === 0 && (
                <div>
                    <h2>Enter a Share Code</h2>
                    <div className="p-2">
                        {pass && <div>
                            <p>You're currently in group: {pass}</p>
                            <p>Your group expires on: {npasso}</p>
                            <p>Enter a share code below to join a group. Note: It might not be possible to rejoin the group you are currently in.</p>
                            </div>}
                        {!pass && <div>
                            <p>You're not in a group. Enter a share code below to join a group.</p>
                            </div>}
                        <p></p>
                    </div>
                    <div className="p-2 pt-0">
                        <p>Enter share code:</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="share code" onChange={(e) => setnpasst(e.target.value)}/>
                    </div>
                </div>
            )}
            {screen === 1 &&  <div>
                <h2>Welcome to {pass}!</h2>
                <p>This group runs until {npasso}</p>
            </div> }
            
            
            <div className="input-group">
                <button className="btn-success btn" style={{width:"100%"}} onClick={updateGroup}>
                    Next
                </button>
                </div> 
                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
  
            
            <button className="modal-close" onClick={onClose}>
            <i className="bi bi-x"></i>
            </button>
        </div>
      </div>
    )

    if (type === "details") return (
        
        <div className="modal-overlay"  onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
            {screen === 0 && (
                <div>
                    <h2>Update Details</h2>
                    <div className="p-2">
                        <p>Height</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="number" aria-label="old-pass" onChange={(e) => setPass(e.target.value)} value={pass}/>
                    </div>
                    <div className="p-2">
                        <p>Weight</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="number" aria-label="new-pass-one" onChange={(e) => setnpasso(e.target.value)} value={npasso}/>
                    </div>
                    <div className="p-2 pt-0">
                        <p>Gender</p>
                        <select className="form-control" onChange={(e) => setnpasst(e.target.value)} value={npasst}>
                                          <option value="1">Male</option> 
                                          <option value="2">Female</option> 
                                          <option value="3">Other</option> 
                                          <option value="4">Prefer not to say</option> 
                                    </select>                    
                    </div>
                </div>
            )}
            {screen === 1 &&  <h2>Details Updated!</h2> }
            
            
            <div className="input-group">
                <button className="btn-success btn" style={{width:"100%"}} onClick={updateDeets}>
                    Next
                </button>
                </div> 
                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
  
            
            <button className="modal-close" onClick={onClose}>
            <i className="bi bi-x"></i>
            </button>
        </div>
      </div>
    )

    if (type === "email") return (
        <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
            {screen === 0 && (
                <div>
                    <h2>Update Email</h2>
                    <div className="p-2">
                        <p>Current Email</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="old-email" onChange={(e) => setnpasso(e.target.value)}/>
                    </div>
                    <div className="p-2">
                        <p>New Email</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="new-email" onChange={(e) => setnpasst(e.target.value)}/>
                    </div>
                </div>
            )}  
            {screen === 1 &&  <h2>Email Updated!</h2> }
            
            
            <div className="input-group">
                <button className="btn-success btn" style={{width:"100%"}} onClick={updateEmail}>
                    Next
                </button>
                </div> 
                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
            <button className="modal-close" onClick={onClose}>
            <i className="bi bi-x"></i>
            </button>
        </div>
      </div>
    )

    if (type === "name") return (
        <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
            {screen === 0 && (
                <div>
                    <h2>Update Name</h2>
                    <div className="p-2">
                        <p>First Name</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="first-name" onChange={(e) => setnpasso(e.target.value)} placeholder={tokenService.getFirstName()}/>
                    </div>
                    <div className="p-2">
                        <p>Second Name</p>
                        <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="second-name" onChange={(e) => setnpasst(e.target.value)} placeholder={tokenService.getLastName()}/>
                    </div>
                </div>
            )}  
            {screen === 1 &&  <h2>Name Updated!</h2> }
            
            
            <div className="input-group">
                <button className="btn-success btn" style={{width:"100%"}} onClick={updateName}>
                    Next
                </button>
                </div> 
                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
            <button className="modal-close" onClick={onClose}>
            <i className="bi bi-x"></i>
            </button>
        </div>
      </div>
    )

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>
          
        </div>
      </div>
    );
  }


export default SettingsPopup;