import { useLocation, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import api from 'services/api';
import './redeem.css'
import Button from "components/buttons/Button";
import {homepage} from "components/nav/links"

export default function AccountRedeemTemplate() {
  const location = useLocation();
  const sendTo = location.state?.from?.pathname || homepage.to;
  const [rewardData, setRewardData] = useState([]);
  const [points, setPoints] = useState(null);
  const navigate = useNavigate(); // For navigation
  const [errors, setErrors] = useState("");

  // URL for API to get reward information. Uses pathname in URL
  var urlOne = "account/rewards/" + location.pathname.split("/").pop()  

  useEffect(() => {
    const fetchData = async () => {
        await api.get(urlOne)
        .then(success => {
          // If unsuccessful, force user back to index
          if (success.data.success === false){
              // window.location.replace("../redeem/")
          } else {
          setRewardData(success.data);
          }
        },
        error => {
            // If error, force user back to index
            // window.location.replace("../redeem/")
        });

        await api.get("users/points",)
        .then(success => {
            setPoints(success.data.points);
        });
    }
    fetchData();
  // eslint-disable-next-line    
  },[]);

  // handle the redemption
  async function handleRedeem(e) {
    await api.post("/account/rewards/redeem", {reward_id: rewardData.id}).then(success => {
      navigate('../redeem/success', {state: {title: rewardData.title, remainingPoints: points - rewardData.cost}})
      }, error => {
        //console.log(error)
        setErrors(error.status)
    });
  }

  function redeemButton(){
    if(points< rewardData.cost){
      return <>
              <p>Unfortunately, you don't have enough points to redeem this reward now. </p>
              <Link to={homepage.to}><Button btnType="success" width="w100" text="go">Go earn some points</Button></Link>
            </>
    }
    return <div className="d-flex gap-2 w-100">
            <Link to={sendTo} className="w-50"><Button btnType="secondary" width="w100">{sendTo === homepage.to? "Cancel":"Go back to rewards"}</Button></Link>
            <Button text="Redeem" btnType="success" width="w50" onClick={handleRedeem}/>
          </div>
  }

  return (

    <div  className="account-container p-0">
      <div  className="row w-100">
        <div className="col w-100 p-0">
            <div style={{backgroundImage: `url(${rewardData.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
              <div className="redeem-cover p-5 pb-3 pt-5">
                <h2>{rewardData.title}</h2>
                <p><b>{rewardData.cost}</b> points</p>

              </div>
            </div>
        </div>
      </div>
      <div  className="row pad-sides-16">
        <h2>About this reward</h2>
        
        <p>Description:{rewardData.description}</p>
        <p>Delivered through: <b>{rewardData.delivery}</b></p>
        <p>Delivered by: <b>{rewardData.author_name}</b></p>
        <p>You can redeem this a maximum of <b>{rewardData.max}</b> times, which means you can redeem this <b>{rewardData.available}</b> more times.</p>
        <p>You have {points} points.</p>
        
          {/* // state={{ from: location }} */}
          <p><b>{errors}</b></p>
          {redeemButton()}
      </div>
    </div>
  );
}
