
const GroupedUsersListItem = ({ group, onSelectUser, selectedUser, show, onShowChange }) => {


  return (
    <div className="accordion">
      <div className="accordion-item" onClick={() => onShowChange(group.group_id)}>
        <h2 className="accordion-header" id="headingOne">
          <button className="btn w-100 btn-outline text-start btn-success">
          <i
              className={`bi ${
                show ? "bi-arrow-down-square" : "bi bi-arrow-right-square"
              } text-end`}
            />{" "}
            {group.title || "Unassigned"}
          </button>
        </h2>
      </div>
      <div className={`accordian-collapse collapse ${show ? "show" : ""}`}>
        <div className="card card-body">
          {group.users.map((user) => (
            <button
              key={user.id}
              className={`btn ${selectedUser === user.id? "btn-success":"btn-warning"} mb-1 text-start d-flex align-items-center gap-2 `}
              onClick={() => onSelectUser(user.id)}
            >
              <img
                className="rounded-circle"
                src={"https://placehold.co/50?text=User+Image"}
                alt="user image"
              ></img>
              {user.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupedUsersListItem;
