import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Button from "components/buttons/Button.js";
import "./SleepPage.js";
import dayjs, { duration } from "dayjs";
import "./SleepGraph.css"
import isBetween from "dayjs/plugin/isBetween";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, ChartDataLabels);

const weekdaysOld = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const weekdays = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

const formatHourTick = (value) => {
    let h = Math.floor(value % 24);
    let suffix = h >= 12 ? "PM" : "AM";
    let displayHour = h % 12;
    if (displayHour === 0) displayHour = 12;
    return `${displayHour} ${suffix}`;
};

function SleepGraph({ sleepData, onPreviousWeek, onNextWeek, fromDate, toDate }) {
    dayjs.extend(isBetween); 

    const getDayLabel = (dateTime) => {
        if (dateTime.isSame(fromDate,"day")){
            return `Prev. Sun (${dateTime.format("DD MMM")})`
        }
        else if (dateTime.isSame(toDate,"day")){
            return `Next. Mon (${dateTime.format("DD MMM")})`
        }
        else{
            const dayIndex = dateTime.day(); //https://day.js.org/docs/en/get-set/day
            const day =  dayIndex === 0 ? "Sunday" : weekdays[dayIndex-1];
            return `${day} (${dateTime.format("DD MMM")})`
        }
        
    };
    
    const getDatesBetween = (fromDate, toDate) => {
        let dates = [];
        let currentDate = fromDate.startOf("day");
    
        while (currentDate.isBefore(toDate) || currentDate.isSame(toDate, "day")) {
            dates.push(currentDate);
            currentDate = currentDate.add(1, "day"); 
        }
        return dates;
    };

    const dayLabels = getDatesBetween(fromDate,toDate).map(item => getDayLabel(item));

    const sessionSegments = [];
    const squareLeftSegments = [];
    const squareRightSegments = [];
    sleepData.forEach(entry => {
        
        const startTime = dayjs(entry.start_time);
        const endTime = dayjs(entry.end_time);
        const segmentStartTime = startTime.hour() + (startTime.minute()/60) ;
        const segmentEndTime = endTime.hour() + (endTime.minute()/60); //Add 24 so it appears on right side of screen!
        
        const totalHoursSlept = endTime-startTime
        //const isBetween = dayjs("16:00", "HH:mm").isBetween(startTime.format("HH:mm"), endTime.format("HH:mm"), "minute", "[]");; 
        const referenceTime = endTime.set("hour", 16).set("minute", 0);
        const isBetween = referenceTime.isBetween(startTime, endTime, "minute", "[]");
        console.log(`st: ${entry.start_time} et: ${entry.end_time} ${segmentStartTime} ${segmentEndTime} between day? ${isBetween}`) //debug
        if (isBetween) {
            //startTime.isBetween(fromDate, toDate, null, "[]")
            if(startTime.isAfter(fromDate,"day")){
                //Because time starts on the 'previous' day, we need to subtract a day!
                const position = [segmentStartTime+24, 40]
                squareRightSegments.push({
                    x: position,
                    y: getDayLabel(startTime.subtract(1,"day")),
                    totalHoursSlept: totalHoursSlept,
                    leftLabel: formatHourTick(segmentStartTime),
                    rightLabel: "",
                    duration: position[1] - position[0]
                    
                });
            }
            if(endTime.isBefore(toDate.add(1,"day"),"day")){
                const position = [16, segmentEndTime]
                squareLeftSegments.push({
                    x: position,
                    y: getDayLabel(endTime),
                    totalHoursSlept: totalHoursSlept,
                    leftLabel: "",
                    rightLabel: formatHourTick(segmentEndTime),
                    duration: position[1] - position[0]
                });
            }
        } else {
            //Add 24 hours to end time, otherwise it will start plotting on the left hand side of the screen!
            const position = [segmentStartTime, segmentEndTime+24 ]
            sessionSegments.push({
                x: position,
                y: getDayLabel(startTime),
                totalHoursSlept: totalHoursSlept,
                leftLabel: formatHourTick(segmentStartTime),
                rightLabel: formatHourTick(segmentEndTime),
                duration: position[1] - position[0]

            });
        }
    });
    const filteredSegments = sessionSegments//.filter(seg => weekDates.includes(seg.date));

    const standardStyles = {
        backgroundColor: "rgba(129, 53, 121, 0.8)",
        borderColor: "rgba(129, 53, 121, 1)",
        borderWidth: 1,
        borderSkipped: false, 
        barPercentage: 0.9 ,
        categoryPercentage: 0.9,
        stack:"group1" //Pretend they are all in the same row!
    }
    const data = {
        datasets: [
            {
                label: "Sleep Sessions",
                data: filteredSegments,
                ...standardStyles,
                borderRadius:10,
                
            },
            {
                label: "multi-day sleep sessions",
                data: squareLeftSegments,
                ...standardStyles,
                borderRadius: { topLeft: 0, topRight: 10, bottomLeft: 0, bottomRight: 10 },
                
            },
            {
                label: "multi-day sleep sessions2",
                data: squareRightSegments,
                ...standardStyles,
                borderRadius: { topLeft: 10, topRight: 0, bottomLeft: 10, bottomRight: 0 },
                
            }
        ]
    };
    const options = {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 800,
            easing: 'easeOutQuart'
        },
        layout: {
            padding: {
                bottom: 20
            }
        },
        scales: {
            x: {
                type: "linear",
                min: 16,
                max: 40,
                grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                    borderDash: [4, 4]
                },

                ticks: {
                    stepSize: 2,
                    callback: (value, index) => {
                        return value % 4 === 0 ? formatHourTick(value) : ""; // Labels every 4 units
                    },
                    font: {
                        family: "'Roboto', sans-serif",
                        size: 12
                    }
                },
                stacked:false
            },
            y: {
                type: "category",
                labels: dayLabels, //width < 576? weekdaysAbbreviated:  weekdays, // Show short labels on narrow screens
                grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                    borderDash: [4, 4]
                },
                ticks: {
                    font: {
                        family: "'Roboto', sans-serif",
                        size: 12
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const start = context.raw.x[0];
                        const end = context.raw.x[1];
                        const startLabel = formatHourTick(start);
                        const endLabel = formatHourTick(end % 24);
                        return `Sleep: ${startLabel} to ${endLabel} (${(end - start).toFixed(2)}h)`;
                    }
                },
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                titleFont: {
                    family: "'Roboto', sans-serif",
                    size: 14,
                    weight: '500'
                },
                bodyFont: {
                    family: "'Roboto', sans-serif",
                    size: 12
                },
                padding: 10
            },
            datalabels: {
                labels: {
                    leftLabel: {
                        anchor: 'start',  // Positions label at the start of the bar
                        align: 'right',
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: 14
                        },
                        formatter: (value) => value.duration > 2? value.leftLabel :"", // Text for start
                    },
                    rightLabel: {
                        anchor: 'end',  // Positions label at the end of the bar
                        align: 'left',
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: 14
                        },
                        formatter: (value) => value.duration > 2? value.rightLabel :"", // Text for end
                    }
                }
            }
            
            
        }
    };

    //console.log(weekDates)
    return (
        <div className="sleep-container">
            <div className="sleep-inner-container">
                <Button text="Prev. Week" btnType="success" width="w20" fontSize="fm" onClick={onPreviousWeek} />
                <h3 style={{ margin: 0, textAlign: "center", flexGrow: 1, color: "#333", fontFamily: "'Roboto', sans-serif" }}>
                    Sleep Sessions <br /> 
                    {fromDate.add(1,"day").format("DD/MM/YY")} - {toDate.add(-1,"day").format("DD/MM/YY")}
                </h3>
                <Button text="Next Week" btnType="success" width="w20" fontSize="fm" onClick={onNextWeek}  disabled={toDate.isAfter(dayjs())}/>
            </div>
            <Bar data={data} options={options} />
        </div>
    );
}

export default SleepGraph;