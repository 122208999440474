import { useLocation, useNavigate } from "react-router-dom";
import "index.css"; 

export default function AccountRedeemSuccess() {
  const {state} = useLocation();
  // get title and remaining points from previous state to display to user
  const {title, remainingPoints} = state;
  const navigate = useNavigate();

  // navigate back to redemption page after complete
  const handleClaimClick = () => {
    navigate('/account/redeem');
  };

  return (
    <div className="account-container">
      <div className="w-100 text-center">
      <h2>{title} - redeemed successfully!</h2>
        <h5>Your new balance is {remainingPoints} points</h5>
        <p>You will receive email conformation of this redemption, and the reward provider will be in touch over email with further information regarding your reward.</p>
        <button
          onClick={handleClaimClick}
          className={`custom-button classic-button`}
        >
          Return
        </button>
      </div>
    </div>
    
  );
}
