import React, {useState, useEffect} from 'react'
import authenticationService from 'services/authentication.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Navbar, Container, NavDropdown } from 'react-bootstrap'
import HealthifyLogo from './HealthifyLogo'
import { Icon } from '@iconify/react'
import {desktopDropdownLinks} from './links';

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() =>{

        setIsDropdownOpen(false);
    },[location])

    if(!authenticationService.isLoggedIn()){
        return <div className='headerStyle z-3'>
                    <div className=" headerMaxWidth mx-auto px-3 h-100">
                        <div className="d-flex justify-content-between align-items-center h-100">
                            <HealthifyLogo /> 
                        </div>
                    </div>
                </div>
    }
    if(authenticationService.isAdmin()){
        return(
            <div className='headerStyle z-3'>
            <div className=" headerMaxWidth mx-auto px-3 h-100">
                <div className="d-flex justify-content-between align-items-center h-100">
                    <HealthifyLogo />
                    <div className="d-flex align-items-center ms-auto">
                        <button  className='nav-item nav-link text-black me-3' 
                        onClick={async () =>  {
                            await authenticationService.logout();
                            navigate("/")
                        }}>
                            <span><i className='bi bi-box-arrow-left'></i> Logout</span>
                        </button>
                        </div>
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className='headerStyle z-3'>
            <div className=" headerMaxWidth mx-auto px-3 h-100">
                <div className="d-flex justify-content-between align-items-center h-100">
                    <HealthifyLogo />
                    <div className="d-flex align-items-center ms-auto">
                        <Link to="/account/" className='nav-item nav-link text-black me-3' style={{textDecoration: "none"}}>
                            <span><i className='bi bi-house-fill'></i> Home</span>
                        </Link>
                        <div className="nav-item dropdown">
                            <button
                                className="btn btn-link nav-link dropdown-toggle"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            >
                                Account
                            </button>
                            <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`} style={{ right: 0, left: "auto" }} onMouseLeave={()=>setIsDropdownOpen(false)}>
                                {desktopDropdownLinks.map(link=><li key={link.to}><Link className="dropdown-item d-flex justify-content-between align-items-center" to={link.to}> {link.name} 
                                <Icon icon={link.icon} className='text-end'/>
                                </Link>
                            </li>)}
                            </ul>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    )

}


export default Header