import React from 'react'

const Streak = ({value}) => {
    return (
        <>
        <h5 className="text-lg-end"> Your streak: {value.length}<i className={`bi bi-fire ${value.danger? "":"text-danger"}`}></i></h5>
        <p className="text-lg-end">{value.danger && value.length !== 0 && ("Your streak is in danger! Log something or complete a task to save it.")} {value.length === 0 && ("You don't have a streak yet. Log something or complete a task to start it.")}</p>
        </>
    )
}

export default Streak