import { Link } from "react-router-dom";
import authenticationService from "services/authentication.service.js";
import Button from "components/buttons/Button.js";

function loginHome() {
  if (authenticationService.currentUserValue) {
    if (authenticationService.isAdmin()){
      return (
        <Link to="/admin">
          <Button text="Admin Panel" btnType="success" width="w50" fontSize="fl"/>
        </Link>
    )} else {
      return (
        <Link to="/account">
          <Button text="Account Home" btnType="success" width="w50" fontSize="fl"/>
        </Link>
    )};
  } else {
    return (             
      <div className="d-flex gap-2 w-100">
        <Link to="register" className="w-100">
          <Button text="Register" btnType="secondary" width="w100" fontSize="fl"/>
        </Link>
        <Link to ="login" className="w-100">
          <Button text="Log In" btnType="success" width="w100" fontSize="fl"/>
        </Link>
      </div> 
    )
  }
}

export default function Home() {
  return (
    <div>
      <div className="homepageStyle jumbotron-fluid align-items-center d-flex container-fluid">
        <div
          className="homepage-max-width container pb-3 pt-lg-5 pt-2"
          style={{
            color:"white",
          }}
        >
          <h1>Healthify</h1>
          <p className="lead">A new approach to lifestyle medicine</p>
          <hr />
          
          {loginHome()}
        </div>
      </div>
      <div className="homepage-max-width pt-5 p-3">
          <h2 >Personalized Health and Wellness Profiles</h2>
          <ul >
            <li>Customized user profiles to track nutrition, exercise, mood, and more</li>
            <li>Visual tracking comparisons </li>
          </ul>
          <h2 >Professional Advice and Support</h2>
          <ul>
            <li>Professionally-tuned reccomendations based on your tracking history</li>
            <li>Integration with Dr. Monika's Google Calendar for appointments</li>
            <li>Recipies from experts in lifestyle medicine</li>
          </ul>
          <h1 >Ready to get started?</h1>
          {loginHome()}
        </div>
    </div>
  );
}
