import "index.css";
import React, { useState } from 'react';
import api from "services/api";

export default function AdminUserTasks({userID}) {
    const [userData, setUserData] = useState ([]);
    const [logSearchTerm, setLogSearchTerm] = useState ("");
    const [userHistory, setUserHistory] = useState();
    const [dateFrom, setDateFrom] = useState ();
    const [dateTo, setDateTo] = useState ();
    const [loading, setLoading] = useState(false);
    let updatedFrom, updatedTo;

    const getDate = (timeshift) => {
        const now = new Date();
        now.setDate(now.getDate() - timeshift)
        return now.toISOString().slice(0, 10); // formats to "yyyy-MM-ddTHH:mm"
    };


    async function handleUserHistory(value) {

        if (value === 0){
            updatedFrom = getDate(0)
        } else if (value === 1){
            updatedFrom = getDate(7)
        } else if (value === 2){
            updatedFrom = getDate(28)
        }
        if (value !== 3){
            setDateFrom(updatedFrom);
            updatedTo = getDate(0)
            setDateTo(updatedTo); 

            getTaskData(); 
        }
        setUserHistory(value);
    }

    async function handleCustomDate(e, from) {
        if (from){
            updatedFrom = e.target.value;
            setDateFrom(e.target.value);
            updatedTo = dateTo;
        } else {
            updatedTo = e.target.value;
            setDateTo(e.target.value);
            updatedFrom = dateFrom;
        }
        console.log(updatedFrom);
        console.log(updatedTo); 
        if (updatedFrom || updatedTo){
            getTaskData();
        }
    }

    async function getTaskData(){
        setUserData([]);
        setLoading(true);
        await api.get(`users/${userID}/tasks`, { params: { fromDate: updatedFrom, toDate:updatedTo}}).then(success => {
            setLoading(false);
            setUserData(success.data);
        }, error =>
        {
            console.log("error" + error);
        });
    }

    // if (!isDisplay) return null; // Don't render if modal is not open
    const filteredTasks = userData.filter(task => 
        task.title.toLowerCase().includes(logSearchTerm.toLowerCase())
    );

    return (
        <div>
            <nav className="nav nav-pills flex-column flex-lg-row">
                    <button className="flex-lg-fill text-lg-center adminNav" value="0" onClick={(e) => {handleUserHistory(0)}} disabled={userHistory === 0}>Today</button>
                    <button className="flex-lg-fill text-lg-center adminNav" value="1" onClick={(e) => {handleUserHistory(1)}} disabled={userHistory === 1}>Past Week</button>
                    <button className="flex-lg-fill text-lg-center adminNav" value="2" onClick={(e) => {handleUserHistory(2)}} disabled={userHistory === 2}>Past 4 Weeks</button>
                    <button className="flex-lg-fill text-lg-center adminNav" value="3" onClick={(e) => {handleUserHistory(3)}} disabled={userHistory === 3}>Custom Range</button>
            </nav>
            {userHistory === 3 && 
                <div>
                    <div className="input-group">
                    <form className="form-floating">
                        <input
                            type="date"
                            id="data_from"
                            name="From"
                            className={"form-control p-3"}
                            value={updatedFrom}
                            onChange={(e) => {handleCustomDate(e, true)}}
                            /> 
                        </form>
                        <input
                            type="date"
                            id="date_to"
                            name="To"
                            className={"form-control p-3"}
                            value={updatedTo}
                            onChange={(e) => {handleCustomDate(e, false)}}
                            /> 
                    </div>
                </div>}
                <div className="container-fluid">
                <div className="row text-center">
                    <p>Showing results from <strong>{dateFrom}</strong> to <strong>{dateTo}</strong></p>
                </div>
                <div className="row">
                    <div className="col-10"> 
                    {/* display each day from user log */}
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-12">
                                {loading && (<p><strong>Loading results, please wait</strong></p>)}
                                {filteredTasks.map((taskentry) => (
                                <div>
                                    <div className="adminpage-userlist-infocard rounded">
                                        <p className="adminpage-userlist-infocard-header">{taskentry.title}, completed on {taskentry.timestamp}</p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-2">
                        <p><strong>Search Filters</strong></p>
                        <div className="accountRedeem-search-box">
                            <input 
                                type="text" 
                                placeholder="Search tasks..." 
                                value={logSearchTerm} 
                                onChange={e => setLogSearchTerm(e.target.value)} 
                            />
                        </div> 
                    </div>
                    </div> 

                    
                    </div>
        </div>    
    );
}