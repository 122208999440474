import React, { useState, useEffect } from 'react';
import "index.css";
import "../admin/admin.css"
import api from "services/api";
import tokenService from 'services/token.service';

// components
import LoadingPlaceholder from "components/LoadingPlaceholder"
import Checkbox from "components/checkbox/checkbox"
import MonikaFeedbackInput from "components/feedback/giveFeedback"
import DatePicker from "components/date/DatePicker";

import dayjs from "dayjs";
import { Icon } from '@iconify/react';

export default function AdminHome() {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [foodSearch, setFoodSearch] = useState(true);
    const [waterSearch, setWaterSearch] = useState(true);
    const [moodSearch, setMoodSearch] = useState(true);
    const [sleepSearch, setSleepSearch] = useState(true);
    const [taskSearch, setTaskSearch] = useState(true);
    const [quesSearch, setQuesSearch] = useState(true);
    const [rewardSearch, setRewardSearch] = useState(true);
    const [showQuesResponse, setResponses] = useState(false);
    const [hideUsers, setHideUsers] = useState(true);
    const [dateDisplay, setDateDisplay] = useState(dayjs());


    useEffect(() => {
        //Gets a list of users
        const fetchData = async () => {
            await api.get(`users/view`, {params: { view_date: dateDisplay.format("YYYY-MM-DD") }}).then(success => {
                setLoading(false);
                setUserData(success.data);
                console.log(success.data)
            }, error =>
            {
                console.log("error" + error);
            });
        }
        fetchData();
      },[dateDisplay]);

    return (
        <div className="container-fluid">
            <div className="row">            
                {/* introduction */}
                <div className="col-lg-3 col-sm-12">
                    {/* blank space on the left */}
                </div>
                <div className="col-lg-6 col-12 pb-0 pt-2">
                    {/* main page title */}
                    <h2>Welcome Back, <b>{tokenService.getFirstName()}!</b></h2>
                    <p>You are an administrator.</p>
                    <hr />
                </div>
                <div className="col-lg-3 col-sm-12">
                    {/* blank space on the right */}
                </div>
            </div>
            {/* main content */}
            <div className="row">            
                {/* introduction */}
                <div className="col-lg-3 col-sm-12">
                {/* blank space on the left */}
                </div>
                <div className="col-lg-6 col-12 pb-0 pt-2">
                    <h2>Today in Healthify</h2>
                    {loading && <LoadingPlaceholder />}
                    {!loading && 
                    <div>
                        {userData.groups.map((groupData, index) => (
                            <div key={groupData.title}>
                                <h4>{groupData.title}</h4>
                                {groupData.users.map((userData, index) => (
                                    <div key={userData.id}>
                                    {(!hideUsers || (userData.activity.length !== 0 || userData.water !== 0)) &&(
                                        <div className="ps-3">
                                        <h5><img src={userData.image_url ? userData.image_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt="Profile" height="50px"/> {userData.name}</h5>
                                        {userData.activity.length === 0 && userData.water === 0 && (<p><i>user does not have activity today</i></p>)}
                                        {userData.activity && (
                                            <div>
                                            {userData.activity.map((individualItem, index) => (
                                                <div>
                                                    {individualItem.type === "food" && foodSearch && (<p className="d-flex justify-content-between"><span><Icon icon="mdi:food-fork-drink" /> {individualItem.title} </span><span><button className="btn p-1 pt-0"><MonikaFeedbackInput feedback_id={individualItem.id}/></button><button className="btn p-1 pt-0"><Icon icon="bi:camera" /></button> {individualItem.timestamp} </span></p>)}
                                                    {individualItem.type === "mood" && moodSearch && (<p className="d-flex justify-content-between"><span><Icon icon="tabler:mood-puzzled" /> {individualItem.title} </span><span><button className="btn p-1 pt-0"><MonikaFeedbackInput feedback_id={individualItem.id}/></button> {individualItem.timestamp}</span></p>)}
                                                    {individualItem.type === "sleep" && sleepSearch && (<p className="d-flex justify-content-between"><span><Icon icon="solar:moon-sleep-linear" /> {dayjs(individualItem.start).format('HH:mm')} - {dayjs(individualItem.end).format('HH:mm')}</span><span><button className="btn p-1 pt-0"><MonikaFeedbackInput feedback_id={individualItem.id}/></button> {individualItem.timestamp}</span></p>)}
                                                    {individualItem.type === "tasks" && taskSearch && (<p className="d-flex justify-content-between"><span><Icon icon="tdesign:task-filled" /> {individualItem.title} </span><span>{individualItem.timestamp}</span></p>)}
                                                    {individualItem.type === "questions" && quesSearch && (<p className="d-flex justify-content-between"><span><Icon icon="wpf:ask-question" /> {individualItem.title} </span><span><button className="btn p-1 pt-0"></button> {individualItem.timestamp}</span></p>)}
                                                    {individualItem.type === "rewards" && rewardSearch && (<p className="d-flex justify-content-between"><span><Icon icon="bi:gift" /> {individualItem.title} </span><span>{individualItem.completed && <Icon icon="bi:check-circle"  alt="completed" />}{individualItem.refunded && <Icon icon="gridicons:refund" alt="refunded" />} {individualItem.timestamp}</span></p>)}
                                                    {showQuesResponse && individualItem.type === "questions" && individualItem.responses && (<div className="ps-5">{individualItem.responses.map((individualResponse, index) => (<div><p>{index + 1}: {individualResponse.question}</p><p>{individualResponse.response ? individualResponse.response : "No Response"}</p></div>))}</div>)}
                                                </div>))}
                                            </div>
                                        )}
                                        {userData.water !== 0 && waterSearch && (<p className="d-flex justify-content-between"><span><Icon icon="mdi:cup" /> {userData.water} ml </span></p>)}
                                    </div>)}
                                    </div>
                                ))}
                            <hr />
                            </div>
                        ))}
                    </div>}
                </div>
                <div className="col-lg-3 col-12 text-start">
                        <p><strong>Search Filters</strong></p>
                        <DatePicker value={dateDisplay} onChange={setDateDisplay} />
                        <hr />  
                        <Checkbox label="Food" checked={foodSearch} onChange={(e) => {setFoodSearch(!foodSearch)}}/>
                        <Checkbox label="Water" checked={waterSearch} onChange={(e) => {setWaterSearch(!waterSearch)}}/>
                        <Checkbox label="Sleep" checked={sleepSearch} onChange={(e) => {setSleepSearch(!sleepSearch)}}/>
                        <Checkbox label="Mood" checked={moodSearch} onChange={(e) => {setMoodSearch(!moodSearch)}}/>
                        <Checkbox label="Task" checked={taskSearch} onChange={(e) => {setTaskSearch(!taskSearch)}}/>
                        <Checkbox label="Questionnaires" checked={quesSearch} onChange={(e) => {setQuesSearch(!quesSearch)}}/>
                        <Checkbox label="Rewards" checked={rewardSearch} onChange={(e) => {setRewardSearch(!rewardSearch)}}/>
                        <hr />
                        <Checkbox label="Show questionaire responses" checked={showQuesResponse} onChange={(e) => {setResponses(!showQuesResponse)}} disabled={!quesSearch}/>
                        <Checkbox label="Hide users with no activity" checked={hideUsers} onChange={(e) => {setHideUsers(!hideUsers)}}/>
                    </div>
                </div>
            </div>
    );
}
