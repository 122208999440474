import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import api from "services/api";
import authenticationService from "services/authentication.service";
import DeleteForum from "components/forum/DeleteForum";
import StickyForum from "components/forum/StickyForum";


export default function CommunityTopic() {
  const [forumTopics, setForumTopics] = useState([]);
  const [title, setTitle] = useState("");
  const location = useLocation();


  useEffect(() => {
    //Run async function in use effect. 

    var urlOne = "forum/topics/" + location.pathname.split("/").pop() + "/threads"
    var urlTwo = "forum/topics/" + location.pathname.split("/").pop()

    const fetchData = async () => {
      // gets a list of the threads within a topic
        await api.get(urlOne)
        .then(success => {
            setForumTopics(success.data);
        });
        
        // gets the title and description of the current topic
        await api.get(urlTwo)
        .then(success => {
            setTitle(success.data);
        },
        error => {

        });
    }
    fetchData();
    
// eslint-disable-next-line    
},[]);



  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <div className="clearfix mb-3">
              <h2>{title.title}</h2>
              <p>{title.description}</p>
            </div>

             {/* INDIVIDUAL THREAD PAGE  */}
             <div>
                <div>
                    {forumTopics.map((entry, index) => (
                      <div className="m-2">
                          <div className={`rounded p-4 pb-1 communitySection `}>
                          <Link style={{ textDecoration: "none", color:  "black" }} to={"/community/post/" + entry.id}>
                            <h4>{entry.title}</h4>
                            <p>{entry.timestamp}</p>
                          </Link>
                            <p>{entry.sticky && (<span><i className="bi bi-pin"></i> pinned</span>)}{authenticationService.isAdmin() && (<span><StickyForum type="thread" id={entry.id} current={entry.sticky}/> <DeleteForum type="thread" id={entry.id}/></span>)}</p>
                          </div>
                      </div>
                    ))
                    }
                    {(forumTopics.length === 0) && (
                      <div>
                        <h5>No threads found.</h5>
                        <p>Why not start one?</p>
                      </div>)}
                      <div>
                        <Link to="../add">
                          <button className="btn btn-primary" style={{width:"100%"}}>Add a thread</button>
                        </Link>
                      </div>
                </div>
                <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
