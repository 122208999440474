import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();
    const scrollPositions = useRef({}); // Store scroll positions
    const lastPathname = useRef(null); // Track last visited page

    useEffect(() => {
        // Save scroll position before navigating away
        return () => {
            scrollPositions.current[lastPathname.current] = window.scrollY;
        };
    }, [pathname]);

    useEffect(() => {
        const isGoingBack = scrollPositions.current[pathname] !== undefined;
        const savedPosition = isGoingBack ? scrollPositions.current[pathname] : 0;
        window.scrollTo(0, savedPosition);

        // Update lastPathname to current pathname
        lastPathname.current = pathname;
    }, [pathname]);

    return null;
};

export default ScrollToTop;
