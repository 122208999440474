import React, {useState} from "react";
import api from "services/api";

// generic button for banning a user on the fourm

function BanForum({id}) {
    const [screen, setScreen] = useState(0)
    async function handleSticky() {
        if (screen === 0){
            setScreen(1)
        } else if (screen === 1){
            var url = "forum/users/ban/" + id  
            await api.post(url).then(success => {
                window.location.reload(false)
            }, error =>
            {
                console.log("error" + error);
            });
        }

    };
    
    return (
        <button onClick={handleSticky}><i className="bi bi-ban"></i> {(screen === 0 && "ban")}{(screen === 1 && "are you sure")}</button>
        )
}

export default BanForum;