import React, {useState} from "react";
import api from "services/api";

// generic button for deleting something on the fourm

function DeleteForum({type, id}) {
    const [screen, setScreen] = useState(0)
    async function handleDelete() {
        if (screen === 0){
            setScreen(1)
        } else if (screen === 1){
            var url;
            if (type === "thread"){
                url = "forum/threads/" + id 
            } else if (type==="topic"){
                url = "forum/topics/" + id 
            } else {
                url = "forum/posts/" + id 
            }
            await api.delete(url).then(success => {
                window.location.reload(false)
            }, error =>
            {
                console.log("error" + error);
            });
        };
    }
    
    return (
            <button onClick={handleDelete}><i className="bi bi-trash"></i> {(screen === 0 && "delete")}{(screen === 1 && "are you sure")}</button> 
        );
}

export default DeleteForum;