import { Link } from "react-router-dom";
import Button from "../../components/buttons/Button.js";

export default function NoMatch() {
  return (
      <div className="container-fluid account-container">

            <h1>404</h1>
            <h3 className="text-left">The requested page cannot be found.</h3>
            <p>We're sorry, but the page you've requested cannot be found. We have alerted the team to try and fix this.</p>
            <Link to="/" style={{textDecoration: 'none'}}>
              <Button text="Return to the Homepage" btnType="success" width="w100" fontSize="fl"/>
            </ Link>
      </div>
  );
}
