import { useLocation, useNavigate, Link } from "react-router-dom";
import "index.css"; 
import Button from "components/buttons/Button.js";
import { homepage } from "components/nav/links";


export default function ActivitySuccessful() {
  const location = useLocation();
  const navigate = useNavigate();
  const taskData = location.state;

  if (!taskData) {
    navigate('/account');
    return null;
  }




  return (
    <div className="account-container">
      <div className="w-100 text-center">
        <h2>{taskData.title} - Completed successfully!</h2>
        <h2>You gained {taskData.points} points!</h2>
        <p className="placeholder-wave">Your new balance is {taskData.totalPoints} points</p>
        <Link to={homepage.to}><Button btnType="success" width="w100" fontSize="fl">Go Home</Button></Link>
      </div>
    </div>
    
  );
}