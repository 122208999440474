import React from 'react'
import { Icon } from '@iconify/react'

const SettingsButton = ({children, onClick, className, icon}) => {
    return (
        <button className={`${className|| "settings-button"} d-flex align-items-center justify-content-center position-relative mb-3`} onClick={onClick}>
            <span className="flex-grow-1 text-center">{children}</span>
            <Icon icon={icon} className="ms-2" />
        </button>
    )
}

export default SettingsButton