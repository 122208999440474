import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Container } from 'react-bootstrap'
import HealthifyLogo from './HealthifyLogo'
import { Icon } from '@iconify/react'

const MobileHeader = () => {
    return (
    <Navbar className="headerStyle z-3">
        <Container fluid>
            <HealthifyLogo/>
        
        <div className="col-lg-2 d-flex justify-content-end align-items-center">
            <Link to="/account/settings" className='nav-item nav-link text-black me-3' style={{textDecoration: "none"}}>
                <span><i className='bi bi-gear'/></span>
            </Link>
        </div>
        </Container>
    </Navbar>)
}

export default MobileHeader