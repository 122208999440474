import React from 'react'
import authenticationService from '../../services/authentication.service'
import { Link } from 'react-router-dom'
import Role from '../../services/role'
import Button from "components/buttons/Button.js";


const Unauthorised = () => {

    function getMessage(){
        if (authenticationService.isAdmin()){
            return <p>You are an admin, so why not <Link to={"/admin/"}>go to the admin page</Link></p>
        }
        else if(authenticationService.isUser()){
            return <p>Let's keep you going! Why not go to <Link to={"/account"}>your account dashboard?</Link></p>
        }
        else{
            return null
        }
            
    }

    return (
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div  className="col-lg-2"></div>
                  <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
                    <h1>403 error - you are unauthorised to view this page.</h1>
                    <h3>{getMessage()}</h3>
                    <Link to="/" style={{textDecoration: 'none'}}>
                      <Button text="Return to the Homepage" btnType="success" width="w50" fontSize="fl"/>
                    </ Link>
                  </div>
                </div>
              </div>
            </div>
        );
}

export default Unauthorised