import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "index.css";
import api from "services/api.js";
import Button from "components/buttons/Button.js";
import LoadingPlaceholder from "components/LoadingPlaceholder";

export default function WaterTarget() {
    const [newTargetCups, setNewTargetCups] = useState("");
    const [newTargetML, setNewTargetML] = useState("");
    const [useCups, setUseCups] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [pastTargets, setPastTargets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPastTargets = async () => {
            await api.get("account/water/target/list")
            .then(success => {
                console.log("got target", success.data)
                setPastTargets(success.data) 
            }, error =>{
                console.log("error occured while loading water target " );
            }); 
            setLoading(false);
        }
        fetchPastTargets();
    }, []);

    const toggleUnit = () => {
        setUseCups(!useCups);
        setNewTargetCups("");
        setNewTargetML("");
        setErrorMessages({});
    };

    async function handleSetNewTarget() {
        let errors = {};
        if (useCups) {
            if (!newTargetCups || parseFloat(newTargetCups) <= 0 || parseFloat(newTargetCups) > 80) {
                errors.newTarget = "Please enter a valid number of cups (0-80).";
            }
        } else {
            if (!newTargetML || parseFloat(newTargetML) <= 0 || parseFloat(newTargetML) > 20000 ) {
                errors.newTarget = "Please enter a valid amount in milliliters (0-20000ml).";
            }
        }
        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }
        

        const targetML = useCups ? parseFloat(newTargetCups) * 250 : parseFloat(newTargetML);
        try {
            const response = await api.post("account/water/target", { target: targetML });

            // for local user timestamp!
            const formattedDate = new Date();
            const correctedDate = formattedDate.toISOString().slice(0, 10);
            // ==

            setPastTargets([{target: targetML, timestamp:correctedDate}, ...pastTargets]);
            setNewTargetCups("");
            setNewTargetML("");
            setErrorMessages({});
        } catch (error) {
            setErrorMessages({ backend: "A server error has occurred." });
        }
    }

    const computeVisualHeight = () => {
        const targetValue = useCups
            ? parseFloat(newTargetCups) * 250
            : parseFloat(newTargetML);
        if (!targetValue || targetValue <= 0) return "0%";
        const height = Math.min((targetValue / 3000) * 100, 100);
        return `${height}%`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB");
    };

    return (
        <div className="account-container">
            {loading && <LoadingPlaceholder />}
            {!loading && 
            <div className="w-100">
                <h1 className="text-center">Water Target</h1>
                <div className="mb-3">
                    <h3>Past Water Goals</h3>
                    {pastTargets.length === 0 ? (
                        <p>No water targets set yet.</p>
                    ) : (
                        <ul className="list-group">
                            {pastTargets.map((target, index) => (
                                <li key={index} className="list-group-item">
                                    <strong>{formatDate(target.timestamp)}</strong>: {target.target} ml
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                    <h3>Set a New Water Target</h3>
                    <div className="mb-2">
                        {useCups ? (
                            <div>
                                <label>Enter target in cups</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={newTargetCups}
                                    onChange={(e) => setNewTargetCups(e.target.value)}
                                    placeholder="e.g., 8"
                                />
                            </div>
                        ) : (
                            <div>
                                <label>Enter target in milliliters</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={newTargetML}
                                    onChange={(e) => setNewTargetML(e.target.value)}
                                    placeholder="e.g., 2000"
                                />
                            </div>
                        )}
                    </div>
                    <div className="cup-container" style={{ margin: "0 auto", marginBottom: "15px" }}>
                        <div className="water" style={{ height: computeVisualHeight() }}></div>
                    </div>
                    <div className="d-flex mb-3 ">
                        <Button
                            text={useCups ? "Cups (Active)" : "Cups"}
                            btnType={useCups ? "success" : "warning"}
                            width="w50"
                            fontSize="fm"
                            other="rounded-start rounded-end-0"
                            onClick={() => {
                                if (!useCups) toggleUnit();
                            }}
                        />
                        <Button
                            text={!useCups ? "Milliliters (Active)" : "Milliliters"}
                            btnType={!useCups ? "success" : "warning"}
                            width="w50"
                            fontSize="fm"
                            other="rounded-end rounded-start-0"
                            onClick={() => {
                                if (useCups) toggleUnit();
                            }}
                        />
                    </div>
                    {Object.entries(errorMessages).map(([key, error]) => (
                        <span key={key} style={{ fontWeight: "bold", color: "red" }}>
                            {error}
                            <br />
                        </span>
                    ))}
                    <div className="text-center">
                        
                    </div>
                <div className="d-flex gap-2 mt-3">
                    <Link to="/account/tracking/food" className="w50">
                        <Button text="Back" btnType="secondary" width="w100" fontSize="sm" />
                    </Link>
                    <Button
                            text="Set New Target"
                            btnType="success"
                            width="w50"
                            fontSize="fm"
                            onClick={handleSetNewTarget}
                            disabled={loading}
                        />
                </div>
                </div>}
        </div>
    );
}
