import { BehaviorSubject } from 'rxjs';
import Role from './role';
import api from "./api";
import tokenService from "./token.service";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('previousAuthTokenResponse')));

export const authenticationService = {
    login,
    logout,
    register,
    updateToken,
    isAdmin,
    isUser,
    getRole,
    isLoggedIn,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return tokenService.getPreviousAuthTokenResponse() }
};

function getRole(){
  return tokenService.getRole()
}
function isAdmin(){
  return tokenService.getRole() === Role.Admin
}
function isUser(){
  return tokenService.getRole() === Role.User
}
function isLoggedIn(){
  return tokenService.getPreviousAuthTokenResponse()
}
async function login(email, password) {
  return  await api
    .post("/auth/login", {
      email,
      password
    })
    .then(response => {
      if (response.data.accessToken) {
        const data = response.data;
        tokenService.saveApiTokenResponse(data);
        currentUserSubject.next(data);
      } 
      return response.data;
    });
}

function updateToken(token){
  currentUserSubject.next(token);
}

async function logout() {
  return await api.post("auth/logout").then(
    success => {
      tokenService.removeUser();
      currentUserSubject.next(null);
    }
  )

}



async function register(firstName, lastName, emailAddress,password, height, weight, dob, gender) {
return await api.post( "auth/register", {
  firstName:  firstName, lastName: lastName, email: emailAddress, password : password, height: height, weight: weight, dob: dob, gender: gender
}).then(response => {
  return response.data;
});
}

export default authenticationService;
