import React from 'react'
import dayjs from 'dayjs';
import { useState } from 'react';
import "./DatePicker.css"
import { Icon } from "@iconify/react/dist/iconify.js";


const DatePicker = ({value, onChange, className=null}) => {

    const incrementDay= (numberOfDays) =>{
        const current = dayjs(value);
        const newDay = current.add(numberOfDays,"day");
        onChange(newDay);
    }  

    const setToday =()=>{
        onChange(dayjs());
    }
    return (
        <div className={`date-picker-container ${className} z-2`}>
            <div className="input-group ">
                <button 
                    className="btn btn-secondary px-4 py-3" 
                    onClick={() => incrementDay(-1)} 
                    type="button"
                >
                    &lt;
                </button>
            
                <button className="form-control text-center bg-white text-align-center" onClick={setToday} type='button'>
                    {dayjs(value).format("DD/MM/YYYY")}&nbsp;<Icon icon={ value&& value.isSame(dayjs(), "day")? "bi:calendar-event" :"bi:calendar-event-fill"}/>
                </button>
            
                <button 
                    className="btn btn-secondary px-4 py-3" 
                    onClick={() => incrementDay(1)} 
                    type="button" 
                    disabled={(value&& value.isSame(dayjs(),"day"))}
                >
                    &gt;
                </button>
            </div>
    </div>
    
    )
}

export default DatePicker