import React from 'react'
import { NavLink } from "react-router-dom";
import { Icon } from '@iconify/react'
import "./MobileNav.css"
import authenticationService from 'services/authentication.service'
import {mobileNavbarLinks} from './links';

const MobileNav = () => {
    if(authenticationService.isAdmin()|| !authenticationService.isLoggedIn()){
        return
    }   


    return (
        <nav className="fixed-bottom bg-white container-border">
            <div className="container d-flex justify-content-around  py-3">
                {mobileNavbarLinks.map(link=>
                    (<NavLink 
                        key={link.to}
                        to={link.to} 
                        style={{textDecoration: "none"}}
                        end
                        className={({ isActive }) => isActive ? "text-gold" : "text-black"}>
                        <div className='text-center '>
                            <div >
                                <Icon icon={link.icon}  width="40px" />
                            </div>
                            <span>{link.name}</span>
                        </div>
                    </NavLink>)
                    )}  
            </div>
        </nav>

    )
}

export default MobileNav